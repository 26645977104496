define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.javeaZoneCapMarti = void 0;
    exports.javeaZoneCapMarti = [
        [
            { lng: 0.21665, lat: 38.7484 },
            { lng: 0.21491, lat: 38.74806 },
            { lng: 0.21343, lat: 38.74753 },
            { lng: 0.20983, lat: 38.74715 },
            { lng: 0.20763, lat: 38.74739 },
            { lng: 0.20719, lat: 38.74721 },
            { lng: 0.20486, lat: 38.7486 },
            { lng: 0.20471, lat: 38.74812 },
            { lng: 0.2042, lat: 38.74805 },
            { lng: 0.20418, lat: 38.74749 },
            { lng: 0.20396, lat: 38.74734 },
            { lng: 0.20235, lat: 38.74708 },
            { lng: 0.20182, lat: 38.74663 },
            { lng: 0.20192, lat: 38.74555 },
            { lng: 0.19919, lat: 38.74508 },
            { lng: 0.19729, lat: 38.74371 },
            { lng: 0.19613, lat: 38.74369 },
            { lng: 0.19505, lat: 38.74342 },
            { lng: 0.19322, lat: 38.7434 },
            { lng: 0.19183, lat: 38.74405 },
            { lng: 0.19045, lat: 38.74576 },
            { lng: 0.18865, lat: 38.74626 },
            { lng: 0.18634, lat: 38.74491 },
            { lng: 0.1825, lat: 38.7439 },
            { lng: 0.17961, lat: 38.74343 },
            { lng: 0.17314, lat: 38.74139 },
            { lng: 0.1712, lat: 38.74018 },
            { lng: 0.16851, lat: 38.73909 },
            { lng: 0.16727, lat: 38.73782 },
            { lng: 0.16497, lat: 38.7367 },
            { lng: 0.16322, lat: 38.73543 },
            { lng: 0.16136, lat: 38.7328 },
            { lng: 0.16084, lat: 38.73162 },
            { lng: 0.1605, lat: 38.73189 },
            { lng: 0.15548, lat: 38.73897 },
            { lng: 0.15801, lat: 38.74104 },
            { lng: 0.16097, lat: 38.74245 },
            { lng: 0.1642, lat: 38.74365 },
            { lng: 0.16529, lat: 38.74525 },
            { lng: 0.16841, lat: 38.74876 },
            { lng: 0.16927, lat: 38.74997 },
            { lng: 0.16957, lat: 38.75302 },
            { lng: 0.16932, lat: 38.75545 },
            { lng: 0.17019, lat: 38.75799 },
            { lng: 0.1727, lat: 38.76043 },
            { lng: 0.17371, lat: 38.7624 },
            { lng: 0.17548, lat: 38.76328 },
            { lng: 0.17611, lat: 38.76385 },
            { lng: 0.1771, lat: 38.76516 },
            { lng: 0.17748, lat: 38.76685 },
            { lng: 0.17848, lat: 38.76632 },
            { lng: 0.18109, lat: 38.76587 },
            { lng: 0.183, lat: 38.76522 },
            { lng: 0.18533, lat: 38.76516 },
            { lng: 0.1868, lat: 38.76467 },
            { lng: 0.18735, lat: 38.7647 },
            { lng: 0.18758, lat: 38.76431 },
            { lng: 0.18884, lat: 38.76354 },
            { lng: 0.19089, lat: 38.7628 },
            { lng: 0.19293, lat: 38.76239 },
            { lng: 0.19496, lat: 38.76169 },
            { lng: 0.19646, lat: 38.76149 },
            { lng: 0.19935, lat: 38.76065 },
            { lng: 0.20216, lat: 38.76044 },
            { lng: 0.20369, lat: 38.76126 },
            { lng: 0.20599, lat: 38.76112 },
            { lng: 0.20613, lat: 38.7617 },
            { lng: 0.20671, lat: 38.76222 },
            { lng: 0.20652, lat: 38.76185 },
            { lng: 0.20664, lat: 38.76174 },
            { lng: 0.20695, lat: 38.76174 },
            { lng: 0.20693, lat: 38.76201 },
            { lng: 0.20719, lat: 38.76207 },
            { lng: 0.20809, lat: 38.76162 },
            { lng: 0.20905, lat: 38.76141 },
            { lng: 0.20939, lat: 38.76114 },
            { lng: 0.20886, lat: 38.76127 },
            { lng: 0.20926, lat: 38.76067 },
            { lng: 0.21007, lat: 38.76046 },
            { lng: 0.2116, lat: 38.76053 },
            { lng: 0.2121, lat: 38.75831 },
            { lng: 0.2134, lat: 38.75786 },
            { lng: 0.21343, lat: 38.75762 },
            { lng: 0.21191, lat: 38.7575 },
            { lng: 0.21063, lat: 38.75699 },
            { lng: 0.20977, lat: 38.75694 },
            { lng: 0.20923, lat: 38.75628 },
            { lng: 0.20884, lat: 38.75611 },
            { lng: 0.20987, lat: 38.75621 },
            { lng: 0.2128, lat: 38.75461 },
            { lng: 0.21415, lat: 38.75362 },
            { lng: 0.21274, lat: 38.75341 },
            { lng: 0.21228, lat: 38.75311 },
            { lng: 0.21211, lat: 38.75261 },
            { lng: 0.21236, lat: 38.75186 },
            { lng: 0.21186, lat: 38.75106 },
            { lng: 0.21206, lat: 38.75074 },
            { lng: 0.21391, lat: 38.75048 },
            { lng: 0.2148, lat: 38.75055 },
            { lng: 0.2169, lat: 38.75149 },
            { lng: 0.21757, lat: 38.75129 },
            { lng: 0.21788, lat: 38.75074 },
            { lng: 0.21762, lat: 38.74969 },
            { lng: 0.21665, lat: 38.7484 }
        ]
    ];
});
