define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.teulada = void 0;
    exports.teulada = [
        [
            { lng: 0.0792, lat: 38.69508 },
            { lng: 0.08045, lat: 38.69423 },
            { lng: 0.08049, lat: 38.6936 },
            { lng: 0.08173, lat: 38.69317 },
            { lng: 0.08307, lat: 38.69521 },
            { lng: 0.08582, lat: 38.69719 },
            { lng: 0.0897, lat: 38.69686 },
            { lng: 0.09371, lat: 38.69794 },
            { lng: 0.09338, lat: 38.69944 },
            { lng: 0.09395, lat: 38.70086 },
            { lng: 0.09537, lat: 38.70048 },
            { lng: 0.09669, lat: 38.7019 },
            { lng: 0.09691, lat: 38.7035 },
            { lng: 0.10174, lat: 38.70409 },
            { lng: 0.1082, lat: 38.70633 },
            { lng: 0.11132, lat: 38.70647 },
            { lng: 0.1145, lat: 38.70739 },
            { lng: 0.11894, lat: 38.7079 },
            { lng: 0.12116, lat: 38.70871 },
            { lng: 0.12235, lat: 38.70981 },
            { lng: 0.12621, lat: 38.71071 },
            { lng: 0.13002, lat: 38.71055 },
            { lng: 0.13364, lat: 38.70964 },
            { lng: 0.13563, lat: 38.70783 },
            { lng: 0.13667, lat: 38.70948 },
            { lng: 0.13783, lat: 38.71369 },
            { lng: 0.1397, lat: 38.71601 },
            { lng: 0.1365, lat: 38.72743 },
            { lng: 0.12954, lat: 38.72918 },
            { lng: 0.11712, lat: 38.73327 },
            { lng: 0.11392, lat: 38.73659 },
            { lng: 0.11354, lat: 38.73794 },
            { lng: 0.11397, lat: 38.73878 },
            { lng: 0.11815, lat: 38.74095 },
            { lng: 0.11714, lat: 38.74237 },
            { lng: 0.11668, lat: 38.74526 },
            { lng: 0.11725, lat: 38.74935 },
            { lng: 0.10835, lat: 38.74803 },
            { lng: 0.10288, lat: 38.74904 },
            { lng: 0.09848, lat: 38.74884 },
            { lng: 0.08751, lat: 38.74688 },
            { lng: 0.08624, lat: 38.74717 },
            { lng: 0.07974, lat: 38.74506 },
            { lng: 0.08361, lat: 38.73257 },
            { lng: 0.08603, lat: 38.73166 },
            { lng: 0.08771, lat: 38.72854 },
            { lng: 0.08754, lat: 38.72742 },
            { lng: 0.08894, lat: 38.72444 },
            { lng: 0.08771, lat: 38.724 },
            { lng: 0.0883, lat: 38.72234 },
            { lng: 0.08594, lat: 38.71983 },
            { lng: 0.08511, lat: 38.71326 },
            { lng: 0.08303, lat: 38.71143 },
            { lng: 0.08223, lat: 38.70515 },
            { lng: 0.08312, lat: 38.70427 },
            { lng: 0.08253, lat: 38.70062 },
            { lng: 0.08138, lat: 38.69989 },
            { lng: 0.08162, lat: 38.69884 },
            { lng: 0.08107, lat: 38.6963 },
            { lng: 0.08019, lat: 38.69624 },
            { lng: 0.0792, lat: 38.69508 }
        ]
    ];
});
