define(["require", "exports", "./SooGoogleMap", "./SooTaggerGoogleMap"], function (require, exports, SooGoogleMap_1, SooTaggerGoogleMap_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.SooMap = void 0;
    class SooMap {
        initMapEntity(htmlElementSelector, config, type = 'google') {
            switch (type) {
                case 'google':
                    this.mapEntity = new SooGoogleMap_1.SooGoogleMap(htmlElementSelector, config);
                    break;
                case 'taggerGoogle':
                    this.mapEntity = new SooTaggerGoogleMap_1.SooTaggerGoogleMap(htmlElementSelector, config);
                    break;
                default:
                    break;
            }
        }
    }
    exports.SooMap = SooMap;
});
