define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.morairaZoneCascoUrbano = void 0;
    exports.morairaZoneCascoUrbano = [
        [
            { lng: 0.13913, lat: 38.68668 },
            { lng: 0.13882, lat: 38.68662 },
            { lng: 0.13841, lat: 38.68532 },
            { lng: 0.13895, lat: 38.68519 },
            { lng: 0.13933, lat: 38.68529 },
            { lng: 0.13955, lat: 38.68515 },
            { lng: 0.13945, lat: 38.68482 },
            { lng: 0.13459, lat: 38.6853 },
            { lng: 0.13395, lat: 38.68673 },
            { lng: 0.13357, lat: 38.68648 },
            { lng: 0.13193, lat: 38.68629 },
            { lng: 0.13139, lat: 38.68636 },
            { lng: 0.13114, lat: 38.68654 },
            { lng: 0.13122, lat: 38.68675 },
            { lng: 0.13092, lat: 38.68687 },
            { lng: 0.13042, lat: 38.68683 },
            { lng: 0.12919, lat: 38.68638 },
            { lng: 0.12885, lat: 38.68658 },
            { lng: 0.12925, lat: 38.68889 },
            { lng: 0.12884, lat: 38.68921 },
            { lng: 0.12876, lat: 38.6895 },
            { lng: 0.13343, lat: 38.68946 },
            { lng: 0.13356, lat: 38.68905 },
            { lng: 0.13536, lat: 38.68922 },
            { lng: 0.1364, lat: 38.68885 },
            { lng: 0.13653, lat: 38.68773 },
            { lng: 0.13742, lat: 38.68774 },
            { lng: 0.13887, lat: 38.68747 },
            { lng: 0.13913, lat: 38.68668 }
        ]
    ];
});
