define(["require", "exports", "./Popup", "../skins/custom", "../polygons/index"], function (require, exports, Popup_1, custom_1, index_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.SooGoogleMap = void 0;
    class SooGoogleMap {
        constructor(htmlElementSelector, config) {
            this.properties = [];
            this.propertiesCount = [];
            this.config = {
                center: { lat: 38.74532785673098, lng: 0.05015891455079979 },
                zoom: 11.2,
                strokeColor: '#000000',
                fillColor: '#1d7cb',
                strokeWeight: 2,
                secondaryStrokeColor: '#000000',
                secondaryFillColor: "#1d7cb",
                dimmedFillColor: "#d4d4d4",
                strokeOpacityAlpha: .4,
                fillOpacityAlpha: .4,
                strokeOpacity: 1,
                fillOpacity: .8,
                styles: custom_1.customTheme,
                showOnlyPolygonsWithProperties: true
            };
            this.markers = [];
            this.activePoligons = [];
            this.loadedPolygons = [];
            this.staticHoveredPolygons = [];
            this.areaSubscribers = [];
            const htmlMapElement = document.querySelector(htmlElementSelector);
            if (!htmlMapElement)
                throw new Error('Not found map HTML element');
            this.htmlMapElement = htmlMapElement;
            // Format polygons with locations
            let polygonsToLoad = index_1.sooUsedPoligons;
            if (config.locations)
                polygonsToLoad = this.formatPolygonLabelsWithLocations(polygonsToLoad, config.locations);
            // Default polygons
            this.usingPoligons = polygonsToLoad.poligons || null;
            if (this.usingPoligons)
                this.config.center = polygonsToLoad.center;
            // Config
            if (config)
                this.config = Object.assign(this.config, config);
            this.configModifiers = config;
            this.setConfig(this.config);
            this.initMap();
            this.setMapStyles();
            this.initMapData();
            this.enableHoverEvents();
        }
        // ===================================
        // Initial config & data set
        // ===================================
        setProperties(directProperties = []) {
            if (!directProperties.length)
                return this.setPropertiesFromHtmlMap();
            this.properties = directProperties;
            this.propertiesCount = this.getPropertiesCountByArea();
        }
        setPropertiesFromHtmlMap() {
            const properties = this.htmlMapElement.getAttribute('data-properties') || '[]';
            if (!properties)
                console.warn('Attribute data-properties as json not present on map element. Skipping properties load.');
            this.properties = JSON.parse(atob(properties)).items;
            this.propertiesCount = this.getPropertiesCountByArea();
        }
        setConfig(config) {
            if (config.center)
                this.config.center = config.center;
            if (config.zoom)
                this.config.zoom = config.zoom;
        }
        initMap() {
            this.map = new google.maps.Map(this.htmlMapElement, {
                scrollwheel: false,
                center: this.config.center,
                zoom: this.config.zoom,
                styles: this.config.styles
            });
        }
        initMapData() {
            // this.setProperties()
            if (this.usingPoligons.length)
                this.loadPolygons();
        }
        resetMapData() {
            this.properties = [];
            this.usingPoligons = [];
            this.loadedPolygons = [];
            this.properties = [];
        }
        // ===================================
        // Properties actions
        // ===================================
        showPropertyMarks() {
            this.properties.forEach(prop => {
                this.addPropertyMark(prop);
            });
        }
        addPropertyMark(property) {
            if (!property.latlng)
                return;
            const [lat, lng] = property.latlng.split(',');
            this.addMark(+lat, +lng, property.title);
        }
        getPropertiesCountByArea() {
            const citiesProps = [...this.properties.map(prop => this.formatArea(prop.cityName)), ...this.properties.map(prop => this.formatArea(prop.areaName))].filter(Boolean);
            const propsCountByCity = citiesProps.reduce((prev, act) => {
                if (prev[act])
                    prev[act]++;
                else
                    prev[act] = 1;
                return prev;
            }, {});
            return propsCountByCity;
        }
        // ===================================
        // Geometries
        // ===================================
        addMark(lat, lng, title = 'Property') {
            const latLng = { lat, lng };
            const marker = new google.maps.Marker({
                position: latLng,
                map: this.map,
                title
            });
            this.markers.push(marker);
        }
        loadPolygons() {
            this.usingPoligons.forEach((polygon) => {
                this.loadPolygon(polygon);
                const poligonFeature = this.map.data.getFeatureById(polygon.id);
                this.activePoligons.push(poligonFeature);
            });
        }
        loadPolygon(polygon, attatchPopup = true) {
            const polgonId = this.formatAreaId(polygon.id);
            const cityCount = this.propertiesCount[polgonId] || null; // TODO fix type
            polygon.entity = new google.maps.Data.Polygon(polygon.coords);
            this.map.data.add({
                geometry: polygon.entity,
                id: polygon.id
            });
            this.loadedPolygons.push(polygon);
            if (attatchPopup)
                this.attachPopupToPolygon(polygon, cityCount);
        }
        removePolygonByFeature(feature, polygon = null) {
            this.map.data.remove(feature);
            if (!polygon || !polygon.popup)
                return;
            polygon.popup.setMap(null);
        }
        setPolygonHoverState(feature, resetPreviousSelection = true, active = false) {
            if (this.staticHoveredPolygons.includes(feature))
                return; // TODO undo selection
            // Reset old static hovered polys
            this.staticHoveredPolygons.forEach(feature => this.setFeatureOriginalStyles(feature));
            if (resetPreviousSelection)
                this.staticHoveredPolygons = [feature];
            else
                this.staticHoveredPolygons.push(feature);
            if (!active)
                this.setFeatureHoverStyles(feature);
            else
                this.setFeatureActiveStyles(feature);
        }
        getPolygonCenter(polygon) {
            // Flatten coords to get group center position
            let coords = polygon.coords.flat();
            const lng = coords.map(xy => xy.lng);
            const lat = coords.map(xy => xy.lat);
            const cx = (Math.min(...lng) + Math.max(...lng)) / 2;
            const cy = (Math.min(...lat) + Math.max(...lat)) / 2;
            const polygonCenterCoords = { lng: cx, lat: cy };
            return polygonCenterCoords;
        }
        // ===================================
        // Styling
        // ===================================
        setMapStyles() {
            this.map.data.setStyle({
                strokeColor: this.config.strokeColor,
                fillColor: this.config.fillColor,
                strokeWeight: this.config.strokeWeight
            });
        }
        setFeatureHoverStyles(feature) {
            this.map.data.overrideStyle(feature, {
                strokeColor: this.config.strokeColor,
                fillColor: this.config.fillColor,
                strokeWeight: this.config.strokeWeight,
                strokeOpacity: this.config.strokeOpacity,
                fillOpacity: this.config.fillOpacity
            });
            const polygon = this.loadedPolygons.filter(up => up.id === feature.getId())[0] || null;
            this.changePolygonLabelVisibility(polygon, '10', '1');
        }
        setFeatureActiveStyles(feature) {
            this.map.data.overrideStyle(feature, {
                strokeColor: this.config.strokeColor,
                fillColor: this.config.fillActiveColor || this.config.fillColor,
                strokeWeight: this.config.strokeWeight,
                strokeOpacity: this.config.strokeOpacity,
                fillOpacity: this.config.fillOpacity
            });
            const polygon = this.loadedPolygons.filter(up => up.id === feature.getId())[0] || null;
            this.changePolygonLabelVisibility(polygon, '10', '1');
        }
        setFeatureOriginalStyles(feature) {
            this.map.data.overrideStyle(feature, {
                strokeColor: this.config.strokeColor,
                fillColor: this.config.fillColor,
                strokeWeight: this.config.strokeWeight,
                strokeOpacity: this.config.strokeOpacity,
                fillOpacity: this.config.fillOpacityAlpha
            });
            const polygon = this.loadedPolygons.filter(up => up.id === feature.getId())[0] || null;
            this.changePolygonLabelVisibility(polygon, '0');
        }
        setFeatureDimmedStyles(feature) {
            this.map.data.overrideStyle(feature, {
                strokeColor: this.config.strokeColor,
                fillColor: this.config.dimmedFillColor,
                strokeWeight: this.config.strokeWeight,
                strokeOpacity: this.config.strokeOpacity,
                fillOpacity: this.config.fillOpacityAlpha
            });
        }
        changePolygonLabelVisibility(polygon, zIndex, opacity = '.9') {
            if (!polygon)
                return;
            if (!polygon.popup)
                return;
            const bubble = polygon.popup.containerDiv.querySelector('.popup-bubble-anchor');
            if (!bubble)
                return;
            bubble.style.zIndex = zIndex;
            bubble.style.opacity = opacity;
        }
        // ===================================
        // Extra data
        // ===================================
        attachInfoWindowToPolygon(polygon) {
            let polygonCenterCoords = polygon.center;
            if (!polygonCenterCoords)
                polygonCenterCoords = this.getPolygonCenter(polygon);
            const infoWindow = new google.maps.InfoWindow({
                content: polygon.label,
                position: polygonCenterCoords,
            });
            infoWindow.open(this.map);
        }
        attachPopupToPolygon(polygon, count = 0) {
            let polygonCenterCoords = polygon.center;
            if (!polygonCenterCoords)
                polygonCenterCoords = this.getPolygonCenter(polygon);
            let popupLabel = polygon.label;
            if (!!count)
                popupLabel = popupLabel + ` <b>(${count})</b>`;
            const div = document.createElement("div");
            div.innerHTML = popupLabel;
            document.body.appendChild(div);
            polygon.popup = new Popup_1.Popup(new google.maps.LatLng(polygonCenterCoords.lat, polygonCenterCoords.lng), div);
            polygon.popup.setMap(this.map);
        }
        // ===================================
        // Events - catchers
        // ===================================
        enableHoverEvents() {
            this.map.data.addListener("mouseover", (event) => {
                this.setFeatureHoverStyles(event.feature);
            });
            this.map.data.addListener("mouseout", (event) => {
                // Dont remove styles if is static hovered
                if (this.staticHoveredPolygons.includes(event.feature))
                    this.setFeatureActiveStyles(event.feature);
                else if (!this.activePoligons.includes(event.feature))
                    this.setFeatureDimmedStyles(event.feature);
                else
                    this.setFeatureOriginalStyles(event.feature);
            });
            this.map.data.addListener("click", (event) => {
                if (!event.latLng)
                    return;
                this.handleAreaClick(event.feature);
            });
        }
        handleAreaClick(feature) {
            this.setPolygonHoverState(feature, true, true);
            const loadedGeometries = this.loadedPolygons.map(p => p.entity);
            const clickedGeometry = feature.getGeometry();
            if (!loadedGeometries.includes(clickedGeometry))
                return;
            const clickedPolygon = this.loadedPolygons[loadedGeometries.indexOf(clickedGeometry)];
            this.activePoligons.push(feature);
            // Emit clicked poligon
            this.emitPolygon(clickedPolygon);
            // No more actions if is children
            if (clickedPolygon.type === 'children')
                return;
            // Hide children & show parent of not clicked areas
            this.reloadUnloadedParentAreas();
            // Hide parent & show children of clicked area
            const isShowingChildren = this.hideParentAndShowChildrenAreas(feature, clickedPolygon);
            // Travel to new position
            if (isShowingChildren) {
                const { lat, lng } = this.getPolygonCenter(clickedPolygon) || { lng: this.config.center.lng, lat: this.config.center.lat };
                this.travel({ lng, lat }, clickedPolygon.zoom || this.config.zoom + 1);
            }
            else
                this.travel({ lng: this.config.center.lng, lat: this.config.center.lat }, this.config.zoom);
            // Dimm not active polys
            this.usingPoligons.forEach(up => {
                if (!up.id)
                    return;
                const notClickedPoligonFeature = this.map.data.getFeatureById(up.id);
                this.setFeatureDimmedStyles(notClickedPoligonFeature);
            });
        }
        reloadUnloadedParentAreas() {
            const unloadedPolygons = this.usingPoligons.filter(up => !this.loadedPolygons.includes(up));
            unloadedPolygons.forEach(up => {
                // Load parent
                this.loadPolygon(up);
                // Remove child polygons
                if (!up.children)
                    return;
                up.children.forEach(upChild => {
                    const relatedFeature = this.map.data.getFeatureById(upChild.id);
                    this.removePolygonByFeature(relatedFeature, upChild);
                });
            });
        }
        hideParentAndShowChildrenAreas(feature, polygon) {
            if (!polygon.children?.length)
                return false;
            this.activePoligons = [];
            // Hide parent
            this.removePolygonByFeature(feature, polygon);
            this.loadedPolygons = this.loadedPolygons.filter(lp => lp !== polygon);
            // Show children
            polygon.children.forEach(cp => {
                this.loadPolygon(cp);
                const childPoligonFeature = this.map.data.getFeatureById(cp.id);
                this.activePoligons.push(childPoligonFeature);
            });
            return true;
        }
        // ===================================
        // Events - emitters
        // ===================================
        subscribe(func) {
            this.areaSubscribers.push(func);
        }
        unsubscribe(func) {
            this.areaSubscribers = this.areaSubscribers.filter(f => f !== func);
        }
        emitAreaSubscribers(polygon) {
            this.areaSubscribers.forEach(f => f(polygon));
        }
        emitPolygon(polygon) {
            this.emitAreaSubscribers(polygon);
        }
        // ===================================
        // Map actions
        // ===================================
        travelToLocationByCityAndArea(city, area = null) {
            const cityPolygon = this.usingPoligons.filter(up => up.id === city)[0] || null;
            if (!cityPolygon)
                return;
            const cityPolygonRelatedFeature = this.map.data.getFeatureById(cityPolygon.id);
            if (!cityPolygonRelatedFeature)
                return;
            this.handleAreaClick(cityPolygonRelatedFeature);
            if (area) {
                const areaPolygon = cityPolygon.children?.filter(pc => pc.id.split(':')[1] === area)[0] || null;
                if (!areaPolygon)
                    return;
                const areaPolygonRelatedFeature = this.map.data.getFeatureById(areaPolygon.id);
                if (!areaPolygonRelatedFeature)
                    return;
                setTimeout(() => {
                    this.handleAreaClick(areaPolygonRelatedFeature);
                }, 200);
            }
        }
        travel(position, zoom = null) {
            const travelEndingPosition = new google.maps.LatLng(position.lat, position.lng);
            this.map.panTo(travelEndingPosition);
            if (zoom)
                this.map.setZoom(zoom);
        }
        // ===================================
        // Formats
        // ===================================
        formatArea(areaName) {
            return areaName.toLowerCase().replace(/\s+\-\s+/g, '-').replace(/\s/g, '-').normalize('NFD').replace(/\p{Diacritic}/gu, "");
        }
        formatAreaId(areaId) {
            const [mainAreaId, subareaId] = areaId.split(':');
            return subareaId ? subareaId : mainAreaId;
        }
        formatPolygonLabelsWithLocations(polygonsToLoad, locations) {
            const mappedPolygonsToLoad = polygonsToLoad.poligons.map(poly => {
                return this.formatPolygonLabelWithLocations(poly, locations);
            });
            const filteredMappedPolygonsToLoad = mappedPolygonsToLoad.filter(Boolean);
            polygonsToLoad.poligons = filteredMappedPolygonsToLoad;
            return polygonsToLoad;
        }
        formatPolygonLabelWithLocations(polygon, locations) {
            // Format actual polygon label element
            if (polygon.type === 'parent') {
                if (locations.cities[polygon.id])
                    polygon.label = polygon.label + ` <b>(${locations.cities[polygon.id]})</b>`;
                else if (this.config.showOnlyPolygonsWithProperties)
                    return null;
            }
            else if (polygon.type === 'children') {
                const [cityId, areaId] = polygon.id.split(':');
                if (locations.areas[cityId] && locations.areas[cityId][areaId])
                    polygon.label = polygon.label + ` <b>(${locations.areas[cityId][areaId]})</b>`;
                else if (this.config.showOnlyPolygonsWithProperties)
                    return null;
            }
            // Format child
            if (!polygon.children?.length)
                return polygon;
            // Recursive format children
            const mappedChildren = polygon.children.map(pl => this.formatPolygonLabelWithLocations(pl, locations)).filter(Boolean);
            polygon.children = mappedChildren;
            return polygon;
        }
    }
    exports.SooGoogleMap = SooGoogleMap;
});
