define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.benissaZoneLaFustera = void 0;
    exports.benissaZoneLaFustera = [
        [
            { lng: 0.0804, lat: 38.68187 },
            { lng: 0.08113, lat: 38.68124 },
            { lng: 0.08222, lat: 38.67885 },
            { lng: 0.08265, lat: 38.67835 },
            { lng: 0.08301, lat: 38.67818 },
            { lng: 0.08324, lat: 38.67764 },
            { lng: 0.08428, lat: 38.67706 },
            { lng: 0.08469, lat: 38.67639 },
            { lng: 0.08484, lat: 38.67504 },
            { lng: 0.08565, lat: 38.6742 },
            { lng: 0.08647, lat: 38.67363 },
            { lng: 0.09012, lat: 38.67255 },
            { lng: 0.09095, lat: 38.67205 },
            { lng: 0.09147, lat: 38.67138 },
            { lng: 0.09197, lat: 38.6711 },
            { lng: 0.09304, lat: 38.67151 },
            { lng: 0.09352, lat: 38.67152 },
            { lng: 0.09417, lat: 38.67122 },
            { lng: 0.095, lat: 38.66997 },
            { lng: 0.09438, lat: 38.66974 },
            { lng: 0.09365, lat: 38.66904 },
            { lng: 0.09349, lat: 38.66848 },
            { lng: 0.09374, lat: 38.66835 },
            { lng: 0.09317, lat: 38.6673 },
            { lng: 0.09104, lat: 38.66613 },
            { lng: 0.09002, lat: 38.66617 },
            { lng: 0.08961, lat: 38.66588 },
            { lng: 0.08956, lat: 38.66544 },
            { lng: 0.08895, lat: 38.665 },
            { lng: 0.08821, lat: 38.66505 },
            { lng: 0.08845, lat: 38.66498 },
            { lng: 0.08831, lat: 38.66419 },
            { lng: 0.08858, lat: 38.66381 },
            { lng: 0.08837, lat: 38.66298 },
            { lng: 0.08867, lat: 38.66301 },
            { lng: 0.08848, lat: 38.6627 },
            { lng: 0.0885, lat: 38.66218 },
            { lng: 0.08742, lat: 38.66135 },
            { lng: 0.08647, lat: 38.66125 },
            { lng: 0.08595, lat: 38.66046 },
            { lng: 0.08505, lat: 38.65983 },
            { lng: 0.08491, lat: 38.65948 },
            { lng: 0.08514, lat: 38.65932 },
            { lng: 0.0856, lat: 38.65994 },
            { lng: 0.08605, lat: 38.66016 },
            { lng: 0.08506, lat: 38.65884 },
            { lng: 0.08466, lat: 38.65919 },
            { lng: 0.08481, lat: 38.65966 },
            { lng: 0.08438, lat: 38.66075 },
            { lng: 0.08408, lat: 38.66232 },
            { lng: 0.08337, lat: 38.66191 },
            { lng: 0.08232, lat: 38.66169 },
            { lng: 0.08144, lat: 38.66179 },
            { lng: 0.08015, lat: 38.66283 },
            { lng: 0.07933, lat: 38.66326 },
            { lng: 0.07832, lat: 38.66426 },
            { lng: 0.07663, lat: 38.66511 },
            { lng: 0.07496, lat: 38.66649 },
            { lng: 0.07521, lat: 38.66937 },
            { lng: 0.07488, lat: 38.67109 },
            { lng: 0.07181, lat: 38.67542 },
            { lng: 0.06993, lat: 38.67698 },
            { lng: 0.06921, lat: 38.67802 },
            { lng: 0.0708, lat: 38.67899 },
            { lng: 0.07343, lat: 38.67994 },
            { lng: 0.07417, lat: 38.68071 },
            { lng: 0.07845, lat: 38.68171 },
            { lng: 0.0804, lat: 38.68187 }
        ]
    ];
});
