define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.javeaZoneElArenal = void 0;
    exports.javeaZoneElArenal = [
        [
            { lng: 0.20671, lat: 38.76222 },
            { lng: 0.20613, lat: 38.7617 },
            { lng: 0.20599, lat: 38.76112 },
            { lng: 0.20369, lat: 38.76126 },
            { lng: 0.20216, lat: 38.76044 },
            { lng: 0.19962, lat: 38.7606 },
            { lng: 0.19646, lat: 38.76149 },
            { lng: 0.19496, lat: 38.76169 },
            { lng: 0.19293, lat: 38.76239 },
            { lng: 0.19089, lat: 38.7628 },
            { lng: 0.189, lat: 38.76346 },
            { lng: 0.18766, lat: 38.76425 },
            { lng: 0.18718, lat: 38.76515 },
            { lng: 0.18334, lat: 38.77015 },
            { lng: 0.18248, lat: 38.77164 },
            { lng: 0.17908, lat: 38.77177 },
            { lng: 0.17631, lat: 38.77557 },
            { lng: 0.17611, lat: 38.78095 },
            { lng: 0.17483, lat: 38.78179 },
            { lng: 0.17338, lat: 38.78357 },
            { lng: 0.17243, lat: 38.78427 },
            { lng: 0.17146, lat: 38.78466 },
            { lng: 0.1711, lat: 38.78509 },
            { lng: 0.17151, lat: 38.78545 },
            { lng: 0.17225, lat: 38.78654 },
            { lng: 0.17283, lat: 38.78698 },
            { lng: 0.1748, lat: 38.78777 },
            { lng: 0.17602, lat: 38.78764 },
            { lng: 0.17676, lat: 38.78803 },
            { lng: 0.17746, lat: 38.78879 },
            { lng: 0.17716, lat: 38.79003 },
            { lng: 0.1774, lat: 38.79044 },
            { lng: 0.18071, lat: 38.79145 },
            { lng: 0.18186, lat: 38.79164 },
            { lng: 0.18229, lat: 38.79138 },
            { lng: 0.18225, lat: 38.79043 },
            { lng: 0.18259, lat: 38.78875 },
            { lng: 0.18288, lat: 38.7879 },
            { lng: 0.18341, lat: 38.78758 },
            { lng: 0.18328, lat: 38.78713 },
            { lng: 0.1835, lat: 38.78634 },
            { lng: 0.18447, lat: 38.78479 },
            { lng: 0.18504, lat: 38.78443 },
            { lng: 0.18547, lat: 38.78317 },
            { lng: 0.18616, lat: 38.78255 },
            { lng: 0.18621, lat: 38.78215 },
            { lng: 0.18719, lat: 38.78081 },
            { lng: 0.18741, lat: 38.78008 },
            { lng: 0.18898, lat: 38.77961 },
            { lng: 0.18896, lat: 38.77893 },
            { lng: 0.18953, lat: 38.77802 },
            { lng: 0.19003, lat: 38.77746 },
            { lng: 0.19037, lat: 38.77731 },
            { lng: 0.19035, lat: 38.77672 },
            { lng: 0.1906, lat: 38.77621 },
            { lng: 0.19095, lat: 38.77623 },
            { lng: 0.19162, lat: 38.77537 },
            { lng: 0.19156, lat: 38.77461 },
            { lng: 0.19065, lat: 38.77481 },
            { lng: 0.19023, lat: 38.77444 },
            { lng: 0.19017, lat: 38.77351 },
            { lng: 0.19057, lat: 38.7728 },
            { lng: 0.19141, lat: 38.77219 },
            { lng: 0.19264, lat: 38.77178 },
            { lng: 0.1938, lat: 38.77286 },
            { lng: 0.19536, lat: 38.77167 },
            { lng: 0.19582, lat: 38.77155 },
            { lng: 0.19588, lat: 38.77132 },
            { lng: 0.19628, lat: 38.77117 },
            { lng: 0.1965, lat: 38.77086 },
            { lng: 0.19781, lat: 38.77056 },
            { lng: 0.19857, lat: 38.76932 },
            { lng: 0.19959, lat: 38.76888 },
            { lng: 0.19991, lat: 38.76824 },
            { lng: 0.20045, lat: 38.76772 },
            { lng: 0.20264, lat: 38.76612 },
            { lng: 0.20261, lat: 38.76593 },
            { lng: 0.20292, lat: 38.76544 },
            { lng: 0.20469, lat: 38.76452 },
            { lng: 0.20502, lat: 38.76371 },
            { lng: 0.20671, lat: 38.76222 }
        ]
    ];
});
