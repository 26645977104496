define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.javeaZonePortichol = void 0;
    exports.javeaZonePortichol = [
        [
            { lng: 0.21348, lat: 38.73037 },
            { lng: 0.21774, lat: 38.73434 },
            { lng: 0.2185, lat: 38.73485 },
            { lng: 0.21869, lat: 38.73527 },
            { lng: 0.21812, lat: 38.73556 },
            { lng: 0.21809, lat: 38.73628 },
            { lng: 0.21697, lat: 38.73677 },
            { lng: 0.2166, lat: 38.73728 },
            { lng: 0.21547, lat: 38.74624 },
            { lng: 0.21638, lat: 38.74742 },
            { lng: 0.21669, lat: 38.74846 },
            { lng: 0.21756, lat: 38.74955 },
            { lng: 0.21786, lat: 38.75087 },
            { lng: 0.21741, lat: 38.75142 },
            { lng: 0.21709, lat: 38.75149 },
            { lng: 0.21493, lat: 38.75057 },
            { lng: 0.21391, lat: 38.75048 },
            { lng: 0.21206, lat: 38.75074 },
            { lng: 0.21186, lat: 38.75106 },
            { lng: 0.21236, lat: 38.75186 },
            { lng: 0.21211, lat: 38.75261 },
            { lng: 0.21228, lat: 38.75311 },
            { lng: 0.21274, lat: 38.75341 },
            { lng: 0.21415, lat: 38.75362 },
            { lng: 0.2128, lat: 38.75461 },
            { lng: 0.20987, lat: 38.75621 },
            { lng: 0.20884, lat: 38.75611 },
            { lng: 0.20923, lat: 38.75628 },
            { lng: 0.20977, lat: 38.75694 },
            { lng: 0.21063, lat: 38.75699 },
            { lng: 0.21191, lat: 38.7575 },
            { lng: 0.21343, lat: 38.75762 },
            { lng: 0.2134, lat: 38.75786 },
            { lng: 0.21227, lat: 38.75817 },
            { lng: 0.21204, lat: 38.7584 },
            { lng: 0.2116, lat: 38.76053 },
            { lng: 0.21215, lat: 38.76097 },
            { lng: 0.21375, lat: 38.76099 },
            { lng: 0.21398, lat: 38.76125 },
            { lng: 0.21501, lat: 38.76149 },
            { lng: 0.21612, lat: 38.76154 },
            { lng: 0.21895, lat: 38.76029 },
            { lng: 0.21978, lat: 38.76011 },
            { lng: 0.22026, lat: 38.7602 },
            { lng: 0.22092, lat: 38.76079 },
            { lng: 0.22101, lat: 38.76186 },
            { lng: 0.22084, lat: 38.76266 },
            { lng: 0.22105, lat: 38.76304 },
            { lng: 0.22172, lat: 38.76298 },
            { lng: 0.2223, lat: 38.76342 },
            { lng: 0.2227, lat: 38.76415 },
            { lng: 0.22389, lat: 38.76405 },
            { lng: 0.2242, lat: 38.76434 },
            { lng: 0.22471, lat: 38.76438 },
            { lng: 0.225, lat: 38.76398 },
            { lng: 0.22484, lat: 38.76362 },
            { lng: 0.22362, lat: 38.76376 },
            { lng: 0.22349, lat: 38.76357 },
            { lng: 0.22336, lat: 38.76308 },
            { lng: 0.22348, lat: 38.76275 },
            { lng: 0.22303, lat: 38.76249 },
            { lng: 0.22271, lat: 38.7617 },
            { lng: 0.22279, lat: 38.76136 },
            { lng: 0.22332, lat: 38.76095 },
            { lng: 0.22338, lat: 38.76057 },
            { lng: 0.22235, lat: 38.75929 },
            { lng: 0.22195, lat: 38.75903 },
            { lng: 0.22177, lat: 38.75824 },
            { lng: 0.22221, lat: 38.7573 },
            { lng: 0.22212, lat: 38.75685 },
            { lng: 0.2228, lat: 38.75578 },
            { lng: 0.22418, lat: 38.75491 },
            { lng: 0.22597, lat: 38.75428 },
            { lng: 0.22577, lat: 38.75377 },
            { lng: 0.22491, lat: 38.75358 },
            { lng: 0.22416, lat: 38.75281 },
            { lng: 0.22408, lat: 38.75246 },
            { lng: 0.22425, lat: 38.75212 },
            { lng: 0.22403, lat: 38.7513 },
            { lng: 0.22398, lat: 38.74992 },
            { lng: 0.22408, lat: 38.7491 },
            { lng: 0.22437, lat: 38.74862 },
            { lng: 0.22521, lat: 38.74796 },
            { lng: 0.22559, lat: 38.74798 },
            { lng: 0.22579, lat: 38.74771 },
            { lng: 0.22572, lat: 38.74709 },
            { lng: 0.22535, lat: 38.7464 },
            { lng: 0.22572, lat: 38.74565 },
            { lng: 0.22593, lat: 38.74478 },
            { lng: 0.22828, lat: 38.74546 },
            { lng: 0.22969, lat: 38.74524 },
            { lng: 0.23051, lat: 38.74578 },
            { lng: 0.23139, lat: 38.74588 },
            { lng: 0.23211, lat: 38.74624 },
            { lng: 0.23288, lat: 38.74596 },
            { lng: 0.23325, lat: 38.7456 },
            { lng: 0.23331, lat: 38.74542 },
            { lng: 0.23271, lat: 38.74428 },
            { lng: 0.23267, lat: 38.74354 },
            { lng: 0.23236, lat: 38.74327 },
            { lng: 0.23261, lat: 38.74235 },
            { lng: 0.23205, lat: 38.74175 },
            { lng: 0.23216, lat: 38.7411 },
            { lng: 0.23204, lat: 38.7401 },
            { lng: 0.23228, lat: 38.73983 },
            { lng: 0.2326, lat: 38.73981 },
            { lng: 0.23314, lat: 38.74036 },
            { lng: 0.23338, lat: 38.7404 },
            { lng: 0.23403, lat: 38.73996 },
            { lng: 0.23426, lat: 38.73943 },
            { lng: 0.23401, lat: 38.73906 },
            { lng: 0.23412, lat: 38.73871 },
            { lng: 0.23365, lat: 38.73833 },
            { lng: 0.23361, lat: 38.73797 },
            { lng: 0.23373, lat: 38.73741 },
            { lng: 0.23427, lat: 38.73717 },
            { lng: 0.23436, lat: 38.73687 },
            { lng: 0.23388, lat: 38.73682 },
            { lng: 0.23362, lat: 38.7365 },
            { lng: 0.23328, lat: 38.73646 },
            { lng: 0.23289, lat: 38.73679 },
            { lng: 0.23255, lat: 38.73685 },
            { lng: 0.23249, lat: 38.73642 },
            { lng: 0.23211, lat: 38.7363 },
            { lng: 0.23244, lat: 38.73557 },
            { lng: 0.2323, lat: 38.73545 },
            { lng: 0.23195, lat: 38.73557 },
            { lng: 0.23219, lat: 38.73487 },
            { lng: 0.23201, lat: 38.73427 },
            { lng: 0.23172, lat: 38.73397 },
            { lng: 0.23179, lat: 38.73357 },
            { lng: 0.23135, lat: 38.73341 },
            { lng: 0.23136, lat: 38.73302 },
            { lng: 0.23178, lat: 38.73261 },
            { lng: 0.23162, lat: 38.7325 },
            { lng: 0.2314, lat: 38.73259 },
            { lng: 0.23141, lat: 38.73215 },
            { lng: 0.2318, lat: 38.7317 },
            { lng: 0.23144, lat: 38.7314 },
            { lng: 0.22974, lat: 38.73182 },
            { lng: 0.2287, lat: 38.73187 },
            { lng: 0.22812, lat: 38.73217 },
            { lng: 0.22692, lat: 38.7321 },
            { lng: 0.22633, lat: 38.73247 },
            { lng: 0.2254, lat: 38.73213 },
            { lng: 0.22547, lat: 38.7317 },
            { lng: 0.2249, lat: 38.73177 },
            { lng: 0.22487, lat: 38.73153 },
            { lng: 0.22441, lat: 38.73143 },
            { lng: 0.224, lat: 38.73086 },
            { lng: 0.2233, lat: 38.73105 },
            { lng: 0.22271, lat: 38.73046 },
            { lng: 0.22181, lat: 38.73053 },
            { lng: 0.22144, lat: 38.73076 },
            { lng: 0.22114, lat: 38.73036 },
            { lng: 0.22032, lat: 38.73039 },
            { lng: 0.2196, lat: 38.73087 },
            { lng: 0.21787, lat: 38.73007 },
            { lng: 0.21724, lat: 38.72918 },
            { lng: 0.21643, lat: 38.72861 },
            { lng: 0.21646, lat: 38.728 },
            { lng: 0.21595, lat: 38.72777 },
            { lng: 0.21833, lat: 38.72701 },
            { lng: 0.21872, lat: 38.72677 },
            { lng: 0.21907, lat: 38.72621 },
            { lng: 0.21879, lat: 38.72604 },
            { lng: 0.21659, lat: 38.72661 },
            { lng: 0.21508, lat: 38.72724 },
            { lng: 0.21508, lat: 38.72749 },
            { lng: 0.21541, lat: 38.72774 },
            { lng: 0.21469, lat: 38.72778 },
            { lng: 0.21412, lat: 38.72816 },
            { lng: 0.21422, lat: 38.72848 },
            { lng: 0.21406, lat: 38.72907 },
            { lng: 0.21357, lat: 38.72948 },
            { lng: 0.21348, lat: 38.73037 }
        ],
        [
            { lng: 0.23194, lat: 38.75615 },
            { lng: 0.2317, lat: 38.75447 },
            { lng: 0.23109, lat: 38.75369 },
            { lng: 0.22865, lat: 38.75371 },
            { lng: 0.22827, lat: 38.75388 },
            { lng: 0.22822, lat: 38.75436 },
            { lng: 0.2286, lat: 38.75579 },
            { lng: 0.22879, lat: 38.75598 },
            { lng: 0.22943, lat: 38.75782 },
            { lng: 0.23062, lat: 38.75806 },
            { lng: 0.23182, lat: 38.75794 },
            { lng: 0.23194, lat: 38.75615 }
        ]
    ];
});
