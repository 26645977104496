define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.altea = void 0;
    exports.altea = [
        [
            { lng: -0.0811, lat: 38.6200755 },
            { lng: -0.0807019, lat: 38.6174348 },
            { lng: -0.080417, lat: 38.6145537 },
            { lng: -0.0780786, lat: 38.6062516 },
            { lng: -0.0797066, lat: 38.6054634 },
            { lng: -0.0813881, lat: 38.6059018 },
            { lng: -0.0830777, lat: 38.6061422 },
            { lng: -0.0840727, lat: 38.6062391 },
            { lng: -0.0847338, lat: 38.6040083 },
            { lng: -0.0848573, lat: 38.6035919 },
            { lng: -0.0845, lat: 38.6019252 },
            { lng: -0.0825055, lat: 38.6012808 },
            { lng: -0.0817634, lat: 38.5989109 },
            { lng: -0.0815526, lat: 38.5984461 },
            { lng: -0.0803085, lat: 38.5965681 },
            { lng: -0.0800756, lat: 38.5960848 },
            { lng: -0.0791143, lat: 38.5951598 },
            { lng: -0.0774174, lat: 38.5931263 },
            { lng: -0.0769288, lat: 38.5924384 },
            { lng: -0.0765914, lat: 38.5917002 },
            { lng: -0.0754557, lat: 38.5902663 },
            { lng: -0.0743239, lat: 38.5890217 },
            { lng: -0.0741917, lat: 38.588604 },
            { lng: -0.0744075, lat: 38.5858345 },
            { lng: -0.072746, lat: 38.5843514 },
            { lng: -0.0714575, lat: 38.5830127 },
            { lng: -0.0699859, lat: 38.5816605 },
            { lng: -0.0692533, lat: 38.5810475 },
            { lng: -0.0685767, lat: 38.5799043 },
            { lng: -0.0681097, lat: 38.579253 },
            { lng: -0.0670167, lat: 38.5785307 },
            { lng: -0.0667558, lat: 38.5783582 },
            { lng: -0.066735, lat: 38.5783446 },
            { lng: -0.0666784, lat: 38.5783171 },
            { lng: -0.0661289, lat: 38.5780512 },
            { lng: -0.0652042, lat: 38.5776034 },
            { lng: -0.0643717, lat: 38.5772149 },
            { lng: -0.0640217, lat: 38.5770512 },
            { lng: -0.0635978, lat: 38.576864 },
            { lng: -0.0635538, lat: 38.5771278 },
            { lng: -0.0633728, lat: 38.5777807 },
            { lng: -0.0631009, lat: 38.5783854 },
            { lng: -0.0626239, lat: 38.5797589 },
            { lng: -0.0621578, lat: 38.5809327 },
            { lng: -0.0617705, lat: 38.5818677 },
            { lng: -0.0616406, lat: 38.5821713 },
            { lng: -0.0613757, lat: 38.5826862 },
            { lng: -0.061204, lat: 38.582968 },
            { lng: -0.0607319, lat: 38.5836188 },
            { lng: -0.06032, lat: 38.5843099 },
            { lng: -0.0599423, lat: 38.5849204 },
            { lng: -0.059702, lat: 38.5853431 },
            { lng: -0.0593586, lat: 38.585853 },
            { lng: -0.059187, lat: 38.586222 },
            { lng: -0.0588778, lat: 38.5867348 },
            { lng: -0.0584059, lat: 38.5872686 },
            { lng: -0.0579516, lat: 38.5876194 },
            { lng: -0.0577487, lat: 38.5877843 },
            { lng: -0.0548053, lat: 38.5878968 },
            { lng: -0.0547293, lat: 38.5879429 },
            { lng: -0.0547478, lat: 38.5880729 },
            { lng: -0.0548496, lat: 38.5880947 },
            { lng: -0.0548752, lat: 38.5880593 },
            { lng: -0.0551086, lat: 38.5880567 },
            { lng: -0.0551155, lat: 38.588138 },
            { lng: -0.0552822, lat: 38.5881322 },
            { lng: -0.0548981, lat: 38.5889893 },
            { lng: -0.0549513, lat: 38.5889984 },
            { lng: -0.0553259, lat: 38.5881669 },
            { lng: -0.055874, lat: 38.5881543 },
            { lng: -0.0572406, lat: 38.5885158 },
            { lng: -0.0567553, lat: 38.5896152 },
            { lng: -0.0563919, lat: 38.5901031 },
            { lng: -0.0551656, lat: 38.5897775 },
            { lng: -0.0551083, lat: 38.5899107 },
            { lng: -0.055297, lat: 38.589965 },
            { lng: -0.0552737, lat: 38.5900087 },
            { lng: -0.0550696, lat: 38.589962 },
            { lng: -0.0550432, lat: 38.5900293 },
            { lng: -0.0553073, lat: 38.5901048 },
            { lng: -0.0552182, lat: 38.5903026 },
            { lng: -0.0558453, lat: 38.5904906 },
            { lng: -0.0556638, lat: 38.5909173 },
            { lng: -0.0554662, lat: 38.5908669 },
            { lng: -0.0552429, lat: 38.5913739 },
            { lng: -0.0551076, lat: 38.5913336 },
            { lng: -0.0549272, lat: 38.5917281 },
            { lng: -0.0544182, lat: 38.5915904 },
            { lng: -0.0544053, lat: 38.5916576 },
            { lng: -0.0543108, lat: 38.5916794 },
            { lng: -0.0542442, lat: 38.5915485 },
            { lng: -0.0540767, lat: 38.5915904 },
            { lng: -0.0533465, lat: 38.5899504 },
            { lng: -0.0536151, lat: 38.5898715 },
            { lng: -0.0532135, lat: 38.5889734 },
            { lng: -0.0539866, lat: 38.5872225 },
            { lng: -0.0538019, lat: 38.5871453 },
            { lng: -0.0537503, lat: 38.5870882 },
            { lng: -0.0537916, lat: 38.5869523 },
            { lng: -0.0542293, lat: 38.5859401 },
            { lng: -0.0543169, lat: 38.5858854 },
            { lng: -0.0543223, lat: 38.5857596 },
            { lng: -0.0542457, lat: 38.5856447 },
            { lng: -0.0541418, lat: 38.5856228 },
            { lng: -0.0539776, lat: 38.5856721 },
            { lng: -0.0538299, lat: 38.5858143 },
            { lng: -0.0537916, lat: 38.5859675 },
            { lng: -0.0524293, lat: 38.5890368 },
            { lng: -0.0525934, lat: 38.5892557 },
            { lng: -0.0536035, lat: 38.5911534 },
            { lng: -0.0538196, lat: 38.5918129 },
            { lng: -0.0532231, lat: 38.5926196 },
            { lng: -0.0526352, lat: 38.5931847 },
            { lng: -0.0521481, lat: 38.5937424 },
            { lng: -0.0517039, lat: 38.5942111 },
            { lng: -0.0513681, lat: 38.5944795 },
            { lng: -0.0503606, lat: 38.5949432 },
            { lng: -0.0500002, lat: 38.5950774 },
            { lng: -0.049645, lat: 38.5954035 },
            { lng: -0.0494573, lat: 38.5956886 },
            { lng: -0.0494305, lat: 38.5960861 },
            { lng: -0.0494197, lat: 38.5965213 },
            { lng: -0.0492789, lat: 38.5969008 },
            { lng: -0.0489999, lat: 38.5972404 },
            { lng: -0.0487102, lat: 38.5975549 },
            { lng: -0.0485654, lat: 38.5977813 },
            { lng: -0.0469808, lat: 38.5992408 },
            { lng: -0.0469038, lat: 38.5993009 },
            { lng: -0.0469079, lat: 38.5995451 },
            { lng: -0.0469098, lat: 38.5996703 },
            { lng: -0.0469064, lat: 38.5997039 },
            { lng: -0.0469084, lat: 38.599729 },
            { lng: -0.0469189, lat: 38.5997531 },
            { lng: -0.0469386, lat: 38.5997788 },
            { lng: -0.0469628, lat: 38.5998023 },
            { lng: -0.0470057, lat: 38.5998234 },
            { lng: -0.0470472, lat: 38.5998296 },
            { lng: -0.0470835, lat: 38.5998297 },
            { lng: -0.047121, lat: 38.5998264 },
            { lng: -0.0471532, lat: 38.5998149 },
            { lng: -0.047174, lat: 38.5998049 },
            { lng: -0.0471995, lat: 38.5998432 },
            { lng: -0.0471603, lat: 38.5998829 },
            { lng: -0.0471324, lat: 38.5999146 },
            { lng: -0.0471257, lat: 38.5999308 },
            { lng: -0.0471203, lat: 38.5999464 },
            { lng: -0.0471237, lat: 38.5999638 },
            { lng: -0.0471317, lat: 38.5999785 },
            { lng: -0.0471398, lat: 38.5999942 },
            { lng: -0.0471499, lat: 38.6000048 },
            { lng: -0.0469527, lat: 38.6002079 },
            { lng: -0.0468736, lat: 38.600209 },
            { lng: -0.0468119, lat: 38.6002289 },
            { lng: -0.046769, lat: 38.6002657 },
            { lng: -0.0467488, lat: 38.6002991 },
            { lng: -0.0467448, lat: 38.6003369 },
            { lng: -0.0467542, lat: 38.600363 },
            { lng: -0.0467757, lat: 38.6003935 },
            { lng: -0.0468038, lat: 38.6004196 },
            { lng: -0.0465866, lat: 38.6006544 },
            { lng: -0.0465504, lat: 38.6006419 },
            { lng: -0.0465011, lat: 38.6006381 },
            { lng: -0.0464645, lat: 38.6006409 },
            { lng: -0.046427, lat: 38.6006502 },
            { lng: -0.0463854, lat: 38.6006713 },
            { lng: -0.0463599, lat: 38.6007026 },
            { lng: -0.0463492, lat: 38.6007487 },
            { lng: -0.0463599, lat: 38.6007824 },
            { lng: -0.0463817, lat: 38.6008197 },
            { lng: -0.0462191, lat: 38.6009867 },
            { lng: -0.046117, lat: 38.6009988 },
            { lng: -0.0460743, lat: 38.6010109 },
            { lng: -0.0460314, lat: 38.6010359 },
            { lng: -0.0460005, lat: 38.6010759 },
            { lng: -0.0460005, lat: 38.6011156 },
            { lng: -0.0460126, lat: 38.6011576 },
            { lng: -0.0460421, lat: 38.6012026 },
            { lng: -0.0458342, lat: 38.6014352 },
            { lng: -0.0457819, lat: 38.6014249 },
            { lng: -0.0457108, lat: 38.6014248 },
            { lng: -0.0456532, lat: 38.6014394 },
            { lng: -0.0456103, lat: 38.6014647 },
            { lng: -0.0455875, lat: 38.601495 },
            { lng: -0.0455754, lat: 38.6015307 },
            { lng: -0.0455834, lat: 38.6015568 },
            { lng: -0.0455982, lat: 38.6015946 },
            { lng: -0.0454171, lat: 38.6018063 },
            { lng: -0.0453554, lat: 38.6018052 },
            { lng: -0.0453072, lat: 38.6018127 },
            { lng: -0.0452642, lat: 38.6018283 },
            { lng: -0.0452294, lat: 38.6018681 },
            { lng: -0.0452119, lat: 38.601908 },
            { lng: -0.045216, lat: 38.6019425 },
            { lng: -0.0452361, lat: 38.601973 },
            { lng: -0.0452631, lat: 38.6020006 },
            { lng: -0.0450671, lat: 38.6021878 },
            { lng: -0.0450202, lat: 38.6021826 },
            { lng: -0.0449621, lat: 38.6021874 },
            { lng: -0.0449263, lat: 38.6021994 },
            { lng: -0.0448901, lat: 38.6022192 },
            { lng: -0.0448619, lat: 38.6022507 },
            { lng: -0.0448512, lat: 38.60228 },
            { lng: -0.0448539, lat: 38.6023052 },
            { lng: -0.0448592, lat: 38.6023304 },
            { lng: -0.0448793, lat: 38.6023701 },
            { lng: -0.0446979, lat: 38.60254 },
            { lng: -0.044713, lat: 38.6025664 },
            { lng: -0.0446506, lat: 38.6026131 },
            { lng: -0.0446065, lat: 38.6026027 },
            { lng: -0.0443522, lat: 38.6027013 },
            { lng: -0.0439525, lat: 38.6028414 },
            { lng: -0.0437605, lat: 38.6028985 },
            { lng: -0.0435373, lat: 38.6029427 },
            { lng: -0.0434464, lat: 38.6029427 },
            { lng: -0.04334, lat: 38.6029271 },
            { lng: -0.0431843, lat: 38.6029401 },
            { lng: -0.0431734, lat: 38.6029486 },
            { lng: -0.0430546, lat: 38.6030387 },
            { lng: -0.0428859, lat: 38.6031088 },
            { lng: -0.0427899, lat: 38.6031555 },
            { lng: -0.0424966, lat: 38.6032281 },
            { lng: -0.0423123, lat: 38.6032541 },
            { lng: -0.0421981, lat: 38.6032255 },
            { lng: -0.0420466, lat: 38.6032442 },
            { lng: -0.0419318, lat: 38.6032582 },
            { lng: -0.0416826, lat: 38.6032886 },
            { lng: -0.0414287, lat: 38.6032822 },
            { lng: -0.0414441, lat: 38.6033981 },
            { lng: -0.0413978, lat: 38.6034599 },
            { lng: -0.0408069, lat: 38.6039735 },
            { lng: -0.0401611, lat: 38.6047829 },
            { lng: -0.0401543, lat: 38.6047883 },
            { lng: -0.0399213, lat: 38.6051569 },
            { lng: -0.0396322, lat: 38.6055295 },
            { lng: -0.039459, lat: 38.6056977 },
            { lng: -0.0392651, lat: 38.6058909 },
            { lng: -0.0389426, lat: 38.6063024 },
            { lng: -0.0384921, lat: 38.6068085 },
            { lng: -0.0382586, lat: 38.607131 },
            { lng: -0.0380828, lat: 38.6074063 },
            { lng: -0.0372048, lat: 38.6087876 },
            { lng: -0.0358392, lat: 38.6103808 },
            { lng: -0.0351399, lat: 38.6113343 },
            { lng: -0.0342134, lat: 38.6122323 },
            { lng: -0.0336411, lat: 38.6124019 },
            { lng: -0.0331218, lat: 38.6124973 },
            { lng: -0.0330265, lat: 38.6123807 },
            { lng: -0.0328993, lat: 38.6124549 },
            { lng: -0.0327297, lat: 38.6125609 },
            { lng: -0.0325495, lat: 38.6126457 },
            { lng: -0.0325072, lat: 38.6127728 },
            { lng: -0.0323588, lat: 38.6129 },
            { lng: -0.0325792, lat: 38.6128657 },
            { lng: -0.032742, lat: 38.6128996 },
            { lng: -0.0330201, lat: 38.6130556 },
            { lng: -0.0331354, lat: 38.6132794 },
            { lng: -0.0331218, lat: 38.6136592 },
            { lng: -0.0330676, lat: 38.6137813 },
            { lng: -0.032898, lat: 38.6137678 },
            { lng: -0.0327827, lat: 38.6138356 },
            { lng: -0.0327827, lat: 38.6139645 },
            { lng: -0.03243, lat: 38.6140662 },
            { lng: -0.0321723, lat: 38.6139441 },
            { lng: -0.0320785, lat: 38.6138903 },
            { lng: -0.0320035, lat: 38.6139341 },
            { lng: -0.032366, lat: 38.6142029 },
            { lng: -0.0321973, lat: 38.6145467 },
            { lng: -0.031966, lat: 38.6144904 },
            { lng: -0.0318534, lat: 38.6141904 },
            { lng: -0.0319181, lat: 38.6141409 },
            { lng: -0.0317805, lat: 38.6140449 },
            { lng: -0.0316877, lat: 38.6141217 },
            { lng: -0.0318893, lat: 38.6146337 },
            { lng: -0.0323936, lat: 38.6151233 },
            { lng: -0.0322331, lat: 38.61541 },
            { lng: -0.0319578, lat: 38.6157197 },
            { lng: -0.0315679, lat: 38.6161325 },
            { lng: -0.0311207, lat: 38.616511 },
            { lng: -0.0304301, lat: 38.6169888 },
            { lng: -0.0300691, lat: 38.6172137 },
            { lng: -0.029483, lat: 38.617516 },
            { lng: -0.0290769, lat: 38.6180176 },
            { lng: -0.0287079, lat: 38.6183462 },
            { lng: -0.0283388, lat: 38.6185876 },
            { lng: -0.0279011, lat: 38.6187888 },
            { lng: -0.0274977, lat: 38.6189028 },
            { lng: -0.0258068, lat: 38.6195935 },
            { lng: -0.0237554, lat: 38.6202507 },
            { lng: -0.022674, lat: 38.6211023 },
            { lng: -0.0215925, lat: 38.622296 },
            { lng: -0.0207685, lat: 38.6228458 },
            { lng: -0.0197428, lat: 38.6236982 },
            { lng: -0.0194897, lat: 38.6237137 },
            { lng: -0.0188756, lat: 38.6236125 },
            { lng: -0.0188221, lat: 38.6236284 },
            { lng: -0.0187476, lat: 38.6237544 },
            { lng: -0.0187452, lat: 38.6238339 },
            { lng: -0.0187898, lat: 38.6238611 },
            { lng: -0.0188915, lat: 38.6237622 },
            { lng: -0.0193581, lat: 38.62383 },
            { lng: -0.0193361, lat: 38.6239541 },
            { lng: -0.0192658, lat: 38.6239534 },
            { lng: -0.0192216, lat: 38.6241306 },
            { lng: -0.0187799, lat: 38.6240724 },
            { lng: -0.0187799, lat: 38.6239968 },
            { lng: -0.0186136, lat: 38.6240453 },
            { lng: -0.0185864, lat: 38.6241209 },
            { lng: -0.0186111, lat: 38.6241635 },
            { lng: -0.018698, lat: 38.6241151 },
            { lng: -0.018971, lat: 38.6243594 },
            { lng: -0.0187426, lat: 38.6244932 },
            { lng: -0.0184696, lat: 38.6242295 },
            { lng: -0.0184175, lat: 38.6242527 },
            { lng: -0.0187451, lat: 38.6245765 },
            { lng: -0.0183704, lat: 38.6247045 },
            { lng: -0.0182736, lat: 38.6245242 },
            { lng: -0.0183133, lat: 38.6244525 },
            { lng: -0.0182636, lat: 38.6244583 },
            { lng: -0.0182239, lat: 38.6245223 },
            { lng: -0.0183232, lat: 38.6247103 },
            { lng: -0.0180452, lat: 38.6247995 },
            { lng: -0.0177276, lat: 38.6241791 },
            { lng: -0.018276, lat: 38.6235217 },
            { lng: -0.0186158, lat: 38.6234128 },
            { lng: -0.0186632, lat: 38.6233976 },
            { lng: -0.0187129, lat: 38.6232193 },
            { lng: -0.0182785, lat: 38.6232445 },
            { lng: -0.0174943, lat: 38.6241829 },
            { lng: -0.0178467, lat: 38.6247666 },
            { lng: -0.0177871, lat: 38.6250361 },
            { lng: -0.0172924, lat: 38.6254409 },
            { lng: -0.0164856, lat: 38.6259773 },
            { lng: -0.0159706, lat: 38.6264534 },
            { lng: -0.0143226, lat: 38.6273519 },
            { lng: -0.0133957, lat: 38.6276604 },
            { lng: -0.0126919, lat: 38.6276 },
            { lng: -0.0117906, lat: 38.6272648 },
            { lng: -0.0112242, lat: 38.6275933 },
            { lng: -0.0107263, lat: 38.6276805 },
            { lng: -0.0098423, lat: 38.6282236 },
            { lng: -0.0090956, lat: 38.628284 },
            { lng: -0.009007, lat: 38.6285605 },
            { lng: -0.0082373, lat: 38.6292495 },
            { lng: -0.0077995, lat: 38.6295378 },
            { lng: -0.007372, lat: 38.629791 },
            { lng: -0.0071264, lat: 38.6297393 },
            { lng: -0.0068431, lat: 38.6292364 },
            { lng: -0.0066457, lat: 38.6291022 },
            { lng: -0.0066018, lat: 38.6292095 },
            { lng: -0.0067378, lat: 38.629364 },
            { lng: -0.0069153, lat: 38.6297337 },
            { lng: -0.0068386, lat: 38.6300635 },
            { lng: -0.0063279, lat: 38.6303152 },
            { lng: -0.0055276, lat: 38.6303551 },
            { lng: -0.0049053, lat: 38.6303149 },
            { lng: -0.004555, lat: 38.6301515 },
            { lng: -0.0042596, lat: 38.6299404 },
            { lng: -0.0040794, lat: 38.6297795 },
            { lng: -0.0041085, lat: 38.6297394 },
            { lng: -0.0041085, lat: 38.6296122 },
            { lng: -0.0040844, lat: 38.6295684 },
            { lng: -0.0040966, lat: 38.6294375 },
            { lng: -0.0036652, lat: 38.629419 },
            { lng: -0.0036541, lat: 38.6295819 },
            { lng: -0.0037714, lat: 38.6295853 },
            { lng: -0.0037346, lat: 38.6301168 },
            { lng: -0.0023921, lat: 38.6300635 },
            { lng: -0.0023749, lat: 38.6303112 },
            { lng: -0.0013826, lat: 38.6302783 },
            { lng: -0.00139, lat: 38.6301195 },
            { lng: -0.0010344, lat: 38.6301044 },
            { lng: -0.001041, lat: 38.6299269 },
            { lng: -0.0003543, lat: 38.6299135 },
            { lng: -0.0003572, lat: 38.6297052 },
            { lng: -0.0003688, lat: 38.6293912 },
            { lng: -0.0003733, lat: 38.6290451 },
            { lng: -0.0003801, lat: 38.6287468 },
            { lng: -0.0043369, lat: 38.6288542 },
            { lng: -0.0046116, lat: 38.6289748 },
            { lng: -0.0047586, lat: 38.6289761 },
            { lng: -0.0048862, lat: 38.6289146 },
            { lng: -0.004927, lat: 38.6288337 },
            { lng: -0.004912, lat: 38.6287401 },
            { lng: -0.0048486, lat: 38.6286678 },
            { lng: -0.0047317, lat: 38.6286127 },
            { lng: -0.0044227, lat: 38.6285859 },
            { lng: -0.0002753, lat: 38.6284547 },
            { lng: 0, lat: 38.628446 },
            { lng: 0.000155, lat: 38.6284584 },
            { lng: 0.000391, lat: 38.6285092 },
            { lng: 0.0004554, lat: 38.6286764 },
            { lng: 0.0003374, lat: 38.6288105 },
            { lng: 0.0000477, lat: 38.628769 },
            { lng: -0.0000439, lat: 38.6287873 },
            { lng: -0.0000167, lat: 38.6298162 },
            { lng: 0.0005627, lat: 38.6298833 },
            { lng: 0.0006485, lat: 38.6300341 },
            { lng: 0.0010347, lat: 38.6303359 },
            { lng: 0.0018151, lat: 38.6303405 },
            { lng: 0.0020218, lat: 38.6304364 },
            { lng: 0.0024939, lat: 38.630537 },
            { lng: 0.0031483, lat: 38.6306424 },
            { lng: 0.003285, lat: 38.630659 },
            { lng: 0.0034594, lat: 38.6306485 },
            { lng: 0.0037599, lat: 38.6306041 },
            { lng: 0.0042319, lat: 38.6304532 },
            { lng: 0.0043821, lat: 38.6302353 },
            { lng: 0.0047684, lat: 38.6300509 },
            { lng: 0.0050688, lat: 38.6293469 },
            { lng: 0.0049835, lat: 38.6290208 },
            { lng: 0.0050688, lat: 38.6287937 },
            { lng: 0.0058412, lat: 38.6282673 },
            { lng: 0.0060161, lat: 38.6287216 },
            { lng: 0.005955, lat: 38.6288503 },
            { lng: 0.0061245, lat: 38.6289006 },
            { lng: 0.0062489, lat: 38.6290883 },
            { lng: 0.0057769, lat: 38.6294475 },
            { lng: 0.0055623, lat: 38.629833 },
            { lng: 0.005609, lat: 38.6299839 },
            { lng: 0.0057747, lat: 38.6301916 },
            { lng: 0.0059952, lat: 38.6303108 },
            { lng: 0.0063562, lat: 38.6303359 },
            { lng: 0.006957, lat: 38.6303023 },
            { lng: 0.0077081, lat: 38.6303191 },
            { lng: 0.0084591, lat: 38.6302185 },
            { lng: 0.0090384, lat: 38.6303526 },
            { lng: 0.0091886, lat: 38.6305203 },
            { lng: 0.0090814, lat: 38.6308723 },
            { lng: 0.0092316, lat: 38.6313249 },
            { lng: 0.0095105, lat: 38.6319451 },
            { lng: 0.0099611, lat: 38.63223 },
            { lng: 0.0104332, lat: 38.6323473 },
            { lng: 0.0109952, lat: 38.6323551 },
            { lng: 0.0109811, lat: 38.6327118 },
            { lng: 0.0110115, lat: 38.6332175 },
            { lng: 0.0099208, lat: 38.6335978 },
            { lng: 0.0089903, lat: 38.633182 },
            { lng: 0.0078328, lat: 38.6338908 },
            { lng: 0.0074432, lat: 38.633922 },
            { lng: 0.006592, lat: 38.6343899 },
            { lng: 0.0064425, lat: 38.6350221 },
            { lng: 0.0063697, lat: 38.6353302 },
            { lng: 0.0062251, lat: 38.6356523 },
            { lng: 0.0062108, lat: 38.6357615 },
            { lng: 0.0062042, lat: 38.635905 },
            { lng: 0.0062229, lat: 38.6360778 },
            { lng: 0.0062958, lat: 38.6362344 },
            { lng: 0.0064643, lat: 38.6364078 },
            { lng: 0.0065448, lat: 38.6365053 },
            { lng: 0.0065931, lat: 38.6366173 },
            { lng: 0.0066132, lat: 38.6368321 },
            { lng: 0.0065661, lat: 38.6372451 },
            { lng: 0.0040229, lat: 38.6378751 },
            { lng: 0.0029208, lat: 38.6379413 },
            { lng: -0.0013745, lat: 38.6389787 },
            { lng: -0.0039178, lat: 38.6402148 },
            { lng: -0.0056981, lat: 38.6416054 },
            { lng: -0.0064122, lat: 38.6427552 },
            { lng: -0.0066777, lat: 38.6434009 },
            { lng: -0.0096616, lat: 38.6448217 },
            { lng: -0.0116565, lat: 38.6457716 },
            { lng: -0.0443928, lat: 38.660956 },
            { lng: -0.0448419, lat: 38.6604712 },
            { lng: -0.0465452, lat: 38.658632 },
            { lng: -0.0512587, lat: 38.6596881 },
            { lng: -0.0558611, lat: 38.6595339 },
            { lng: -0.0562064, lat: 38.660921 },
            { lng: -0.0564913, lat: 38.6615408 },
            { lng: -0.0577732, lat: 38.6616632 },
            { lng: -0.0587596, lat: 38.661706 },
            { lng: -0.0591663, lat: 38.662437 },
            { lng: -0.0596157, lat: 38.6638087 },
            { lng: -0.0600403, lat: 38.6646663 },
            { lng: -0.0600112, lat: 38.6639719 },
            { lng: -0.0598233, lat: 38.6629491 },
            { lng: -0.0600028, lat: 38.6624941 },
            { lng: -0.0603115, lat: 38.6619703 },
            { lng: -0.0604777, lat: 38.6615601 },
            { lng: -0.0605472, lat: 38.6609852 },
            { lng: -0.0605171, lat: 38.6605971 },
            { lng: -0.0607803, lat: 38.6603424 },
            { lng: -0.0615126, lat: 38.6598563 },
            { lng: -0.0624192, lat: 38.658762 },
            { lng: -0.0628522, lat: 38.6580071 },
            { lng: -0.0624758, lat: 38.6570967 },
            { lng: -0.0620598, lat: 38.6563114 },
            { lng: -0.0615145, lat: 38.6547571 },
            { lng: -0.0615746, lat: 38.6538487 },
            { lng: -0.0618798, lat: 38.6528473 },
            { lng: -0.0633606, lat: 38.6506322 },
            { lng: -0.0641481, lat: 38.6493547 },
            { lng: -0.0647784, lat: 38.6485507 },
            { lng: -0.0665279, lat: 38.6445314 },
            { lng: -0.0693378, lat: 38.6384485 },
            { lng: -0.07093, lat: 38.6365875 },
            { lng: -0.0714879, lat: 38.6358627 },
            { lng: -0.0742981, lat: 38.6314464 },
            { lng: -0.0763522, lat: 38.6286689 },
            { lng: -0.0777717, lat: 38.627371 },
            { lng: -0.0770089, lat: 38.6266402 },
            { lng: -0.0766142, lat: 38.6261798 },
            { lng: -0.076343, lat: 38.6255063 },
            { lng: -0.0777641, lat: 38.6238842 },
            { lng: -0.0780811, lat: 38.6234326 },
            { lng: -0.0781324, lat: 38.6224519 },
            { lng: -0.0785053, lat: 38.6220377 },
            { lng: -0.0792928, lat: 38.6213096 },
            { lng: -0.0802658, lat: 38.6205411 },
            { lng: -0.0811, lat: 38.6200755 }
        ]
    ];
});
