define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.javeaZoneMontgo = void 0;
    exports.javeaZoneMontgo = [
        [
            { lng: 0.1416, lat: 38.80345 },
            { lng: 0.14094, lat: 38.79271 },
            { lng: 0.13994, lat: 38.79033 },
            { lng: 0.13938, lat: 38.78992 },
            { lng: 0.13913, lat: 38.78951 },
            { lng: 0.13757, lat: 38.78837 },
            { lng: 0.13731, lat: 38.78811 },
            { lng: 0.13713, lat: 38.7875 },
            { lng: 0.13681, lat: 38.78735 },
            { lng: 0.13666, lat: 38.78707 },
            { lng: 0.13746, lat: 38.78612 },
            { lng: 0.13728, lat: 38.78528 },
            { lng: 0.13775, lat: 38.78463 },
            { lng: 0.13749, lat: 38.7839 },
            { lng: 0.13809, lat: 38.78322 },
            { lng: 0.13795, lat: 38.78297 },
            { lng: 0.13797, lat: 38.7821 },
            { lng: 0.13754, lat: 38.78205 },
            { lng: 0.13699, lat: 38.77753 },
            { lng: 0.13701, lat: 38.77532 },
            { lng: 0.1294, lat: 38.77522 },
            { lng: 0.11451, lat: 38.77604 },
            { lng: 0.10833, lat: 38.77763 },
            { lng: 0.10365, lat: 38.77942 },
            { lng: 0.10319, lat: 38.79442 },
            { lng: 0.10349, lat: 38.79565 },
            { lng: 0.10321, lat: 38.79675 },
            { lng: 0.10308, lat: 38.80002 },
            { lng: 0.10282, lat: 38.80133 },
            { lng: 0.10473, lat: 38.80314 },
            { lng: 0.10602, lat: 38.80475 },
            { lng: 0.10565, lat: 38.80787 },
            { lng: 0.10863, lat: 38.8078 },
            { lng: 0.10968, lat: 38.80755 },
            { lng: 0.11049, lat: 38.80778 },
            { lng: 0.11173, lat: 38.80773 },
            { lng: 0.11292, lat: 38.80839 },
            { lng: 0.1196, lat: 38.80788 },
            { lng: 0.12106, lat: 38.80747 },
            { lng: 0.12247, lat: 38.80764 },
            { lng: 0.12332, lat: 38.80685 },
            { lng: 0.1244, lat: 38.80671 },
            { lng: 0.12667, lat: 38.80556 },
            { lng: 0.1279, lat: 38.80529 },
            { lng: 0.12882, lat: 38.8036 },
            { lng: 0.1299, lat: 38.8034 },
            { lng: 0.13223, lat: 38.80334 },
            { lng: 0.1416, lat: 38.80345 }
        ]
    ];
});
