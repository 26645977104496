define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.javea = void 0;
    exports.javea = [
        [
            { lng: 0.1171536, lat: 38.7493531 },
            { lng: 0.1167492, lat: 38.7453462 },
            { lng: 0.1168395, lat: 38.7424049 },
            { lng: 0.1177986, lat: 38.7409199 },
            { lng: 0.118007, lat: 38.7406892 },
            { lng: 0.1229003, lat: 38.7426395 },
            { lng: 0.1290749, lat: 38.7431049 },
            { lng: 0.1311012, lat: 38.7434034 },
            { lng: 0.1322331, lat: 38.7435667 },
            { lng: 0.1363922, lat: 38.7433892 },
            { lng: 0.1374417, lat: 38.7433075 },
            { lng: 0.1387001, lat: 38.7432971 },
            { lng: 0.1457949, lat: 38.7419261 },
            { lng: 0.1497908, lat: 38.7412418 },
            { lng: 0.1546585, lat: 38.739723 },
            { lng: 0.1552697, lat: 38.7394993 },
            { lng: 0.1554896, lat: 38.7392681 },
            { lng: 0.1567856, lat: 38.7370693 },
            { lng: 0.1587082, lat: 38.7344917 },
            { lng: 0.1612816, lat: 38.7310756 },
            { lng: 0.1641673, lat: 38.7284479 },
            { lng: 0.1643514, lat: 38.7282788 },
            { lng: 0.1673855, lat: 38.7258022 },
            { lng: 0.1753936, lat: 38.7196659 },
            { lng: 0.1793487, lat: 38.7168016 },
            { lng: 0.1804719, lat: 38.7167974 },
            { lng: 0.1806359, lat: 38.7169362 },
            { lng: 0.180666, lat: 38.717056 },
            { lng: 0.1805453, lat: 38.7172971 },
            { lng: 0.1806783, lat: 38.7177039 },
            { lng: 0.1806772, lat: 38.7179826 },
            { lng: 0.180791, lat: 38.7184104 },
            { lng: 0.1811037, lat: 38.7187151 },
            { lng: 0.18149, lat: 38.7190776 },
            { lng: 0.1816943, lat: 38.7194186 },
            { lng: 0.1820146, lat: 38.7198899 },
            { lng: 0.1819824, lat: 38.7201833 },
            { lng: 0.182102, lat: 38.7205265 },
            { lng: 0.1823901, lat: 38.7205938 },
            { lng: 0.1825087, lat: 38.7207458 },
            { lng: 0.1827822, lat: 38.7208145 },
            { lng: 0.1828949, lat: 38.7206785 },
            { lng: 0.1830124, lat: 38.7203523 },
            { lng: 0.1832758, lat: 38.7203131 },
            { lng: 0.1835381, lat: 38.7201118 },
            { lng: 0.1837602, lat: 38.7198129 },
            { lng: 0.1838422, lat: 38.7195212 },
            { lng: 0.1841507, lat: 38.7194953 },
            { lng: 0.1844248, lat: 38.7196305 },
            { lng: 0.1846179, lat: 38.7199414 },
            { lng: 0.1849339, lat: 38.720234 },
            { lng: 0.1852804, lat: 38.7204801 },
            { lng: 0.1854484, lat: 38.7209204 },
            { lng: 0.1856742, lat: 38.721174 },
            { lng: 0.1858625, lat: 38.7212205 },
            { lng: 0.1861184, lat: 38.7212096 },
            { lng: 0.1864392, lat: 38.7213188 },
            { lng: 0.1866768, lat: 38.7215719 },
            { lng: 0.1866135, lat: 38.7217774 },
            { lng: 0.1867706, lat: 38.7220768 },
            { lng: 0.1869936, lat: 38.7221126 },
            { lng: 0.1871875, lat: 38.721952 },
            { lng: 0.1874788, lat: 38.7219917 },
            { lng: 0.1877604, lat: 38.7222496 },
            { lng: 0.1880329, lat: 38.7224698 },
            { lng: 0.1881021, lat: 38.7227053 },
            { lng: 0.1885313, lat: 38.7230117 },
            { lng: 0.1890312, lat: 38.7232177 },
            { lng: 0.1893976, lat: 38.7232552 },
            { lng: 0.1897946, lat: 38.7231397 },
            { lng: 0.1901208, lat: 38.7232331 },
            { lng: 0.1905698, lat: 38.723467 },
            { lng: 0.1909801, lat: 38.7236416 },
            { lng: 0.1912929, lat: 38.723845 },
            { lng: 0.1915418, lat: 38.7239462 },
            { lng: 0.1919731, lat: 38.7239384 },
            { lng: 0.1922934, lat: 38.7240295 },
            { lng: 0.1925905, lat: 38.7240341 },
            { lng: 0.1931189, lat: 38.7241936 },
            { lng: 0.1932957, lat: 38.7242253 },
            { lng: 0.193386, lat: 38.7242777 },
            { lng: 0.1934375, lat: 38.72432 },
            { lng: 0.1935983, lat: 38.7244293 },
            { lng: 0.1934617, lat: 38.7242303 },
            { lng: 0.1933973, lat: 38.72414 },
            { lng: 0.1933398, lat: 38.7240319 },
            { lng: 0.1933942, lat: 38.7239815 },
            { lng: 0.1936501, lat: 38.7240364 },
            { lng: 0.1938503, lat: 38.7240783 },
            { lng: 0.1939965, lat: 38.7240747 },
            { lng: 0.194132, lat: 38.7240622 },
            { lng: 0.1942929, lat: 38.7239972 },
            { lng: 0.1944391, lat: 38.7239215 },
            { lng: 0.1950566, lat: 38.723858 },
            { lng: 0.19532, lat: 38.7240313 },
            { lng: 0.1953897, lat: 38.7243535 },
            { lng: 0.1955866, lat: 38.7245226 },
            { lng: 0.1958929, lat: 38.724744 },
            { lng: 0.1963081, lat: 38.7251197 },
            { lng: 0.1965318, lat: 38.7252064 },
            { lng: 0.196874, lat: 38.725128 },
            { lng: 0.197534, lat: 38.725015 },
            { lng: 0.197862, lat: 38.725023 },
            { lng: 0.198189, lat: 38.725031 },
            { lng: 0.1985542, lat: 38.7251135 },
            { lng: 0.1989999, lat: 38.7251356 },
            { lng: 0.199258, lat: 38.7253262 },
            { lng: 0.1991577, lat: 38.7255454 },
            { lng: 0.1990916, lat: 38.7256907 },
            { lng: 0.198466, lat: 38.7264002 },
            { lng: 0.1981962, lat: 38.7267875 },
            { lng: 0.1979427, lat: 38.7271728 },
            { lng: 0.1978655, lat: 38.727304 },
            { lng: 0.197562, lat: 38.7274292 },
            { lng: 0.1976542, lat: 38.7275402 },
            { lng: 0.1974852, lat: 38.7277071 },
            { lng: 0.1971244, lat: 38.7277913 },
            { lng: 0.196753, lat: 38.7282272 },
            { lng: 0.1967797, lat: 38.7283975 },
            { lng: 0.1966116, lat: 38.7284755 },
            { lng: 0.1965275, lat: 38.7283658 },
            { lng: 0.1963321, lat: 38.72843 },
            { lng: 0.1962002, lat: 38.72858 },
            { lng: 0.1961871, lat: 38.7287242 },
            { lng: 0.1962432, lat: 38.7288101 },
            { lng: 0.1963511, lat: 38.7288245 },
            { lng: 0.1963381, lat: 38.7291314 },
            { lng: 0.1964454, lat: 38.7293447 },
            { lng: 0.1968157, lat: 38.7295159 },
            { lng: 0.1971273, lat: 38.7296007 },
            { lng: 0.1975353, lat: 38.7295899 },
            { lng: 0.1976926, lat: 38.729491 },
            { lng: 0.1977128, lat: 38.7294378 },
            { lng: 0.1977043, lat: 38.7293838 },
            { lng: 0.1976204, lat: 38.7292546 },
            { lng: 0.1976216, lat: 38.7291856 },
            { lng: 0.197651, lat: 38.7291424 },
            { lng: 0.1979541, lat: 38.7289667 },
            { lng: 0.1981371, lat: 38.7289786 },
            { lng: 0.1983975, lat: 38.7290458 },
            { lng: 0.1985058, lat: 38.7289838 },
            { lng: 0.1986349, lat: 38.7290883 },
            { lng: 0.19889, lat: 38.7292235 },
            { lng: 0.1990413, lat: 38.729227 },
            { lng: 0.1991168, lat: 38.7290948 },
            { lng: 0.1993181, lat: 38.7291171 },
            { lng: 0.1994306, lat: 38.728995 },
            { lng: 0.1995516, lat: 38.7290556 },
            { lng: 0.1995721, lat: 38.7292092 },
            { lng: 0.2002101, lat: 38.7292581 },
            { lng: 0.2001349, lat: 38.7294907 },
            { lng: 0.2001196, lat: 38.7296346 },
            { lng: 0.200293, lat: 38.7296879 },
            { lng: 0.2002807, lat: 38.7297798 },
            { lng: 0.2001702, lat: 38.7298678 },
            { lng: 0.2004677, lat: 38.7300229 },
            { lng: 0.2006185, lat: 38.7300745 },
            { lng: 0.2005375, lat: 38.7302784 },
            { lng: 0.2010789, lat: 38.7302083 },
            { lng: 0.2012248, lat: 38.7306368 },
            { lng: 0.2010789, lat: 38.7308778 },
            { lng: 0.2010275, lat: 38.730991 },
            { lng: 0.201126, lat: 38.7311079 },
            { lng: 0.2013556, lat: 38.7312886 },
            { lng: 0.201594, lat: 38.7312867 },
            { lng: 0.201671, lat: 38.7313912 },
            { lng: 0.2018505, lat: 38.731142 },
            { lng: 0.2020099, lat: 38.7312015 },
            { lng: 0.2021947, lat: 38.7312058 },
            { lng: 0.2024694, lat: 38.7310786 },
            { lng: 0.2030273, lat: 38.7311857 },
            { lng: 0.2035458, lat: 38.7310896 },
            { lng: 0.2045189, lat: 38.7311726 },
            { lng: 0.2041163, lat: 38.7315682 },
            { lng: 0.2040747, lat: 38.7322391 },
            { lng: 0.2046738, lat: 38.7320786 },
            { lng: 0.2047272, lat: 38.7318519 },
            { lng: 0.2051299, lat: 38.7319056 },
            { lng: 0.205076, lat: 38.7320772 },
            { lng: 0.2053705, lat: 38.7319624 },
            { lng: 0.2055593, lat: 38.732036 },
            { lng: 0.2055078, lat: 38.7321567 },
            { lng: 0.2058103, lat: 38.7323507 },
            { lng: 0.2058386, lat: 38.7325129 },
            { lng: 0.2061707, lat: 38.7324738 },
            { lng: 0.2066563, lat: 38.7325572 },
            { lng: 0.2074905, lat: 38.732304 },
            { lng: 0.2082114, lat: 38.732237 },
            { lng: 0.2087608, lat: 38.7320495 },
            { lng: 0.2086493, lat: 38.7319442 },
            { lng: 0.2095076, lat: 38.7317683 },
            { lng: 0.2096305, lat: 38.7318702 },
            { lng: 0.2103731, lat: 38.7318124 },
            { lng: 0.2108207, lat: 38.7319289 },
            { lng: 0.2114574, lat: 38.7316863 },
            { lng: 0.211915, lat: 38.7318173 },
            { lng: 0.2123313, lat: 38.7317414 },
            { lng: 0.2126432, lat: 38.7318337 },
            { lng: 0.2128063, lat: 38.7320209 },
            { lng: 0.2127577, lat: 38.7321722 },
            { lng: 0.213004, lat: 38.7322387 },
            { lng: 0.2130937, lat: 38.7322605 },
            { lng: 0.2131622, lat: 38.7322608 },
            { lng: 0.2131975, lat: 38.7321714 },
            { lng: 0.2132907, lat: 38.7320447 },
            { lng: 0.2133737, lat: 38.7316762 },
            { lng: 0.2134277, lat: 38.7315 },
            { lng: 0.2134784, lat: 38.7311627 },
            { lng: 0.213619, lat: 38.7306701 },
            { lng: 0.2135502, lat: 38.7303214 },
            { lng: 0.2135534, lat: 38.7298174 },
            { lng: 0.2136763, lat: 38.7295187 },
            { lng: 0.213915, lat: 38.7293011 },
            { lng: 0.2140433, lat: 38.7293219 },
            { lng: 0.2141446, lat: 38.7290925 },
            { lng: 0.214208, lat: 38.7288669 },
            { lng: 0.2142725, lat: 38.7287475 },
            { lng: 0.2143206, lat: 38.7285772 },
            { lng: 0.2142917, lat: 38.7285228 },
            { lng: 0.2141475, lat: 38.7282778 },
            { lng: 0.2141734, lat: 38.7282128 },
            { lng: 0.214207, lat: 38.7281778 },
            { lng: 0.2143188, lat: 38.7281314 },
            { lng: 0.2142861, lat: 38.7281001 },
            { lng: 0.2142935, lat: 38.7280802 },
            { lng: 0.2143498, lat: 38.7280666 },
            { lng: 0.2143981, lat: 38.7280135 },
            { lng: 0.2144588, lat: 38.7279745 },
            { lng: 0.2145362, lat: 38.7279567 },
            { lng: 0.2146378, lat: 38.7279103 },
            { lng: 0.2147649, lat: 38.7278461 },
            { lng: 0.2148387, lat: 38.7278353 },
            { lng: 0.2150138, lat: 38.7279305 },
            { lng: 0.2150639, lat: 38.7279288 },
            { lng: 0.215078, lat: 38.7279063 },
            { lng: 0.2150753, lat: 38.7278519 },
            { lng: 0.215137, lat: 38.7278173 },
            { lng: 0.2151773, lat: 38.7278655 },
            { lng: 0.2151752, lat: 38.7279026 },
            { lng: 0.2151477, lat: 38.7279335 },
            { lng: 0.215184, lat: 38.7279492 },
            { lng: 0.2152323, lat: 38.7279481 },
            { lng: 0.2152785, lat: 38.727929 },
            { lng: 0.2153073, lat: 38.7279008 },
            { lng: 0.2153221, lat: 38.727872 },
            { lng: 0.2153721, lat: 38.7278387 },
            { lng: 0.2154636, lat: 38.7278267 },
            { lng: 0.2155119, lat: 38.7278506 },
            { lng: 0.2155896, lat: 38.7278308 },
            { lng: 0.2156104, lat: 38.7278458 },
            { lng: 0.2156285, lat: 38.7278464 },
            { lng: 0.2156513, lat: 38.7278584 },
            { lng: 0.2156735, lat: 38.7278558 },
            { lng: 0.2156835, lat: 38.7278307 },
            { lng: 0.2157282, lat: 38.7278348 },
            { lng: 0.2157499, lat: 38.7278647 },
            { lng: 0.2156953, lat: 38.7278883 },
            { lng: 0.2156747, lat: 38.727947 },
            { lng: 0.2155796, lat: 38.727985 },
            { lng: 0.2155467, lat: 38.7280064 },
            { lng: 0.2155266, lat: 38.7280106 },
            { lng: 0.2155031, lat: 38.7280023 },
            { lng: 0.2154783, lat: 38.7280289 },
            { lng: 0.2153814, lat: 38.7280661 },
            { lng: 0.2153127, lat: 38.7280687 },
            { lng: 0.215361, lat: 38.7281011 },
            { lng: 0.215365, lat: 38.7281388 },
            { lng: 0.2153905, lat: 38.7281576 },
            { lng: 0.2154663, lat: 38.7281104 },
            { lng: 0.215519, lat: 38.728143 },
            { lng: 0.2156431, lat: 38.7281032 },
            { lng: 0.2157392, lat: 38.7280185 },
            { lng: 0.2158451, lat: 38.7279563 },
            { lng: 0.2159149, lat: 38.7279745 },
            { lng: 0.2160235, lat: 38.7279724 },
            { lng: 0.2160989, lat: 38.7279629 },
            { lng: 0.216226, lat: 38.7280415 },
            { lng: 0.216374, lat: 38.7280905 },
            { lng: 0.2163188, lat: 38.7282495 },
            { lng: 0.2162379, lat: 38.7283384 },
            { lng: 0.2162354, lat: 38.7284474 },
            { lng: 0.2162763, lat: 38.7285526 },
            { lng: 0.2163197, lat: 38.7286872 },
            { lng: 0.2165344, lat: 38.7287561 },
            { lng: 0.2165706, lat: 38.7288587 },
            { lng: 0.2166634, lat: 38.7289269 },
            { lng: 0.2167222, lat: 38.7289224 },
            { lng: 0.2167678, lat: 38.728959 },
            { lng: 0.2168915, lat: 38.7289979 },
            { lng: 0.2169073, lat: 38.7290668 },
            { lng: 0.2169502, lat: 38.729162 },
            { lng: 0.2170319, lat: 38.7292293 },
            { lng: 0.2171992, lat: 38.7293475 },
            { lng: 0.217223, lat: 38.7294701 },
            { lng: 0.2174648, lat: 38.729833 },
            { lng: 0.2175007, lat: 38.7298221 },
            { lng: 0.2175765, lat: 38.729848 },
            { lng: 0.2176734, lat: 38.7298391 },
            { lng: 0.2177159, lat: 38.7299127 },
            { lng: 0.2176818, lat: 38.7299247 },
            { lng: 0.2176938, lat: 38.729954 },
            { lng: 0.2175946, lat: 38.7299592 },
            { lng: 0.2175887, lat: 38.7299954 },
            { lng: 0.2179452, lat: 38.7303159 },
            { lng: 0.2183947, lat: 38.7305025 },
            { lng: 0.2184364, lat: 38.7304707 },
            { lng: 0.2186353, lat: 38.7304875 },
            { lng: 0.2187305, lat: 38.7305158 },
            { lng: 0.2188606, lat: 38.7305545 },
            { lng: 0.2189947, lat: 38.730634 },
            { lng: 0.2190068, lat: 38.7306758 },
            { lng: 0.2191755, lat: 38.7307128 },
            { lng: 0.2192993, lat: 38.7308032 },
            { lng: 0.2193816, lat: 38.7308469 },
            { lng: 0.2194802, lat: 38.7308778 },
            { lng: 0.2196307, lat: 38.7309514 },
            { lng: 0.219684, lat: 38.7309426 },
            { lng: 0.219731, lat: 38.7309217 },
            { lng: 0.2197799, lat: 38.7309311 },
            { lng: 0.2198235, lat: 38.7309097 },
            { lng: 0.219855, lat: 38.7308898 },
            { lng: 0.2198785, lat: 38.7308647 },
            { lng: 0.2198986, lat: 38.7308621 },
            { lng: 0.2199187, lat: 38.7308349 },
            { lng: 0.2199474, lat: 38.7308125 },
            { lng: 0.2199455, lat: 38.7307998 },
            { lng: 0.2199214, lat: 38.7307857 },
            { lng: 0.2198986, lat: 38.7307941 },
            { lng: 0.2198758, lat: 38.7307773 },
            { lng: 0.2198883, lat: 38.73069 },
            { lng: 0.2199402, lat: 38.7306696 },
            { lng: 0.2199496, lat: 38.7306366 },
            { lng: 0.2200173, lat: 38.7305827 },
            { lng: 0.2200488, lat: 38.7305629 },
            { lng: 0.2200776, lat: 38.7305597 },
            { lng: 0.22013, lat: 38.7305148 },
            { lng: 0.2201709, lat: 38.7305069 },
            { lng: 0.2202118, lat: 38.7305445 },
            { lng: 0.2202131, lat: 38.7305644 },
            { lng: 0.2202346, lat: 38.730577 },
            { lng: 0.2202808, lat: 38.7305404 },
            { lng: 0.2203586, lat: 38.7305111 },
            { lng: 0.2204002, lat: 38.7305022 },
            { lng: 0.2204156, lat: 38.7304886 },
            { lng: 0.2204585, lat: 38.7304781 },
            { lng: 0.2205554, lat: 38.7304902 },
            { lng: 0.2206094, lat: 38.730464 },
            { lng: 0.2206811, lat: 38.7304645 },
            { lng: 0.2207261, lat: 38.7304849 },
            { lng: 0.2207556, lat: 38.7304823 },
            { lng: 0.2208516, lat: 38.7304531 },
            { lng: 0.2209051, lat: 38.7304488 },
            { lng: 0.2209165, lat: 38.7304593 },
            { lng: 0.2209494, lat: 38.7304624 },
            { lng: 0.2209876, lat: 38.7304462 },
            { lng: 0.2210647, lat: 38.7304514 },
            { lng: 0.2210862, lat: 38.7304614 },
            { lng: 0.2211646, lat: 38.7304467 },
            { lng: 0.2212138, lat: 38.7304964 },
            { lng: 0.2210964, lat: 38.7305819 },
            { lng: 0.221011, lat: 38.7308053 },
            { lng: 0.2211709, lat: 38.7309067 },
            { lng: 0.2213841, lat: 38.7309035 },
            { lng: 0.2214218, lat: 38.7308428 },
            { lng: 0.2215369, lat: 38.7308424 },
            { lng: 0.2217164, lat: 38.730661 },
            { lng: 0.2218192, lat: 38.7306723 },
            { lng: 0.2220309, lat: 38.7306143 },
            { lng: 0.2222401, lat: 38.7305663 },
            { lng: 0.2222059, lat: 38.7307107 },
            { lng: 0.2223326, lat: 38.7307173 },
            { lng: 0.2225088, lat: 38.7305716 },
            { lng: 0.222667, lat: 38.7305209 },
            { lng: 0.2226127, lat: 38.7306759 },
            { lng: 0.2226943, lat: 38.7307492 },
            { lng: 0.2227734, lat: 38.7308165 },
            { lng: 0.222707, lat: 38.7310654 },
            { lng: 0.2227808, lat: 38.7311106 },
            { lng: 0.2227897, lat: 38.7310689 },
            { lng: 0.222963, lat: 38.7310601 },
            { lng: 0.2230543, lat: 38.7310867 },
            { lng: 0.2230772, lat: 38.7311177 },
            { lng: 0.2229526, lat: 38.7311323 },
            { lng: 0.2229451, lat: 38.7311728 },
            { lng: 0.2229893, lat: 38.7311891 },
            { lng: 0.2231004, lat: 38.731213 },
            { lng: 0.2231246, lat: 38.7313067 },
            { lng: 0.223162, lat: 38.7313071 },
            { lng: 0.2233001, lat: 38.7312415 },
            { lng: 0.2234482, lat: 38.731118 },
            { lng: 0.2235497, lat: 38.7310534 },
            { lng: 0.2237377, lat: 38.7309889 },
            { lng: 0.2239219, lat: 38.7309619 },
            { lng: 0.2239571, lat: 38.7313038 },
            { lng: 0.2241947, lat: 38.7314347 },
            { lng: 0.2242607, lat: 38.7316417 },
            { lng: 0.2243055, lat: 38.7316538 },
            { lng: 0.2246297, lat: 38.7315469 },
            { lng: 0.2246437, lat: 38.7316676 },
            { lng: 0.2245341, lat: 38.7318068 },
            { lng: 0.224518, lat: 38.7318927 },
            { lng: 0.2246744, lat: 38.7319373 },
            { lng: 0.2248676, lat: 38.7319157 },
            { lng: 0.2249688, lat: 38.7319581 },
            { lng: 0.2250356, lat: 38.731908 },
            { lng: 0.2250347, lat: 38.7318488 },
            { lng: 0.2252155, lat: 38.7317024 },
            { lng: 0.225244, lat: 38.731855 },
            { lng: 0.2252098, lat: 38.731975 },
            { lng: 0.2252113, lat: 38.7320481 },
            { lng: 0.2251034, lat: 38.732103 },
            { lng: 0.2250725, lat: 38.7321825 },
            { lng: 0.2250236, lat: 38.7322339 },
            { lng: 0.2250511, lat: 38.7322698 },
            { lng: 0.2250998, lat: 38.7322682 },
            { lng: 0.2250974, lat: 38.7322209 },
            { lng: 0.2251362, lat: 38.7321911 },
            { lng: 0.2251984, lat: 38.7322123 },
            { lng: 0.2252022, lat: 38.7323124 },
            { lng: 0.2253282, lat: 38.7323354 },
            { lng: 0.2254281, lat: 38.7322607 },
            { lng: 0.2255027, lat: 38.7322142 },
            { lng: 0.2255715, lat: 38.7322776 },
            { lng: 0.2257084, lat: 38.7323074 },
            { lng: 0.2256726, lat: 38.732474 },
            { lng: 0.2257897, lat: 38.7325223 },
            { lng: 0.2258646, lat: 38.7324338 },
            { lng: 0.2259528, lat: 38.7324644 },
            { lng: 0.2260127, lat: 38.7325626 },
            { lng: 0.2262081, lat: 38.732627 },
            { lng: 0.2267314, lat: 38.7321837 },
            { lng: 0.2268197, lat: 38.7322265 },
            { lng: 0.2268625, lat: 38.7322702 },
            { lng: 0.2269277, lat: 38.7322534 },
            { lng: 0.2271244, lat: 38.7322751 },
            { lng: 0.227404, lat: 38.7322412 },
            { lng: 0.2275985, lat: 38.7322702 },
            { lng: 0.2276835, lat: 38.7323402 },
            { lng: 0.2277888, lat: 38.7323 },
            { lng: 0.2279474, lat: 38.7323606 },
            { lng: 0.2285774, lat: 38.731966 },
            { lng: 0.2287582, lat: 38.7319923 },
            { lng: 0.2288167, lat: 38.7322141 },
            { lng: 0.2288782, lat: 38.7322022 },
            { lng: 0.2291196, lat: 38.7319695 },
            { lng: 0.2293678, lat: 38.7319524 },
            { lng: 0.2295669, lat: 38.7319934 },
            { lng: 0.2305821, lat: 38.7317866 },
            { lng: 0.2309977, lat: 38.7316901 },
            { lng: 0.2313203, lat: 38.7317182 },
            { lng: 0.2316217, lat: 38.7318463 },
            { lng: 0.2311282, lat: 38.7324603 },
            { lng: 0.2313469, lat: 38.7326012 },
            { lng: 0.2309955, lat: 38.7328014 },
            { lng: 0.2307091, lat: 38.7328067 },
            { lng: 0.2308935, lat: 38.7329501 },
            { lng: 0.2310117, lat: 38.7329835 },
            { lng: 0.2309285, lat: 38.7331527 },
            { lng: 0.2307968, lat: 38.7332399 },
            { lng: 0.2306569, lat: 38.7333827 },
            { lng: 0.2305955, lat: 38.7335166 },
            { lng: 0.2305692, lat: 38.7336854 },
            { lng: 0.2305159, lat: 38.7338064 },
            { lng: 0.2305474, lat: 38.7339719 },
            { lng: 0.2312195, lat: 38.7335686 },
            { lng: 0.2313872, lat: 38.7336101 },
            { lng: 0.2315431, lat: 38.7337604 },
            { lng: 0.2312744, lat: 38.7339767 },
            { lng: 0.2314811, lat: 38.7340676 },
            { lng: 0.2318897, lat: 38.7342913 },
            { lng: 0.2318108, lat: 38.7344272 },
            { lng: 0.2316865, lat: 38.7344849 },
            { lng: 0.2316988, lat: 38.73453 },
            { lng: 0.2317845, lat: 38.7345365 },
            { lng: 0.2320325, lat: 38.7349416 },
            { lng: 0.231716, lat: 38.7354918 },
            { lng: 0.231362, lat: 38.7359337 },
            { lng: 0.2315559, lat: 38.7358439 },
            { lng: 0.2317084, lat: 38.7356819 },
            { lng: 0.2318108, lat: 38.7357281 },
            { lng: 0.2316393, lat: 38.7359717 },
            { lng: 0.2317652, lat: 38.7359276 },
            { lng: 0.2321328, lat: 38.7356067 },
            { lng: 0.2322547, lat: 38.7356253 },
            { lng: 0.2321764, lat: 38.7358366 },
            { lng: 0.2320255, lat: 38.7359635 },
            { lng: 0.2319366, lat: 38.7361171 },
            { lng: 0.2317106, lat: 38.7363785 },
            { lng: 0.2312708, lat: 38.7367216 },
            { lng: 0.2314588, lat: 38.7366307 },
            { lng: 0.231569, lat: 38.7366507 },
            { lng: 0.2315692, lat: 38.7367867 },
            { lng: 0.2317467, lat: 38.7367966 },
            { lng: 0.2319688, lat: 38.7366147 },
            { lng: 0.2322211, lat: 38.7364865 },
            { lng: 0.2323104, lat: 38.7365499 },
            { lng: 0.23225, lat: 38.7366313 },
            { lng: 0.2323707, lat: 38.7367381 },
            { lng: 0.232248, lat: 38.7369011 },
            { lng: 0.2323705, lat: 38.7369753 },
            { lng: 0.232354, lat: 38.7370697 },
            { lng: 0.2326013, lat: 38.7369002 },
            { lng: 0.2326882, lat: 38.7369983 },
            { lng: 0.2328004, lat: 38.7369721 },
            { lng: 0.2328655, lat: 38.7368329 },
            { lng: 0.2329838, lat: 38.7367168 },
            { lng: 0.2331045, lat: 38.7366482 },
            { lng: 0.2330995, lat: 38.7367921 },
            { lng: 0.2332619, lat: 38.7366598 },
            { lng: 0.2334191, lat: 38.7365978 },
            { lng: 0.2333617, lat: 38.7367988 },
            { lng: 0.2334798, lat: 38.7368976 },
            { lng: 0.2335819, lat: 38.7368641 },
            { lng: 0.2336334, lat: 38.7369433 },
            { lng: 0.2336228, lat: 38.737059 },
            { lng: 0.2338258, lat: 38.7369386 },
            { lng: 0.2340051, lat: 38.7368823 },
            { lng: 0.2341588, lat: 38.7369798 },
            { lng: 0.2339381, lat: 38.737185 },
            { lng: 0.2336202, lat: 38.7373748 },
            { lng: 0.2334636, lat: 38.7373641 },
            { lng: 0.2333898, lat: 38.7374859 },
            { lng: 0.2334086, lat: 38.7376882 },
            { lng: 0.2331323, lat: 38.7379977 },
            { lng: 0.2331889, lat: 38.7381098 },
            { lng: 0.2334303, lat: 38.7381326 },
            { lng: 0.2332257, lat: 38.7384405 },
            { lng: 0.2336665, lat: 38.7385237 },
            { lng: 0.2336688, lat: 38.7386447 },
            { lng: 0.2339091, lat: 38.7386921 },
            { lng: 0.2338831, lat: 38.7389718 },
            { lng: 0.2337385, lat: 38.7389328 },
            { lng: 0.2336953, lat: 38.7390684 },
            { lng: 0.2340652, lat: 38.7394609 },
            { lng: 0.2340172, lat: 38.7396746 },
            { lng: 0.2339445, lat: 38.73978 },
            { lng: 0.2336822, lat: 38.7399758 },
            { lng: 0.2335105, lat: 38.7398813 },
            { lng: 0.2334193, lat: 38.7401453 },
            { lng: 0.2333346, lat: 38.7402217 },
            { lng: 0.2332005, lat: 38.7401602 },
            { lng: 0.2331136, lat: 38.7403717 },
            { lng: 0.2329794, lat: 38.7402714 },
            { lng: 0.2327823, lat: 38.7402425 },
            { lng: 0.2324913, lat: 38.7398282 },
            { lng: 0.2318258, lat: 38.7399536 },
            { lng: 0.2314884, lat: 38.7402534 },
            { lng: 0.2314181, lat: 38.7403605 },
            { lng: 0.2315289, lat: 38.7404885 },
            { lng: 0.2315657, lat: 38.7406017 },
            { lng: 0.2315737, lat: 38.7408157 },
            { lng: 0.2316756, lat: 38.7407935 },
            { lng: 0.2317035, lat: 38.740949 },
            { lng: 0.2318537, lat: 38.741174 },
            { lng: 0.2316888, lat: 38.7417076 },
            { lng: 0.2319138, lat: 38.7420954 },
            { lng: 0.2322392, lat: 38.7421183 },
            { lng: 0.2322791, lat: 38.7423301 },
            { lng: 0.2323035, lat: 38.742529 },
            { lng: 0.2321748, lat: 38.742639 },
            { lng: 0.2321922, lat: 38.7432306 },
            { lng: 0.2323773, lat: 38.743895 },
            { lng: 0.2325581, lat: 38.7446578 },
            { lng: 0.2329955, lat: 38.7453097 },
            { lng: 0.2329687, lat: 38.7455532 },
            { lng: 0.2319345, lat: 38.7462347 },
            { lng: 0.2313988, lat: 38.7459178 },
            { lng: 0.2312129, lat: 38.7457975 },
            { lng: 0.2309681, lat: 38.7458368 },
            { lng: 0.230794, lat: 38.7457467 },
            { lng: 0.2305542, lat: 38.7457509 },
            { lng: 0.2302811, lat: 38.7456919 },
            { lng: 0.2300564, lat: 38.745505 },
            { lng: 0.229672, lat: 38.7452809 },
            { lng: 0.2292404, lat: 38.7452927 },
            { lng: 0.2290181, lat: 38.7454213 },
            { lng: 0.22888, lat: 38.7453861 },
            { lng: 0.2287161, lat: 38.7453991 },
            { lng: 0.228536, lat: 38.745526 },
            { lng: 0.2277245, lat: 38.7454086 },
            { lng: 0.2274541, lat: 38.7453283 },
            { lng: 0.2270156, lat: 38.7451985 },
            { lng: 0.2267996, lat: 38.7450229 },
            { lng: 0.2258504, lat: 38.7448132 },
            { lng: 0.2254304, lat: 38.7451875 },
            { lng: 0.2254878, lat: 38.7456211 },
            { lng: 0.2255283, lat: 38.7459134 },
            { lng: 0.2252641, lat: 38.7465796 },
            { lng: 0.2256104, lat: 38.7470642 },
            { lng: 0.2255717, lat: 38.7473487 },
            { lng: 0.2256545, lat: 38.7477254 },
            { lng: 0.2257345, lat: 38.7479865 },
            { lng: 0.2252796, lat: 38.7480159 },
            { lng: 0.2248449, lat: 38.7483251 },
            { lng: 0.2244927, lat: 38.7486149 },
            { lng: 0.2242566, lat: 38.7487513 },
            { lng: 0.2241166, lat: 38.7491849 },
            { lng: 0.22392, lat: 38.7495314 },
            { lng: 0.2239469, lat: 38.7512997 },
            { lng: 0.2239805, lat: 38.7515398 },
            { lng: 0.2240773, lat: 38.7517506 },
            { lng: 0.2241843, lat: 38.7520084 },
            { lng: 0.2242272, lat: 38.7521673 },
            { lng: 0.2242648, lat: 38.7522301 },
            { lng: 0.2241736, lat: 38.7522677 },
            { lng: 0.2241453, lat: 38.7522407 },
            { lng: 0.2240595, lat: 38.7523997 },
            { lng: 0.2239965, lat: 38.7525605 },
            { lng: 0.2239911, lat: 38.7527279 },
            { lng: 0.2240541, lat: 38.7528766 },
            { lng: 0.2241292, lat: 38.7530063 },
            { lng: 0.2243063, lat: 38.7532071 },
            { lng: 0.2245597, lat: 38.7533805 },
            { lng: 0.2248695, lat: 38.7535752 },
            { lng: 0.2251337, lat: 38.7537068 },
            { lng: 0.2256031, lat: 38.7536664 },
            { lng: 0.2258955, lat: 38.7540611 },
            { lng: 0.2260068, lat: 38.7543299 },
            { lng: 0.22576, lat: 38.7543167 },
            { lng: 0.2252772, lat: 38.7544598 },
            { lng: 0.2249929, lat: 38.7546188 },
            { lng: 0.2246335, lat: 38.754724 },
            { lng: 0.2241198, lat: 38.7549139 },
            { lng: 0.2236411, lat: 38.7551515 },
            { lng: 0.2231695, lat: 38.7554623 },
            { lng: 0.2229161, lat: 38.7556529 },
            { lng: 0.2225578, lat: 38.7558889 },
            { lng: 0.2222244, lat: 38.7564595 },
            { lng: 0.2219721, lat: 38.7571119 },
            { lng: 0.222213, lat: 38.7571983 },
            { lng: 0.2219569, lat: 38.7575578 },
            { lng: 0.2218037, lat: 38.7577729 },
            { lng: 0.2216692, lat: 38.7585616 },
            { lng: 0.2221287, lat: 38.7591579 },
            { lng: 0.2221478, lat: 38.7591829 },
            { lng: 0.2225978, lat: 38.7597714 },
            { lng: 0.2229493, lat: 38.7601382 },
            { lng: 0.2232238, lat: 38.7603467 },
            { lng: 0.2233566, lat: 38.7606579 },
            { lng: 0.2232968, lat: 38.760873 },
            { lng: 0.2231714, lat: 38.7610474 },
            { lng: 0.2226912, lat: 38.7614426 },
            { lng: 0.2226823, lat: 38.7618256 },
            { lng: 0.2226951, lat: 38.7621033 },
            { lng: 0.2230436, lat: 38.7625795 },
            { lng: 0.2233292, lat: 38.7627221 },
            { lng: 0.2234683, lat: 38.7626617 },
            { lng: 0.2235227, lat: 38.7626802 },
            { lng: 0.2235368, lat: 38.7628187 },
            { lng: 0.223418, lat: 38.7628522 },
            { lng: 0.2233576, lat: 38.7629852 },
            { lng: 0.2233314, lat: 38.7632715 },
            { lng: 0.2233663, lat: 38.7632849 },
            { lng: 0.2233488, lat: 38.7633623 },
            { lng: 0.2234074, lat: 38.7634473 },
            { lng: 0.2235253, lat: 38.7636128 },
            { lng: 0.2235244, lat: 38.7637515 },
            { lng: 0.2236493, lat: 38.7638283 },
            { lng: 0.2237625, lat: 38.763767 },
            { lng: 0.2240446, lat: 38.7637924 },
            { lng: 0.2242283, lat: 38.7637347 },
            { lng: 0.224469, lat: 38.7635717 },
            { lng: 0.2247643, lat: 38.7636107 },
            { lng: 0.2249222, lat: 38.7638354 },
            { lng: 0.2248587, lat: 38.7640353 },
            { lng: 0.2247291, lat: 38.7641408 },
            { lng: 0.2247892, lat: 38.7642237 },
            { lng: 0.2247267, lat: 38.7643551 },
            { lng: 0.2244023, lat: 38.7643768 },
            { lng: 0.2242687, lat: 38.7643456 },
            { lng: 0.224211, lat: 38.7644002 },
            { lng: 0.2241278, lat: 38.7643936 },
            { lng: 0.224103, lat: 38.7643045 },
            { lng: 0.2239746, lat: 38.764266 },
            { lng: 0.2239553, lat: 38.7642218 },
            { lng: 0.2240113, lat: 38.7641444 },
            { lng: 0.2238656, lat: 38.7640359 },
            { lng: 0.2236964, lat: 38.7639774 },
            { lng: 0.2234216, lat: 38.7640124 },
            { lng: 0.2230973, lat: 38.7641345 },
            { lng: 0.2230051, lat: 38.7642283 },
            { lng: 0.2227419, lat: 38.7643196 },
            { lng: 0.2227621, lat: 38.7641779 },
            { lng: 0.2225822, lat: 38.7642822 },
            { lng: 0.2225973, lat: 38.7641302 },
            { lng: 0.2224556, lat: 38.7641891 },
            { lng: 0.2224463, lat: 38.7640737 },
            { lng: 0.2223398, lat: 38.7640617 },
            { lng: 0.2224558, lat: 38.7638166 },
            { lng: 0.2222844, lat: 38.7638607 },
            { lng: 0.2222617, lat: 38.7636414 },
            { lng: 0.2221637, lat: 38.763657 },
            { lng: 0.2220794, lat: 38.76339 },
            { lng: 0.22142, lat: 38.7629202 },
            { lng: 0.2212087, lat: 38.7631027 },
            { lng: 0.2209029, lat: 38.7632944 },
            { lng: 0.2206646, lat: 38.7624705 },
            { lng: 0.2209183, lat: 38.7619631 },
            { lng: 0.2207689, lat: 38.7614031 },
            { lng: 0.2208462, lat: 38.7611694 },
            { lng: 0.2207592, lat: 38.7607782 },
            { lng: 0.2205057, lat: 38.7604188 },
            { lng: 0.2200503, lat: 38.760234 },
            { lng: 0.2196647, lat: 38.7601726 },
            { lng: 0.2188584, lat: 38.760403 },
            { lng: 0.2188309, lat: 38.7604131 },
            { lng: 0.2176213, lat: 38.7608908 },
            { lng: 0.2169529, lat: 38.7613351 },
            { lng: 0.2165971, lat: 38.7615073 },
            { lng: 0.2157815, lat: 38.7616567 },
            { lng: 0.2155202, lat: 38.7615664 },
            { lng: 0.214806, lat: 38.7616094 },
            { lng: 0.2146097, lat: 38.7614053 },
            { lng: 0.214329, lat: 38.7614701 },
            { lng: 0.2142828, lat: 38.761256 },
            { lng: 0.2139752, lat: 38.7614046 },
            { lng: 0.2137624, lat: 38.7613339 },
            { lng: 0.2138272, lat: 38.761118 },
            { lng: 0.2133323, lat: 38.7610002 },
            { lng: 0.2128467, lat: 38.7611113 },
            { lng: 0.2127508, lat: 38.7609771 },
            { lng: 0.2125025, lat: 38.7609689 },
            { lng: 0.2122888, lat: 38.7610846 },
            { lng: 0.212197, lat: 38.7610225 },
            { lng: 0.2120231, lat: 38.7610177 },
            { lng: 0.2120284, lat: 38.7608041 },
            { lng: 0.2118769, lat: 38.7608236 },
            { lng: 0.2118014, lat: 38.7606177 },
            { lng: 0.2112286, lat: 38.7605308 },
            { lng: 0.210846, lat: 38.7606944 },
            { lng: 0.2107863, lat: 38.7606251 },
            { lng: 0.2105431, lat: 38.7606073 },
            { lng: 0.2104997, lat: 38.7604974 },
            { lng: 0.2101438, lat: 38.7605054 },
            { lng: 0.2090711, lat: 38.7608192 },
            { lng: 0.208934, lat: 38.7612642 },
            { lng: 0.2093518, lat: 38.761165 },
            { lng: 0.2095148, lat: 38.761211 },
            { lng: 0.2093945, lat: 38.7613026 },
            { lng: 0.2092056, lat: 38.7613484 },
            { lng: 0.2090384, lat: 38.7615392 },
            { lng: 0.2086779, lat: 38.7616257 },
            { lng: 0.2085238, lat: 38.7615934 },
            { lng: 0.2080037, lat: 38.7616708 },
            { lng: 0.2078976, lat: 38.7618175 },
            { lng: 0.2072595, lat: 38.7620931 },
            { lng: 0.2068943, lat: 38.7619655 },
            { lng: 0.2068777, lat: 38.7619072 },
            { lng: 0.2069675, lat: 38.7618704 },
            { lng: 0.2069479, lat: 38.761749 },
            { lng: 0.2067085, lat: 38.7616457 },
            { lng: 0.2064701, lat: 38.761753 },
            { lng: 0.2064948, lat: 38.761958 },
            { lng: 0.2067026, lat: 38.7619581 },
            { lng: 0.2067593, lat: 38.7622497 },
            { lng: 0.2066615, lat: 38.7623876 },
            { lng: 0.2065004, lat: 38.7624107 },
            { lng: 0.2064579, lat: 38.7625102 },
            { lng: 0.2057457, lat: 38.762961 },
            { lng: 0.2057783, lat: 38.7630966 },
            { lng: 0.2057214, lat: 38.7631391 },
            { lng: 0.2055748, lat: 38.7631243 },
            { lng: 0.2051783, lat: 38.7636477 },
            { lng: 0.2049564, lat: 38.7637164 },
            { lng: 0.204989, lat: 38.763888 },
            { lng: 0.2047677, lat: 38.7641285 },
            { lng: 0.2049397, lat: 38.7642189 },
            { lng: 0.2048032, lat: 38.7644976 },
            { lng: 0.2043986, lat: 38.7646946 },
            { lng: 0.2041833, lat: 38.7646789 },
            { lng: 0.2038679, lat: 38.764886 },
            { lng: 0.2034914, lat: 38.7649546 },
            { lng: 0.2034095, lat: 38.7651456 },
            { lng: 0.2032039, lat: 38.7651892 },
            { lng: 0.2027959, lat: 38.765553 },
            { lng: 0.2028734, lat: 38.7657061 },
            { lng: 0.2025849, lat: 38.7659932 },
            { lng: 0.202675, lat: 38.7661437 },
            { lng: 0.2025135, lat: 38.7663609 },
            { lng: 0.2021513, lat: 38.766317 },
            { lng: 0.2021541, lat: 38.7665749 },
            { lng: 0.2016042, lat: 38.7670251 },
            { lng: 0.2013231, lat: 38.7671151 },
            { lng: 0.2008329, lat: 38.7674313 },
            { lng: 0.2006789, lat: 38.7677009 },
            { lng: 0.200481, lat: 38.7677327 },
            { lng: 0.2004539, lat: 38.7679272 },
            { lng: 0.2001813, lat: 38.7681907 },
            { lng: 0.1999107, lat: 38.7682541 },
            { lng: 0.1998863, lat: 38.7685835 },
            { lng: 0.1996727, lat: 38.7687158 },
            { lng: 0.1994064, lat: 38.7687402 },
            { lng: 0.1992321, lat: 38.7690932 },
            { lng: 0.1990462, lat: 38.7690265 },
            { lng: 0.1987635, lat: 38.7693046 },
            { lng: 0.1985711, lat: 38.7693392 },
            { lng: 0.1982812, lat: 38.7696971 },
            { lng: 0.1982734, lat: 38.7700011 },
            { lng: 0.1979836, lat: 38.7705366 },
            { lng: 0.197486, lat: 38.7707713 },
            { lng: 0.1971186, lat: 38.7706528 },
            { lng: 0.1964202, lat: 38.7710162 },
            { lng: 0.1964454, lat: 38.7712567 },
            { lng: 0.196235, lat: 38.7713871 },
            { lng: 0.1959988, lat: 38.7714062 },
            { lng: 0.1960026, lat: 38.7715895 },
            { lng: 0.1958336, lat: 38.7716844 },
            { lng: 0.1956645, lat: 38.7716343 },
            { lng: 0.1954965, lat: 38.7717007 },
            { lng: 0.195447, lat: 38.7718771 },
            { lng: 0.195279, lat: 38.771874 },
            { lng: 0.1947145, lat: 38.7723434 },
            { lng: 0.194361, lat: 38.77257 },
            { lng: 0.1938477, lat: 38.772872 },
            { lng: 0.1936853, lat: 38.772907 },
            { lng: 0.1936283, lat: 38.7727052 },
            { lng: 0.1934225, lat: 38.7725025 },
            { lng: 0.1931442, lat: 38.7721469 },
            { lng: 0.1925823, lat: 38.7716634 },
            { lng: 0.1924321, lat: 38.7716816 },
            { lng: 0.1922339, lat: 38.771764 },
            { lng: 0.1918869, lat: 38.7719968 },
            { lng: 0.1911016, lat: 38.7723898 },
            { lng: 0.1908427, lat: 38.772606 },
            { lng: 0.1905557, lat: 38.7729922 },
            { lng: 0.1904161, lat: 38.7732192 },
            { lng: 0.1902718, lat: 38.7735494 },
            { lng: 0.1901474, lat: 38.7738238 },
            { lng: 0.1901739, lat: 38.7740781 },
            { lng: 0.190344, lat: 38.7744549 },
            { lng: 0.1905121, lat: 38.774709 },
            { lng: 0.1907918, lat: 38.7748615 },
            { lng: 0.1909098, lat: 38.7746798 },
            { lng: 0.1909983, lat: 38.7747221 },
            { lng: 0.1908412, lat: 38.7749665 },
            { lng: 0.1905071, lat: 38.7749889 },
            { lng: 0.1901891, lat: 38.7750368 },
            { lng: 0.1890557, lat: 38.7750841 },
            { lng: 0.1891664, lat: 38.7752519 },
            { lng: 0.1893998, lat: 38.7752311 },
            { lng: 0.1899737, lat: 38.7752168 },
            { lng: 0.190587, lat: 38.77513 },
            { lng: 0.1907292, lat: 38.7751306 },
            { lng: 0.1908782, lat: 38.7751938 },
            { lng: 0.1909672, lat: 38.7751789 },
            { lng: 0.191289, lat: 38.7745486 },
            { lng: 0.1914923, lat: 38.7745265 },
            { lng: 0.1916536, lat: 38.7746428 },
            { lng: 0.1918156, lat: 38.7752376 },
            { lng: 0.1917135, lat: 38.7754711 },
            { lng: 0.1915445, lat: 38.7755834 },
            { lng: 0.191495, lat: 38.7758297 },
            { lng: 0.1913237, lat: 38.7758929 },
            { lng: 0.1912817, lat: 38.7760752 },
            { lng: 0.1911549, lat: 38.776162 },
            { lng: 0.1911483, lat: 38.7763367 },
            { lng: 0.1905824, lat: 38.7765379 },
            { lng: 0.1905904, lat: 38.7772057 },
            { lng: 0.1904149, lat: 38.7774647 },
            { lng: 0.1899695, lat: 38.7775537 },
            { lng: 0.1897933, lat: 38.777919 },
            { lng: 0.1894248, lat: 38.7781294 },
            { lng: 0.1893625, lat: 38.7786533 },
            { lng: 0.189567, lat: 38.7786793 },
            { lng: 0.1893158, lat: 38.7789547 },
            { lng: 0.1890546, lat: 38.7790045 },
            { lng: 0.1890733, lat: 38.7796405 },
            { lng: 0.1882739, lat: 38.7798641 },
            { lng: 0.1881049, lat: 38.7798017 },
            { lng: 0.187613, lat: 38.7799983 },
            { lng: 0.1874629, lat: 38.7804401 },
            { lng: 0.1869324, lat: 38.7813048 },
            { lng: 0.1865204, lat: 38.7817397 },
            { lng: 0.1862458, lat: 38.7822415 },
            { lng: 0.1860398, lat: 38.7826496 },
            { lng: 0.185705, lat: 38.7828503 },
            { lng: 0.1854476, lat: 38.7833789 },
            { lng: 0.1853188, lat: 38.783874 },
            { lng: 0.1851557, lat: 38.7843825 },
            { lng: 0.1846407, lat: 38.7847772 },
            { lng: 0.1843842, lat: 38.784831 },
            { lng: 0.1841429, lat: 38.7851519 },
            { lng: 0.1837996, lat: 38.7856938 },
            { lng: 0.183585, lat: 38.7861555 },
            { lng: 0.1833104, lat: 38.7868245 },
            { lng: 0.1833876, lat: 38.7873597 },
            { lng: 0.1835249, lat: 38.7875069 },
            { lng: 0.183216, lat: 38.7877076 },
            { lng: 0.1828875, lat: 38.7878918 },
            { lng: 0.182495, lat: 38.7892062 },
            { lng: 0.1822759, lat: 38.7899114 },
            { lng: 0.1822115, lat: 38.7902836 },
            { lng: 0.1822632, lat: 38.7906981 },
            { lng: 0.182392, lat: 38.7913537 },
            { lng: 0.1822285, lat: 38.7914768 },
            { lng: 0.1821259, lat: 38.7915544 },
            { lng: 0.1821214, lat: 38.7916363 },
            { lng: 0.1821002, lat: 38.7920294 },
            { lng: 0.1822203, lat: 38.7923238 },
            { lng: 0.1823061, lat: 38.7926248 },
            { lng: 0.1819907, lat: 38.792856 },
            { lng: 0.182033, lat: 38.7938431 },
            { lng: 0.182289, lat: 38.7944043 },
            { lng: 0.182701, lat: 38.7951 },
            { lng: 0.183141, lat: 38.7956176 },
            { lng: 0.183587, lat: 38.7960459 },
            { lng: 0.1836118, lat: 38.7960875 },
            { lng: 0.1840311, lat: 38.7962472 },
            { lng: 0.184908, lat: 38.7959691 },
            { lng: 0.1860756, lat: 38.7960199 },
            { lng: 0.1860999, lat: 38.7961173 },
            { lng: 0.1860526, lat: 38.7962591 },
            { lng: 0.1859582, lat: 38.796326 },
            { lng: 0.185016, lat: 38.7962874 },
            { lng: 0.1844649, lat: 38.7964606 },
            { lng: 0.1843287, lat: 38.7977056 },
            { lng: 0.1847677, lat: 38.7977323 },
            { lng: 0.1847128, lat: 38.7983338 },
            { lng: 0.1847562, lat: 38.7983713 },
            { lng: 0.1848125, lat: 38.7984069 },
            { lng: 0.1848796, lat: 38.7984455 },
            { lng: 0.1849325, lat: 38.7984714 },
            { lng: 0.1850351, lat: 38.7985051 },
            { lng: 0.1851411, lat: 38.798526 },
            { lng: 0.1851491, lat: 38.7984832 },
            { lng: 0.1852433, lat: 38.7984886 },
            { lng: 0.1855881, lat: 38.7985047 },
            { lng: 0.1855909, lat: 38.7984635 },
            { lng: 0.1857422, lat: 38.7984697 },
            { lng: 0.1857444, lat: 38.7984447 },
            { lng: 0.1858008, lat: 38.7984465 },
            { lng: 0.1858, lat: 38.7984752 },
            { lng: 0.1858232, lat: 38.7984765 },
            { lng: 0.1858145, lat: 38.7986061 },
            { lng: 0.1858849, lat: 38.7986087 },
            { lng: 0.185897, lat: 38.7984809 },
            { lng: 0.1861278, lat: 38.7984925 },
            { lng: 0.1861213, lat: 38.7985814 },
            { lng: 0.1865376, lat: 38.798604 },
            { lng: 0.1871358, lat: 38.7986391 },
            { lng: 0.1879317, lat: 38.7986876 },
            { lng: 0.1885507, lat: 38.7987223 },
            { lng: 0.1886568, lat: 38.7983808 },
            { lng: 0.1882444, lat: 38.7973073 },
            { lng: 0.1881264, lat: 38.7973334 },
            { lng: 0.1880915, lat: 38.7972333 },
            { lng: 0.1882001, lat: 38.7972049 },
            { lng: 0.1880888, lat: 38.7969258 },
            { lng: 0.1879722, lat: 38.796953 },
            { lng: 0.1879306, lat: 38.7968443 },
            { lng: 0.1880406, lat: 38.796815 },
            { lng: 0.1879319, lat: 38.7965317 },
            { lng: 0.1878059, lat: 38.7962893 },
            { lng: 0.1876999, lat: 38.7963227 },
            { lng: 0.1876436, lat: 38.7962203 },
            { lng: 0.1877509, lat: 38.7961816 },
            { lng: 0.1874873, lat: 38.7956851 },
            { lng: 0.1873157, lat: 38.795359 },
            { lng: 0.1869348, lat: 38.795474 },
            { lng: 0.1868383, lat: 38.7952921 },
            { lng: 0.1872191, lat: 38.795175 },
            { lng: 0.1871169, lat: 38.794968 },
            { lng: 0.1870287, lat: 38.7949514 },
            { lng: 0.1869452, lat: 38.7948944 },
            { lng: 0.1869023, lat: 38.7947539 },
            { lng: 0.1870153, lat: 38.794665 },
            { lng: 0.1872191, lat: 38.7946503 },
            { lng: 0.1873666, lat: 38.7947548 },
            { lng: 0.1889898, lat: 38.7982322 },
            { lng: 0.1892201, lat: 38.7989996 },
            { lng: 0.1892482, lat: 38.7991214 },
            { lng: 0.1893612, lat: 38.7992515 },
            { lng: 0.1894553, lat: 38.7993851 },
            { lng: 0.1897294, lat: 38.7995793 },
            { lng: 0.1906055, lat: 38.7999628 },
            { lng: 0.1911525, lat: 38.8000931 },
            { lng: 0.1913496, lat: 38.8000342 },
            { lng: 0.1918771, lat: 38.7998765 },
            { lng: 0.1923684, lat: 38.7998365 },
            { lng: 0.1924521, lat: 38.7998297 },
            { lng: 0.192634, lat: 38.799874 },
            { lng: 0.1927385, lat: 38.7998595 },
            { lng: 0.1929588, lat: 38.7998289 },
            { lng: 0.1935925, lat: 38.7997409 },
            { lng: 0.1943068, lat: 38.7999931 },
            { lng: 0.1947657, lat: 38.7998416 },
            { lng: 0.1953091, lat: 38.799883 },
            { lng: 0.1955371, lat: 38.8001975 },
            { lng: 0.1962747, lat: 38.7998496 },
            { lng: 0.1967682, lat: 38.7997994 },
            { lng: 0.1974334, lat: 38.8002593 },
            { lng: 0.1979913, lat: 38.8008028 },
            { lng: 0.1980594, lat: 38.8012035 },
            { lng: 0.1983666, lat: 38.801268 },
            { lng: 0.1985652, lat: 38.8015722 },
            { lng: 0.1988604, lat: 38.8017309 },
            { lng: 0.1987531, lat: 38.8020782 },
            { lng: 0.1985584, lat: 38.8023349 },
            { lng: 0.1983722, lat: 38.8026158 },
            { lng: 0.1979951, lat: 38.8029168 },
            { lng: 0.1976587, lat: 38.8031856 },
            { lng: 0.1974763, lat: 38.8034099 },
            { lng: 0.197353, lat: 38.8036545 },
            { lng: 0.1972669, lat: 38.8038572 },
            { lng: 0.1971596, lat: 38.8040094 },
            { lng: 0.1968128, lat: 38.804247 },
            { lng: 0.1969179, lat: 38.8043511 },
            { lng: 0.1968098, lat: 38.8044095 },
            { lng: 0.196673, lat: 38.8044615 },
            { lng: 0.1964877, lat: 38.8045138 },
            { lng: 0.1963034, lat: 38.8045528 },
            { lng: 0.1961157, lat: 38.8046164 },
            { lng: 0.1957353, lat: 38.8046743 },
            { lng: 0.1955242, lat: 38.8047936 },
            { lng: 0.195283, lat: 38.8049359 },
            { lng: 0.195079, lat: 38.8048356 },
            { lng: 0.195021, lat: 38.8046788 },
            { lng: 0.1947456, lat: 38.8046979 },
            { lng: 0.1944905, lat: 38.8045696 },
            { lng: 0.1940793, lat: 38.8045311 },
            { lng: 0.193729, lat: 38.8048292 },
            { lng: 0.1937902, lat: 38.8058806 },
            { lng: 0.1941053, lat: 38.8059901 },
            { lng: 0.1936354, lat: 38.8063628 },
            { lng: 0.1934316, lat: 38.8062542 },
            { lng: 0.1930024, lat: 38.8065802 },
            { lng: 0.1929917, lat: 38.8061956 },
            { lng: 0.192584, lat: 38.8062625 },
            { lng: 0.1921548, lat: 38.8061956 },
            { lng: 0.1918544, lat: 38.8062709 },
            { lng: 0.1917257, lat: 38.8062207 },
            { lng: 0.1912536, lat: 38.8062542 },
            { lng: 0.1905777, lat: 38.8065468 },
            { lng: 0.190406, lat: 38.8067558 },
            { lng: 0.190685, lat: 38.8066889 },
            { lng: 0.1909187, lat: 38.8068753 },
            { lng: 0.1909681, lat: 38.8069014 },
            { lng: 0.1911158, lat: 38.8070878 },
            { lng: 0.1909102, lat: 38.8072013 },
            { lng: 0.1906403, lat: 38.8074138 },
            { lng: 0.1907534, lat: 38.8074836 },
            { lng: 0.1910456, lat: 38.8073268 },
            { lng: 0.1911588, lat: 38.807489 },
            { lng: 0.1913674, lat: 38.807618 },
            { lng: 0.1914242, lat: 38.8076853 },
            { lng: 0.1914658, lat: 38.8077612 },
            { lng: 0.1912807, lat: 38.8078697 },
            { lng: 0.1910708, lat: 38.8079476 },
            { lng: 0.1908859, lat: 38.8080359 },
            { lng: 0.1907487, lat: 38.8081687 },
            { lng: 0.1905045, lat: 38.808306 },
            { lng: 0.1903347, lat: 38.8083351 },
            { lng: 0.1902219, lat: 38.8083919 },
            { lng: 0.1900242, lat: 38.8085026 },
            { lng: 0.1900463, lat: 38.8086322 },
            { lng: 0.1901134, lat: 38.8087824 },
            { lng: 0.1900656, lat: 38.808806 },
            { lng: 0.1899221, lat: 38.8088036 },
            { lng: 0.1897867, lat: 38.8087995 },
            { lng: 0.1895922, lat: 38.8088369 },
            { lng: 0.1895062, lat: 38.8089121 },
            { lng: 0.1893181, lat: 38.8090239 },
            { lng: 0.189273, lat: 38.8091025 },
            { lng: 0.1891667, lat: 38.8091518 },
            { lng: 0.1889818, lat: 38.8091967 },
            { lng: 0.1887769, lat: 38.8091941 },
            { lng: 0.188687, lat: 38.8091585 },
            { lng: 0.1884215, lat: 38.8091014 },
            { lng: 0.1883671, lat: 38.8090673 },
            { lng: 0.1882786, lat: 38.8090739 },
            { lng: 0.1881515, lat: 38.8091296 },
            { lng: 0.1880973, lat: 38.8090839 },
            { lng: 0.1880425, lat: 38.8091083 },
            { lng: 0.1879759, lat: 38.8090828 },
            { lng: 0.1878731, lat: 38.8090261 },
            { lng: 0.1878188, lat: 38.8090439 },
            { lng: 0.1877974, lat: 38.8091652 },
            { lng: 0.1877121, lat: 38.8091692 },
            { lng: 0.1876917, lat: 38.8092319 },
            { lng: 0.1875474, lat: 38.8092535 },
            { lng: 0.1874005, lat: 38.8093054 },
            { lng: 0.18724, lat: 38.8093854 },
            { lng: 0.1872619, lat: 38.8094367 },
            { lng: 0.1873419, lat: 38.8094823 },
            { lng: 0.1873483, lat: 38.8095633 },
            { lng: 0.1872662, lat: 38.8096202 },
            { lng: 0.1871591, lat: 38.8095858 },
            { lng: 0.1870406, lat: 38.8095123 },
            { lng: 0.1869406, lat: 38.809532 },
            { lng: 0.1868207, lat: 38.8095412 },
            { lng: 0.1867512, lat: 38.8095903 },
            { lng: 0.1866908, lat: 38.8096447 },
            { lng: 0.1865837, lat: 38.8096625 },
            { lng: 0.1864623, lat: 38.8096581 },
            { lng: 0.1863867, lat: 38.809687 },
            { lng: 0.1863167, lat: 38.8096737 },
            { lng: 0.1862125, lat: 38.8096714 },
            { lng: 0.1861782, lat: 38.8097627 },
            { lng: 0.186101, lat: 38.8098201 },
            { lng: 0.1860825, lat: 38.8099129 },
            { lng: 0.185892, lat: 38.8100161 },
            { lng: 0.1857047, lat: 38.8100595 },
            { lng: 0.1855566, lat: 38.8100916 },
            { lng: 0.1853973, lat: 38.8100984 },
            { lng: 0.1852601, lat: 38.8102133 },
            { lng: 0.1852068, lat: 38.8103402 },
            { lng: 0.1849809, lat: 38.8104149 },
            { lng: 0.1849459, lat: 38.8104803 },
            { lng: 0.1848252, lat: 38.8107139 },
            { lng: 0.1846713, lat: 38.8107782 },
            { lng: 0.1845574, lat: 38.8107604 },
            { lng: 0.1844128, lat: 38.8108481 },
            { lng: 0.1843732, lat: 38.8108402 },
            { lng: 0.1842007, lat: 38.810888 },
            { lng: 0.1841209, lat: 38.8109424 },
            { lng: 0.1841444, lat: 38.8109998 },
            { lng: 0.1841081, lat: 38.8111082 },
            { lng: 0.1839486, lat: 38.8111306 },
            { lng: 0.1838396, lat: 38.8111044 },
            { lng: 0.1837221, lat: 38.8110086 },
            { lng: 0.1837165, lat: 38.8108865 },
            { lng: 0.1836494, lat: 38.8108538 },
            { lng: 0.1834928, lat: 38.8108037 },
            { lng: 0.1833501, lat: 38.8108386 },
            { lng: 0.1833181, lat: 38.8109465 },
            { lng: 0.1833697, lat: 38.81105 },
            { lng: 0.1833725, lat: 38.8111676 },
            { lng: 0.1832442, lat: 38.8112368 },
            { lng: 0.1830928, lat: 38.8111676 },
            { lng: 0.1829378, lat: 38.8110799 },
            { lng: 0.1828048, lat: 38.8111066 },
            { lng: 0.1826739, lat: 38.8111485 },
            { lng: 0.1826011, lat: 38.811213 },
            { lng: 0.1825363, lat: 38.8113049 },
            { lng: 0.182444, lat: 38.8115948 },
            { lng: 0.1824356, lat: 38.8116907 },
            { lng: 0.1823014, lat: 38.8117168 },
            { lng: 0.1822399, lat: 38.8116144 },
            { lng: 0.182135, lat: 38.8115975 },
            { lng: 0.181963, lat: 38.8117277 },
            { lng: 0.1820226, lat: 38.81187 },
            { lng: 0.1821364, lat: 38.8118802 },
            { lng: 0.182128, lat: 38.8119805 },
            { lng: 0.1819909, lat: 38.8119957 },
            { lng: 0.181875, lat: 38.8119578 },
            { lng: 0.181784, lat: 38.8120655 },
            { lng: 0.1815071, lat: 38.8122071 },
            { lng: 0.1813519, lat: 38.8122113 },
            { lng: 0.1812247, lat: 38.812266 },
            { lng: 0.18111, lat: 38.8122355 },
            { lng: 0.1811827, lat: 38.8121657 },
            { lng: 0.1811659, lat: 38.8121134 },
            { lng: 0.1810277, lat: 38.8120828 },
            { lng: 0.1809756, lat: 38.8120625 },
            { lng: 0.1809345, lat: 38.812071 },
            { lng: 0.1809879, lat: 38.8121628 },
            { lng: 0.1809332, lat: 38.8122012 },
            { lng: 0.180851, lat: 38.812134 },
            { lng: 0.1808131, lat: 38.8120492 },
            { lng: 0.1806771, lat: 38.812086 },
            { lng: 0.1805511, lat: 38.8121255 },
            { lng: 0.1804991, lat: 38.8121937 },
            { lng: 0.1805223, lat: 38.8122898 },
            { lng: 0.1804237, lat: 38.812357 },
            { lng: 0.1802579, lat: 38.8123543 },
            { lng: 0.1801636, lat: 38.8123762 },
            { lng: 0.1800486, lat: 38.8123237 },
            { lng: 0.1799913, lat: 38.8121531 },
            { lng: 0.1799157, lat: 38.8120614 },
            { lng: 0.1798055, lat: 38.8120852 },
            { lng: 0.1797406, lat: 38.8121902 },
            { lng: 0.1796637, lat: 38.812229 },
            { lng: 0.1796254, lat: 38.812277 },
            { lng: 0.1797383, lat: 38.8123323 },
            { lng: 0.1796295, lat: 38.812437 },
            { lng: 0.1795925, lat: 38.8124989 },
            { lng: 0.1795542, lat: 38.8126152 },
            { lng: 0.1794416, lat: 38.812585 },
            { lng: 0.1793912, lat: 38.812579 },
            { lng: 0.1793524, lat: 38.8126189 },
            { lng: 0.1794104, lat: 38.8126579 },
            { lng: 0.1794583, lat: 38.8126857 },
            { lng: 0.1794583, lat: 38.8127518 },
            { lng: 0.1793529, lat: 38.8127593 },
            { lng: 0.1793392, lat: 38.8127934 },
            { lng: 0.179268, lat: 38.8128222 },
            { lng: 0.1792241, lat: 38.8127838 },
            { lng: 0.1792091, lat: 38.8127241 },
            { lng: 0.1791095, lat: 38.8126759 },
            { lng: 0.1790543, lat: 38.8125075 },
            { lng: 0.1790029, lat: 38.8125158 },
            { lng: 0.1790146, lat: 38.8126163 },
            { lng: 0.1789475, lat: 38.8126803 },
            { lng: 0.1787352, lat: 38.8127369 },
            { lng: 0.1787161, lat: 38.8127977 },
            { lng: 0.1786063, lat: 38.8128496 },
            { lng: 0.1785134, lat: 38.8127646 },
            { lng: 0.1784271, lat: 38.8127315 },
            { lng: 0.1784121, lat: 38.8127518 },
            { lng: 0.1782573, lat: 38.8127956 },
            { lng: 0.1782012, lat: 38.8128436 },
            { lng: 0.1781491, lat: 38.8128468 },
            { lng: 0.17813, lat: 38.8127966 },
            { lng: 0.1780526, lat: 38.812803 },
            { lng: 0.1778961, lat: 38.8127748 },
            { lng: 0.1777835, lat: 38.8128318 },
            { lng: 0.1777123, lat: 38.8129247 },
            { lng: 0.1776151, lat: 38.8129439 },
            { lng: 0.1776137, lat: 38.8130122 },
            { lng: 0.1775589, lat: 38.8130463 },
            { lng: 0.1775165, lat: 38.8130292 },
            { lng: 0.1775055, lat: 38.8130036 },
            { lng: 0.1774563, lat: 38.8129913 },
            { lng: 0.1773069, lat: 38.8130399 },
            { lng: 0.1773289, lat: 38.8130869 },
            { lng: 0.1773165, lat: 38.8131402 },
            { lng: 0.1773302, lat: 38.8131808 },
            { lng: 0.1772494, lat: 38.8132042 },
            { lng: 0.1771796, lat: 38.8131861 },
            { lng: 0.177207, lat: 38.8131456 },
            { lng: 0.1771548, lat: 38.8131154 },
            { lng: 0.1770577, lat: 38.8131584 },
            { lng: 0.1770577, lat: 38.8131989 },
            { lng: 0.1769906, lat: 38.8132256 },
            { lng: 0.1768427, lat: 38.8131968 },
            { lng: 0.1768372, lat: 38.8131477 },
            { lng: 0.1767814, lat: 38.8131569 },
            { lng: 0.1766661, lat: 38.8131978 },
            { lng: 0.1766291, lat: 38.8132555 },
            { lng: 0.1766811, lat: 38.8133302 },
            { lng: 0.1766729, lat: 38.813376 },
            { lng: 0.1765798, lat: 38.8134422 },
            { lng: 0.1765031, lat: 38.8133718 },
            { lng: 0.1764552, lat: 38.8133494 },
            { lng: 0.1763689, lat: 38.8133664 },
            { lng: 0.1764059, lat: 38.8134667 },
            { lng: 0.176362, lat: 38.8134945 },
            { lng: 0.1762803, lat: 38.8134701 },
            { lng: 0.1761539, lat: 38.8134209 },
            { lng: 0.1760882, lat: 38.813296 },
            { lng: 0.176084, lat: 38.8132245 },
            { lng: 0.1761621, lat: 38.8132256 },
            { lng: 0.1761826, lat: 38.8132074 },
            { lng: 0.1761566, lat: 38.8131776 },
            { lng: 0.1760372, lat: 38.8131562 },
            { lng: 0.1759827, lat: 38.8132021 },
            { lng: 0.1759882, lat: 38.8132373 },
            { lng: 0.1759142, lat: 38.8132907 },
            { lng: 0.1758376, lat: 38.8132576 },
            { lng: 0.1758042, lat: 38.813188 },
            { lng: 0.1756924, lat: 38.8131722 },
            { lng: 0.175489, lat: 38.8132559 },
            { lng: 0.1754048, lat: 38.8132629 },
            { lng: 0.1752953, lat: 38.8132992 },
            { lng: 0.1752145, lat: 38.81336 },
            { lng: 0.1751446, lat: 38.8134091 },
            { lng: 0.17505, lat: 38.8134553 },
            { lng: 0.1750077, lat: 38.8134305 },
            { lng: 0.1749721, lat: 38.8134603 },
            { lng: 0.1748899, lat: 38.8134667 },
            { lng: 0.1748214, lat: 38.8134102 },
            { lng: 0.1747667, lat: 38.8133878 },
            { lng: 0.1747105, lat: 38.813327 },
            { lng: 0.1745849, lat: 38.8133333 },
            { lng: 0.1746174, lat: 38.8134283 },
            { lng: 0.174553, lat: 38.8134315 },
            { lng: 0.1744886, lat: 38.8133677 },
            { lng: 0.1744189, lat: 38.8133942 },
            { lng: 0.1743545, lat: 38.81336 },
            { lng: 0.1743051, lat: 38.8133738 },
            { lng: 0.1741737, lat: 38.813359 },
            { lng: 0.1740402, lat: 38.8133692 },
            { lng: 0.1739217, lat: 38.8134742 },
            { lng: 0.1738341, lat: 38.8135361 },
            { lng: 0.1737903, lat: 38.8136396 },
            { lng: 0.173786, lat: 38.8137004 },
            { lng: 0.1737823, lat: 38.813733 },
            { lng: 0.1736716, lat: 38.8137873 },
            { lng: 0.1736488, lat: 38.8138531 },
            { lng: 0.1736555, lat: 38.8139357 },
            { lng: 0.1735978, lat: 38.813967 },
            { lng: 0.1735455, lat: 38.8139472 },
            { lng: 0.1734545, lat: 38.8139215 },
            { lng: 0.173402, lat: 38.8140015 },
            { lng: 0.1733714, lat: 38.8140814 },
            { lng: 0.1734275, lat: 38.8141687 },
            { lng: 0.1734235, lat: 38.8142053 },
            { lng: 0.1733551, lat: 38.8142178 },
            { lng: 0.1732894, lat: 38.814199 },
            { lng: 0.1732599, lat: 38.8142115 },
            { lng: 0.1732372, lat: 38.8142695 },
            { lng: 0.1731701, lat: 38.8143394 },
            { lng: 0.1730976, lat: 38.8143139 },
            { lng: 0.1730507, lat: 38.8143171 },
            { lng: 0.1730091, lat: 38.8143432 },
            { lng: 0.1730466, lat: 38.8143683 },
            { lng: 0.1731003, lat: 38.8143526 },
            { lng: 0.1731311, lat: 38.8143819 },
            { lng: 0.1731553, lat: 38.8144665 },
            { lng: 0.1731579, lat: 38.8145208 },
            { lng: 0.1730976, lat: 38.8145271 },
            { lng: 0.1730614, lat: 38.8145093 },
            { lng: 0.1730171, lat: 38.8145261 },
            { lng: 0.1728897, lat: 38.8145647 },
            { lng: 0.1728428, lat: 38.8145511 },
            { lng: 0.1728282, lat: 38.8145861 },
            { lng: 0.1727797, lat: 38.8145783 },
            { lng: 0.1727073, lat: 38.8145302 },
            { lng: 0.1726807, lat: 38.8145464 },
            { lng: 0.1727317, lat: 38.8146541 },
            { lng: 0.1726778, lat: 38.8146901 },
            { lng: 0.1726778, lat: 38.8147403 },
            { lng: 0.172647, lat: 38.8147685 },
            { lng: 0.1725801, lat: 38.8147596 },
            { lng: 0.1725209, lat: 38.8147946 },
            { lng: 0.1724431, lat: 38.8148395 },
            { lng: 0.1723586, lat: 38.8148949 },
            { lng: 0.1723278, lat: 38.8149231 },
            { lng: 0.172301, lat: 38.8149827 },
            { lng: 0.1722648, lat: 38.8150862 },
            { lng: 0.172167, lat: 38.8151619 },
            { lng: 0.1720877, lat: 38.8152021 },
            { lng: 0.1719872, lat: 38.8151854 },
            { lng: 0.1719429, lat: 38.8151969 },
            { lng: 0.1719094, lat: 38.8151938 },
            { lng: 0.1718237, lat: 38.8152099 },
            { lng: 0.1717605, lat: 38.815268 },
            { lng: 0.1717017, lat: 38.8152539 },
            { lng: 0.1716063, lat: 38.8152408 },
            { lng: 0.1715608, lat: 38.8152695 },
            { lng: 0.1714802, lat: 38.815316 },
            { lng: 0.1714762, lat: 38.8153516 },
            { lng: 0.1714547, lat: 38.815385 },
            { lng: 0.1713704, lat: 38.8153751 },
            { lng: 0.1713195, lat: 38.8153886 },
            { lng: 0.1712214, lat: 38.815361 },
            { lng: 0.1711624, lat: 38.8153129 },
            { lng: 0.1711852, lat: 38.815222 },
            { lng: 0.17122, lat: 38.8151875 },
            { lng: 0.171216, lat: 38.8151363 },
            { lng: 0.1711932, lat: 38.8151165 },
            { lng: 0.171157, lat: 38.8151321 },
            { lng: 0.1710687, lat: 38.815141 },
            { lng: 0.1710149, lat: 38.8151039 },
            { lng: 0.1709466, lat: 38.8150406 },
            { lng: 0.1708369, lat: 38.8150198 },
            { lng: 0.170807, lat: 38.8150141 },
            { lng: 0.1707547, lat: 38.8150446 },
            { lng: 0.1706756, lat: 38.8151206 },
            { lng: 0.1706313, lat: 38.8152272 },
            { lng: 0.1705362, lat: 38.8152789 },
            { lng: 0.1703363, lat: 38.8152763 },
            { lng: 0.1702278, lat: 38.8152831 },
            { lng: 0.1700586, lat: 38.8154373 },
            { lng: 0.1700131, lat: 38.8155209 },
            { lng: 0.1699475, lat: 38.8155819 },
            { lng: 0.1699527, lat: 38.8156233 },
            { lng: 0.1700117, lat: 38.8156619 },
            { lng: 0.1700494, lat: 38.8157513 },
            { lng: 0.1700707, lat: 38.815803 },
            { lng: 0.1700479, lat: 38.8158469 },
            { lng: 0.1699795, lat: 38.8158855 },
            { lng: 0.1698724, lat: 38.8158912 },
            { lng: 0.1698199, lat: 38.8158678 },
            { lng: 0.1697515, lat: 38.8159012 },
            { lng: 0.1696805, lat: 38.8159012 },
            { lng: 0.169643, lat: 38.8158526 },
            { lng: 0.1695423, lat: 38.8158239 },
            { lng: 0.1694539, lat: 38.8158002 },
            { lng: 0.1693693, lat: 38.8158667 },
            { lng: 0.169281, lat: 38.8158934 },
            { lng: 0.1693103, lat: 38.8159409 },
            { lng: 0.1694109, lat: 38.8159503 },
            { lng: 0.1694163, lat: 38.815989 },
            { lng: 0.1694901, lat: 38.8160478 },
            { lng: 0.1694632, lat: 38.8160841 },
            { lng: 0.1694967, lat: 38.8161896 },
            { lng: 0.1695195, lat: 38.8162189 },
            { lng: 0.1695624, lat: 38.816246 },
            { lng: 0.1695034, lat: 38.8163359 },
            { lng: 0.1695438, lat: 38.8164461 },
            { lng: 0.1695262, lat: 38.8164926 },
            { lng: 0.169478, lat: 38.816548 },
            { lng: 0.1694055, lat: 38.8165919 },
            { lng: 0.1693479, lat: 38.8166651 },
            { lng: 0.169262, lat: 38.816686 },
            { lng: 0.1692111, lat: 38.8167675 },
            { lng: 0.1690984, lat: 38.8168249 },
            { lng: 0.1690007, lat: 38.816909 },
            { lng: 0.1688973, lat: 38.8169242 },
            { lng: 0.1687312, lat: 38.8169508 },
            { lng: 0.1686734, lat: 38.817025 },
            { lng: 0.1686947, lat: 38.8171039 },
            { lng: 0.1687739, lat: 38.8171039 },
            { lng: 0.1688371, lat: 38.8171243 },
            { lng: 0.1687859, lat: 38.8171938 },
            { lng: 0.1686976, lat: 38.817227 },
            { lng: 0.1685928, lat: 38.8172147 },
            { lng: 0.1685204, lat: 38.8172335 },
            { lng: 0.1685325, lat: 38.8172805 },
            { lng: 0.16855, lat: 38.8173299 },
            { lng: 0.1685083, lat: 38.8173934 },
            { lng: 0.1684534, lat: 38.8174605 },
            { lng: 0.1683622, lat: 38.8175198 },
            { lng: 0.1683058, lat: 38.8175856 },
            { lng: 0.1682468, lat: 38.8175992 },
            { lng: 0.1681826, lat: 38.8176196 },
            { lng: 0.1681556, lat: 38.8176577 },
            { lng: 0.1681382, lat: 38.8176995 },
            { lng: 0.1680484, lat: 38.8177572 },
            { lng: 0.1679223, lat: 38.8178009 },
            { lng: 0.1678002, lat: 38.8178678 },
            { lng: 0.1675964, lat: 38.8179921 },
            { lng: 0.1674784, lat: 38.818106 },
            { lng: 0.1674059, lat: 38.8181739 },
            { lng: 0.1673469, lat: 38.818176 },
            { lng: 0.1673107, lat: 38.8181165 },
            { lng: 0.1673349, lat: 38.8180329 },
            { lng: 0.1673805, lat: 38.8180141 },
            { lng: 0.1674167, lat: 38.8180203 },
            { lng: 0.1674891, lat: 38.8179639 },
            { lng: 0.1675629, lat: 38.8178939 },
            { lng: 0.1676728, lat: 38.8178103 },
            { lng: 0.167862, lat: 38.8177052 },
            { lng: 0.1678606, lat: 38.8174856 },
            { lng: 0.1679679, lat: 38.8174404 },
            { lng: 0.1680134, lat: 38.8173843 },
            { lng: 0.1678957, lat: 38.8174116 },
            { lng: 0.1677774, lat: 38.8174467 },
            { lng: 0.167654, lat: 38.8174984 },
            { lng: 0.1676118, lat: 38.817512 },
            { lng: 0.1675482, lat: 38.8175091 },
            { lng: 0.1674891, lat: 38.8175067 },
            { lng: 0.1673979, lat: 38.8174945 },
            { lng: 0.1673235, lat: 38.8175209 },
            { lng: 0.1672499, lat: 38.8175574 },
            { lng: 0.1672182, lat: 38.8175731 },
            { lng: 0.167194, lat: 38.817595 },
            { lng: 0.1672028, lat: 38.8176222 },
            { lng: 0.167245, lat: 38.8175997 },
            { lng: 0.1672665, lat: 38.8176044 },
            { lng: 0.1672745, lat: 38.8176556 },
            { lng: 0.167243, lat: 38.8176624 },
            { lng: 0.167213, lat: 38.8176794 },
            { lng: 0.1671578, lat: 38.817734 },
            { lng: 0.1671216, lat: 38.8177513 },
            { lng: 0.1670747, lat: 38.8177434 },
            { lng: 0.1670418, lat: 38.8177324 },
            { lng: 0.1670278, lat: 38.8177048 },
            { lng: 0.1669976, lat: 38.8176995 },
            { lng: 0.1669801, lat: 38.8177139 },
            { lng: 0.1669573, lat: 38.8177053 },
            { lng: 0.166956, lat: 38.8176698 },
            { lng: 0.1669278, lat: 38.8176457 },
            { lng: 0.1668896, lat: 38.8176489 },
            { lng: 0.1668521, lat: 38.81764 },
            { lng: 0.1668527, lat: 38.8176175 },
            { lng: 0.1668628, lat: 38.8176034 },
            { lng: 0.1667924, lat: 38.8175721 },
            { lng: 0.1667644, lat: 38.8175375 },
            { lng: 0.1666953, lat: 38.817423 },
            { lng: 0.1659355, lat: 38.8152984 },
            { lng: 0.162487, lat: 38.8130857 },
            { lng: 0.1605719, lat: 38.8109156 },
            { lng: 0.1596144, lat: 38.8098303 },
            { lng: 0.1554988, lat: 38.8055705 },
            { lng: 0.1532389, lat: 38.8053605 },
            { lng: 0.1489493, lat: 38.8048973 },
            { lng: 0.1480044, lat: 38.8048157 },
            { lng: 0.1446062, lat: 38.8040993 },
            { lng: 0.1418242, lat: 38.803507 },
            { lng: 0.134412, lat: 38.8033919 },
            { lng: 0.1298851, lat: 38.8033344 },
            { lng: 0.1294122, lat: 38.8033566 },
            { lng: 0.1291857, lat: 38.8035064 },
            { lng: 0.1288536, lat: 38.8037187 },
            { lng: 0.1287814, lat: 38.8040002 },
            { lng: 0.1285508, lat: 38.8044288 },
            { lng: 0.1280829, lat: 38.8048522 },
            { lng: 0.1280127, lat: 38.8051166 },
            { lng: 0.1277847, lat: 38.805349 },
            { lng: 0.1274406, lat: 38.8054676 },
            { lng: 0.1268749, lat: 38.8054315 },
            { lng: 0.1262498, lat: 38.8055951 },
            { lng: 0.125963, lat: 38.805773 },
            { lng: 0.1257167, lat: 38.8061746 },
            { lng: 0.1243716, lat: 38.8067306 },
            { lng: 0.1238363, lat: 38.8066981 },
            { lng: 0.1236819, lat: 38.8066986 },
            { lng: 0.1235003, lat: 38.8067034 },
            { lng: 0.1233674, lat: 38.8068782 },
            { lng: 0.1230881, lat: 38.8070748 },
            { lng: 0.1228059, lat: 38.8074298 },
            { lng: 0.122514, lat: 38.8075073 },
            { lng: 0.1219434, lat: 38.807494 },
            { lng: 0.1213365, lat: 38.8074522 },
            { lng: 0.1203577, lat: 38.8076162 },
            { lng: 0.1199567, lat: 38.8077389 },
            { lng: 0.1198123, lat: 38.8078762 },
            { lng: 0.1155812, lat: 38.8082201 },
            { lng: 0.1128439, lat: 38.8083605 },
            { lng: 0.1124245, lat: 38.8078565 },
            { lng: 0.1122555, lat: 38.8077141 },
            { lng: 0.1120606, lat: 38.8076701 },
            { lng: 0.1104467, lat: 38.8077592 },
            { lng: 0.1099413, lat: 38.8076331 },
            { lng: 0.1096136, lat: 38.807612 },
            { lng: 0.1085056, lat: 38.8078231 },
            { lng: 0.1066573, lat: 38.8079442 },
            { lng: 0.1055522, lat: 38.807852 },
            { lng: 0.1059908, lat: 38.8059872 },
            { lng: 0.1059096, lat: 38.8057314 },
            { lng: 0.1059601, lat: 38.8049682 },
            { lng: 0.1058222, lat: 38.8042473 },
            { lng: 0.1056702, lat: 38.803927 },
            { lng: 0.1052885, lat: 38.8036219 },
            { lng: 0.1048548, lat: 38.8034442 },
            { lng: 0.104513, lat: 38.8031897 },
            { lng: 0.1040541, lat: 38.8025016 },
            { lng: 0.1032629, lat: 38.8018182 },
            { lng: 0.102879, lat: 38.8013643 },
            { lng: 0.1028231, lat: 38.8011156 },
            { lng: 0.1029111, lat: 38.800568 },
            { lng: 0.1030692, lat: 38.8003089 },
            { lng: 0.1031561, lat: 38.8001111 },
            { lng: 0.1031884, lat: 38.7998632 },
            { lng: 0.103177, lat: 38.7996708 },
            { lng: 0.1032141, lat: 38.7995657 },
            { lng: 0.1032854, lat: 38.7994376 },
            { lng: 0.1033497, lat: 38.7993901 },
            { lng: 0.1034028, lat: 38.7993091 },
            { lng: 0.1034148, lat: 38.7991828 },
            { lng: 0.1034691, lat: 38.7990614 },
            { lng: 0.1034569, lat: 38.7989259 },
            { lng: 0.1033025, lat: 38.7984116 },
            { lng: 0.103278, lat: 38.7982673 },
            { lng: 0.1032775, lat: 38.7981981 },
            { lng: 0.1033047, lat: 38.7980603 },
            { lng: 0.1033715, lat: 38.7979205 },
            { lng: 0.1034203, lat: 38.7977884 },
            { lng: 0.1034066, lat: 38.7975745 },
            { lng: 0.1033093, lat: 38.7971127 },
            { lng: 0.1032608, lat: 38.7968117 },
            { lng: 0.103207, lat: 38.7965631 },
            { lng: 0.1031874, lat: 38.7964022 },
            { lng: 0.1031851, lat: 38.7962263 },
            { lng: 0.1032453, lat: 38.7960242 },
            { lng: 0.1032866, lat: 38.7957824 },
            { lng: 0.1032951, lat: 38.7955705 },
            { lng: 0.103293, lat: 38.7953142 },
            { lng: 0.1030634, lat: 38.7948474 },
            { lng: 0.1029261, lat: 38.7945231 },
            { lng: 0.1027347, lat: 38.7941021 },
            { lng: 0.102583, lat: 38.7938008 },
            { lng: 0.1024402, lat: 38.7935065 },
            { lng: 0.1023807, lat: 38.7933622 },
            { lng: 0.1025804, lat: 38.7932997 },
            { lng: 0.1029043, lat: 38.7932279 },
            { lng: 0.1031099, lat: 38.7931956 },
            { lng: 0.1032719, lat: 38.7931788 },
            { lng: 0.1032751, lat: 38.7930756 },
            { lng: 0.1033201, lat: 38.7918213 },
            { lng: 0.1033576, lat: 38.7907466 },
            { lng: 0.1033762, lat: 38.7901791 },
            { lng: 0.1033998, lat: 38.7893754 },
            { lng: 0.1034199, lat: 38.7887482 },
            { lng: 0.1037887, lat: 38.7788213 },
            { lng: 0.1039683, lat: 38.7751334 },
            { lng: 0.1039865, lat: 38.7733339 },
            { lng: 0.1039931, lat: 38.7725604 },
            { lng: 0.1042482, lat: 38.7698311 },
            { lng: 0.1043333, lat: 38.7687104 },
            { lng: 0.1043514, lat: 38.7685705 },
            { lng: 0.1043749, lat: 38.7683463 },
            { lng: 0.1047635, lat: 38.7680413 },
            { lng: 0.1048054, lat: 38.7678527 },
            { lng: 0.1049091, lat: 38.7673566 },
            { lng: 0.1049653, lat: 38.7670069 },
            { lng: 0.1052077, lat: 38.7664023 },
            { lng: 0.1054605, lat: 38.7657804 },
            { lng: 0.1060964, lat: 38.7654915 },
            { lng: 0.1068351, lat: 38.7654676 },
            { lng: 0.1078399, lat: 38.7654351 },
            { lng: 0.1082806, lat: 38.7656857 },
            { lng: 0.108673, lat: 38.7659074 },
            { lng: 0.1089463, lat: 38.7663077 },
            { lng: 0.1092905, lat: 38.7668035 },
            { lng: 0.1095265, lat: 38.7671388 },
            { lng: 0.11001, lat: 38.7685485 },
            { lng: 0.110077, lat: 38.7687604 },
            { lng: 0.1105491, lat: 38.7692893 },
            { lng: 0.111227, lat: 38.7699574 },
            { lng: 0.1126237, lat: 38.7688773 },
            { lng: 0.1133491, lat: 38.7686354 },
            { lng: 0.1138494, lat: 38.768563 },
            { lng: 0.114551, lat: 38.7686556 },
            { lng: 0.1148456, lat: 38.7687329 },
            { lng: 0.1152356, lat: 38.7689588 },
            { lng: 0.1162963, lat: 38.7698397 },
            { lng: 0.1157419, lat: 38.7701622 },
            { lng: 0.1188643, lat: 38.7701929 },
            { lng: 0.1198454, lat: 38.7694424 },
            { lng: 0.1204005, lat: 38.7684311 },
            { lng: 0.1210727, lat: 38.7688343 },
            { lng: 0.1222982, lat: 38.7689939 },
            { lng: 0.122565, lat: 38.7689985 },
            { lng: 0.1231228, lat: 38.7687827 },
            { lng: 0.1234577, lat: 38.7686503 },
            { lng: 0.1240871, lat: 38.7683808 },
            { lng: 0.1244879, lat: 38.7682114 },
            { lng: 0.124652, lat: 38.7681334 },
            { lng: 0.1248003, lat: 38.7680699 },
            { lng: 0.124523, lat: 38.7679403 },
            { lng: 0.1235327, lat: 38.7675907 },
            { lng: 0.1243651, lat: 38.7659457 },
            { lng: 0.1241244, lat: 38.7653394 },
            { lng: 0.1237131, lat: 38.7651866 },
            { lng: 0.1234473, lat: 38.7648772 },
            { lng: 0.123217, lat: 38.7645412 },
            { lng: 0.123165, lat: 38.7644191 },
            { lng: 0.1232675, lat: 38.763815 },
            { lng: 0.123099, lat: 38.7630491 },
            { lng: 0.1230633, lat: 38.7626611 },
            { lng: 0.1228271, lat: 38.7619667 },
            { lng: 0.122385, lat: 38.7605109 },
            { lng: 0.1222948, lat: 38.7598108 },
            { lng: 0.1230638, lat: 38.7586046 },
            { lng: 0.1233159, lat: 38.7582218 },
            { lng: 0.1231616, lat: 38.7566473 },
            { lng: 0.1209424, lat: 38.7546516 },
            { lng: 0.1201843, lat: 38.7535493 },
            { lng: 0.1191235, lat: 38.7525851 },
            { lng: 0.1184011, lat: 38.7519163 },
            { lng: 0.1181794, lat: 38.7516234 },
            { lng: 0.1179669, lat: 38.7512017 },
            { lng: 0.1175946, lat: 38.7501154 },
            { lng: 0.1171536, lat: 38.7493531 }
        ],
        [
            { lng: 0.215982, lat: 38.7268763 },
            { lng: 0.216167, lat: 38.7268394 },
            { lng: 0.2162898, lat: 38.7267887 },
            { lng: 0.2166797, lat: 38.7267598 },
            { lng: 0.216908, lat: 38.7266668 },
            { lng: 0.2170561, lat: 38.7266448 },
            { lng: 0.2171476, lat: 38.726658 },
            { lng: 0.2171889, lat: 38.7266217 },
            { lng: 0.2172767, lat: 38.7265957 },
            { lng: 0.2173847, lat: 38.7264954 },
            { lng: 0.2176573, lat: 38.7264091 },
            { lng: 0.2177153, lat: 38.726406 },
            { lng: 0.2177978, lat: 38.7263908 },
            { lng: 0.2179148, lat: 38.7263733 },
            { lng: 0.2179811, lat: 38.7263566 },
            { lng: 0.2181196, lat: 38.7263228 },
            { lng: 0.218184, lat: 38.7262995 },
            { lng: 0.2182643, lat: 38.7262629 },
            { lng: 0.2183168, lat: 38.7262684 },
            { lng: 0.2183416, lat: 38.726249 },
            { lng: 0.2183818, lat: 38.7262582 },
            { lng: 0.2184086, lat: 38.7262817 },
            { lng: 0.2185139, lat: 38.7263238 },
            { lng: 0.2185696, lat: 38.7263769 },
            { lng: 0.2185794, lat: 38.7264107 },
            { lng: 0.2185736, lat: 38.7264543 },
            { lng: 0.2185595, lat: 38.7264957 },
            { lng: 0.2185611, lat: 38.7265321 },
            { lng: 0.2185153, lat: 38.7265987 },
            { lng: 0.2184925, lat: 38.7266649 },
            { lng: 0.2183445, lat: 38.7268302 },
            { lng: 0.2180924, lat: 38.7269728 },
            { lng: 0.2180325, lat: 38.7270089 },
            { lng: 0.2179538, lat: 38.7270836 },
            { lng: 0.2178233, lat: 38.7270999 },
            { lng: 0.2177101, lat: 38.7271004 },
            { lng: 0.2174469, lat: 38.7271368 },
            { lng: 0.2173405, lat: 38.7271972 },
            { lng: 0.2171057, lat: 38.727316 },
            { lng: 0.2168318, lat: 38.7274347 },
            { lng: 0.2167423, lat: 38.7274765 },
            { lng: 0.2165532, lat: 38.7275372 },
            { lng: 0.2164513, lat: 38.7275529 },
            { lng: 0.2163669, lat: 38.7275927 },
            { lng: 0.2162917, lat: 38.7276031 },
            { lng: 0.2162206, lat: 38.7276282 },
            { lng: 0.2161369, lat: 38.7276535 },
            { lng: 0.2161066, lat: 38.7276795 },
            { lng: 0.2159846, lat: 38.7277067 },
            { lng: 0.2159377, lat: 38.7277056 },
            { lng: 0.2158208, lat: 38.7277399 },
            { lng: 0.2157087, lat: 38.7277608 },
            { lng: 0.215597, lat: 38.7277109 },
            { lng: 0.2155286, lat: 38.7277203 },
            { lng: 0.2155045, lat: 38.7277036 },
            { lng: 0.2155199, lat: 38.7276511 },
            { lng: 0.2154441, lat: 38.7276272 },
            { lng: 0.2153098, lat: 38.7275499 },
            { lng: 0.2151738, lat: 38.727432 },
            { lng: 0.2151214, lat: 38.7273083 },
            { lng: 0.2151716, lat: 38.7272635 },
            { lng: 0.2151276, lat: 38.7272139 },
            { lng: 0.2152601, lat: 38.7271589 },
            { lng: 0.2153194, lat: 38.7271344 },
            { lng: 0.2153996, lat: 38.7270778 },
            { lng: 0.2154915, lat: 38.7270444 },
            { lng: 0.2155863, lat: 38.7270507 },
            { lng: 0.2156332, lat: 38.7270601 },
            { lng: 0.2157122, lat: 38.727054 },
            { lng: 0.215982, lat: 38.7268763 }
        ],
        [
            { lng: 0.2237518, lat: 38.761164 },
            { lng: 0.2236461, lat: 38.7612347 },
            { lng: 0.223604, lat: 38.7611908 },
            { lng: 0.2236035, lat: 38.7611095 },
            { lng: 0.2236641, lat: 38.7609834 },
            { lng: 0.2237211, lat: 38.7609616 },
            { lng: 0.2237821, lat: 38.7610873 },
            { lng: 0.2237518, lat: 38.761164 }
        ],
        [
            { lng: 0.2241143, lat: 38.7612931 },
            { lng: 0.2239587, lat: 38.7613551 },
            { lng: 0.2239274, lat: 38.761337 },
            { lng: 0.2239228, lat: 38.7612171 },
            { lng: 0.223979, lat: 38.7611682 },
            { lng: 0.2240556, lat: 38.7610736 },
            { lng: 0.2241698, lat: 38.7611657 },
            { lng: 0.2241897, lat: 38.7612365 },
            { lng: 0.2241143, lat: 38.7612931 }
        ],
        [
            { lng: 0.224393, lat: 38.7612882 },
            { lng: 0.2244698, lat: 38.7611652 },
            { lng: 0.2245783, lat: 38.7611478 },
            { lng: 0.2246145, lat: 38.7612777 },
            { lng: 0.2246992, lat: 38.7612963 },
            { lng: 0.2247696, lat: 38.7612789 },
            { lng: 0.2247911, lat: 38.7611887 },
            { lng: 0.2248412, lat: 38.7611491 },
            { lng: 0.2248907, lat: 38.7613166 },
            { lng: 0.224872, lat: 38.761441 },
            { lng: 0.2247816, lat: 38.7615695 },
            { lng: 0.2247374, lat: 38.7615239 },
            { lng: 0.2247041, lat: 38.7615774 },
            { lng: 0.2244902, lat: 38.761768 },
            { lng: 0.2244622, lat: 38.7617313 },
            { lng: 0.2244853, lat: 38.7616381 },
            { lng: 0.2245946, lat: 38.7615043 },
            { lng: 0.224612, lat: 38.7613898 },
            { lng: 0.224441, lat: 38.7614895 },
            { lng: 0.2243385, lat: 38.7615806 },
            { lng: 0.2241697, lat: 38.7616258 },
            { lng: 0.224149, lat: 38.7616011 },
            { lng: 0.2242194, lat: 38.7614694 },
            { lng: 0.2242076, lat: 38.7613005 },
            { lng: 0.224393, lat: 38.7612882 }
        ],
        [
            { lng: 0.2251546, lat: 38.761543 },
            { lng: 0.2251948, lat: 38.7615887 },
            { lng: 0.2252754, lat: 38.7615916 },
            { lng: 0.2252974, lat: 38.7616658 },
            { lng: 0.2252571, lat: 38.76174 },
            { lng: 0.2251875, lat: 38.76174 },
            { lng: 0.225107, lat: 38.7617743 },
            { lng: 0.2250777, lat: 38.7617514 },
            { lng: 0.2250521, lat: 38.7616572 },
            { lng: 0.225096, lat: 38.7616287 },
            { lng: 0.225096, lat: 38.761563 },
            { lng: 0.2251546, lat: 38.761543 }
        ],
        [
            { lng: 0.2305461, lat: 38.7536389 },
            { lng: 0.2304446, lat: 38.7537549 },
            { lng: 0.2301717, lat: 38.7538904 },
            { lng: 0.2304877, lat: 38.7541171 },
            { lng: 0.2308925, lat: 38.7543662 },
            { lng: 0.2309193, lat: 38.754443 },
            { lng: 0.2310829, lat: 38.7545037 },
            { lng: 0.2311872, lat: 38.754566 },
            { lng: 0.2313388, lat: 38.7548452 },
            { lng: 0.2314147, lat: 38.7550935 },
            { lng: 0.2314075, lat: 38.7553027 },
            { lng: 0.2313524, lat: 38.7554649 },
            { lng: 0.231169, lat: 38.7558015 },
            { lng: 0.2314532, lat: 38.7558905 },
            { lng: 0.2315121, lat: 38.7558256 },
            { lng: 0.2316014, lat: 38.755841 },
            { lng: 0.2316754, lat: 38.7562296 },
            { lng: 0.2315176, lat: 38.7564761 },
            { lng: 0.2312735, lat: 38.7565599 },
            { lng: 0.2310652, lat: 38.7567315 },
            { lng: 0.2307195, lat: 38.7569113 },
            { lng: 0.2304929, lat: 38.7568819 },
            { lng: 0.2302462, lat: 38.7568882 },
            { lng: 0.2298525, lat: 38.7568717 },
            { lng: 0.2296335, lat: 38.7567827 },
            { lng: 0.2292394, lat: 38.7568834 },
            { lng: 0.2290268, lat: 38.7568785 },
            { lng: 0.2289076, lat: 38.756822 },
            { lng: 0.228653, lat: 38.7561624 },
            { lng: 0.2286341, lat: 38.7559365 },
            { lng: 0.2284491, lat: 38.7558988 },
            { lng: 0.2284463, lat: 38.7557441 },
            { lng: 0.2284115, lat: 38.7557012 },
            { lng: 0.2283417, lat: 38.7557127 },
            { lng: 0.2283204, lat: 38.7556876 },
            { lng: 0.2284008, lat: 38.755523 },
            { lng: 0.2283471, lat: 38.75524 },
            { lng: 0.22839, lat: 38.7549534 },
            { lng: 0.2283444, lat: 38.754943 },
            { lng: 0.2282532, lat: 38.7546397 },
            { lng: 0.2283122, lat: 38.7545999 },
            { lng: 0.2282908, lat: 38.7545183 },
            { lng: 0.2282086, lat: 38.754507 },
            { lng: 0.2281513, lat: 38.7544556 },
            { lng: 0.2280708, lat: 38.7540686 },
            { lng: 0.2280853, lat: 38.7538941 },
            { lng: 0.2281486, lat: 38.7538406 },
            { lng: 0.2284712, lat: 38.7536923 },
            { lng: 0.2288782, lat: 38.7537319 },
            { lng: 0.2290492, lat: 38.7537305 },
            { lng: 0.2296203, lat: 38.7535794 },
            { lng: 0.2302487, lat: 38.7534619 },
            { lng: 0.2304926, lat: 38.7535662 },
            { lng: 0.2305461, lat: 38.7536389 }
        ],
        [
            { lng: 0.2312379, lat: 38.732852 },
            { lng: 0.2313215, lat: 38.7327702 },
            { lng: 0.2315385, lat: 38.7327005 },
            { lng: 0.2317305, lat: 38.7326158 },
            { lng: 0.2317229, lat: 38.7326965 },
            { lng: 0.2315587, lat: 38.7327563 },
            { lng: 0.2315505, lat: 38.7327968 },
            { lng: 0.2314738, lat: 38.7328267 },
            { lng: 0.231441, lat: 38.7328096 },
            { lng: 0.2313972, lat: 38.7329249 },
            { lng: 0.2313507, lat: 38.7329846 },
            { lng: 0.2312357, lat: 38.732927 },
            { lng: 0.2312379, lat: 38.732852 }
        ]
    ];
});
