define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.javeaZoneCentroCiudad = void 0;
    exports.javeaZoneCentroCiudad = [
        [
            { lng: 0.15538, lat: 38.78162 },
            { lng: 0.15602, lat: 38.78217 },
            { lng: 0.15727, lat: 38.78372 },
            { lng: 0.15958, lat: 38.7849 },
            { lng: 0.15818, lat: 38.78722 },
            { lng: 0.15702, lat: 38.78814 },
            { lng: 0.15712, lat: 38.78947 },
            { lng: 0.15741, lat: 38.7902 },
            { lng: 0.15785, lat: 38.79057 },
            { lng: 0.16133, lat: 38.79194 },
            { lng: 0.16164, lat: 38.79244 },
            { lng: 0.16259, lat: 38.79287 },
            { lng: 0.16365, lat: 38.79312 },
            { lng: 0.1649, lat: 38.79317 },
            { lng: 0.16578, lat: 38.79311 },
            { lng: 0.1659, lat: 38.7929 },
            { lng: 0.16813, lat: 38.79321 },
            { lng: 0.17011, lat: 38.79317 },
            { lng: 0.17207, lat: 38.79416 },
            { lng: 0.17393, lat: 38.79438 },
            { lng: 0.17577, lat: 38.79439 },
            { lng: 0.17776, lat: 38.79057 },
            { lng: 0.1772, lat: 38.79022 },
            { lng: 0.17746, lat: 38.78879 },
            { lng: 0.17676, lat: 38.78803 },
            { lng: 0.17602, lat: 38.78764 },
            { lng: 0.1748, lat: 38.78777 },
            { lng: 0.17386, lat: 38.78746 },
            { lng: 0.17283, lat: 38.78698 },
            { lng: 0.17225, lat: 38.78654 },
            { lng: 0.17125, lat: 38.78514 },
            { lng: 0.16936, lat: 38.7849 },
            { lng: 0.16852, lat: 38.78452 },
            { lng: 0.16826, lat: 38.78405 },
            { lng: 0.16809, lat: 38.78249 },
            { lng: 0.1677, lat: 38.78197 },
            { lng: 0.16729, lat: 38.78181 },
            { lng: 0.16587, lat: 38.78167 },
            { lng: 0.16454, lat: 38.78111 },
            { lng: 0.16352, lat: 38.78112 },
            { lng: 0.16093, lat: 38.78186 },
            { lng: 0.15973, lat: 38.78165 },
            { lng: 0.15912, lat: 38.78178 },
            { lng: 0.15723, lat: 38.78178 },
            { lng: 0.15538, lat: 38.78162 }
        ]
    ];
});
