define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.morairaZoneMoravit = void 0;
    exports.morairaZoneMoravit = [
        [
            { lng: 0.121, lat: 38.68171 },
            { lng: 0.12135, lat: 38.68148 },
            { lng: 0.12108, lat: 38.68113 },
            { lng: 0.12122, lat: 38.68089 },
            { lng: 0.12058, lat: 38.6807 },
            { lng: 0.12033, lat: 38.68038 },
            { lng: 0.12001, lat: 38.6803 },
            { lng: 0.11988, lat: 38.67996 },
            { lng: 0.12015, lat: 38.67968 },
            { lng: 0.11973, lat: 38.67926 },
            { lng: 0.11937, lat: 38.67939 },
            { lng: 0.1181, lat: 38.67836 },
            { lng: 0.11545, lat: 38.67487 },
            { lng: 0.11516, lat: 38.67379 },
            { lng: 0.11454, lat: 38.67363 },
            { lng: 0.11429, lat: 38.67381 },
            { lng: 0.11362, lat: 38.67383 },
            { lng: 0.11313, lat: 38.67453 },
            { lng: 0.11243, lat: 38.67489 },
            { lng: 0.11169, lat: 38.67494 },
            { lng: 0.1085, lat: 38.67457 },
            { lng: 0.10585, lat: 38.68075 },
            { lng: 0.10552, lat: 38.68237 },
            { lng: 0.10575, lat: 38.68316 },
            { lng: 0.1073, lat: 38.68575 },
            { lng: 0.10755, lat: 38.68647 },
            { lng: 0.10767, lat: 38.68783 },
            { lng: 0.1081, lat: 38.68784 },
            { lng: 0.10929, lat: 38.68749 },
            { lng: 0.10977, lat: 38.68719 },
            { lng: 0.11001, lat: 38.68672 },
            { lng: 0.11256, lat: 38.68556 },
            { lng: 0.11389, lat: 38.68541 },
            { lng: 0.11537, lat: 38.68482 },
            { lng: 0.11614, lat: 38.68411 },
            { lng: 0.11723, lat: 38.68272 },
            { lng: 0.11864, lat: 38.68198 },
            { lng: 0.1188, lat: 38.68155 },
            { lng: 0.11929, lat: 38.68117 },
            { lng: 0.12004, lat: 38.68134 },
            { lng: 0.12055, lat: 38.68187 },
            { lng: 0.121, lat: 38.68171 }
        ]
    ];
});
