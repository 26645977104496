define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.javeaZoneAdsubia = void 0;
    exports.javeaZoneAdsubia = [
        [
            { lng: 0.18735, lat: 38.7647 },
            { lng: 0.1868, lat: 38.76467 },
            { lng: 0.18533, lat: 38.76516 },
            { lng: 0.183, lat: 38.76522 },
            { lng: 0.18109, lat: 38.76587 },
            { lng: 0.17848, lat: 38.76632 },
            { lng: 0.17748, lat: 38.76685 },
            { lng: 0.1771, lat: 38.76516 },
            { lng: 0.17611, lat: 38.76385 },
            { lng: 0.17548, lat: 38.76328 },
            { lng: 0.17371, lat: 38.7624 },
            { lng: 0.1727, lat: 38.76043 },
            { lng: 0.17019, lat: 38.75799 },
            { lng: 0.16932, lat: 38.75545 },
            { lng: 0.16957, lat: 38.75302 },
            { lng: 0.16927, lat: 38.74997 },
            { lng: 0.16841, lat: 38.74876 },
            { lng: 0.16529, lat: 38.74525 },
            { lng: 0.1642, lat: 38.74365 },
            { lng: 0.16097, lat: 38.74245 },
            { lng: 0.15801, lat: 38.74104 },
            { lng: 0.15548, lat: 38.73897 },
            { lng: 0.15537, lat: 38.73913 },
            { lng: 0.15048, lat: 38.74076 },
            { lng: 0.14943, lat: 38.74128 },
            { lng: 0.14525, lat: 38.7423 },
            { lng: 0.1403, lat: 38.74318 },
            { lng: 0.13463, lat: 38.74374 },
            { lng: 0.13385, lat: 38.74398 },
            { lng: 0.12287, lat: 38.74281 },
            { lng: 0.11815, lat: 38.74095 },
            { lng: 0.11714, lat: 38.74237 },
            { lng: 0.11668, lat: 38.74526 },
            { lng: 0.1174, lat: 38.74988 },
            { lng: 0.11819, lat: 38.75157 },
            { lng: 0.12301, lat: 38.75662 },
            { lng: 0.12326, lat: 38.75724 },
            { lng: 0.12344, lat: 38.75805 },
            { lng: 0.12235, lat: 38.75954 },
            { lng: 0.12307, lat: 38.76366 },
            { lng: 0.12311, lat: 38.76465 },
            { lng: 0.12342, lat: 38.76544 },
            { lng: 0.12403, lat: 38.76616 },
            { lng: 0.12387, lat: 38.76707 },
            { lng: 0.12344, lat: 38.76738 },
            { lng: 0.12394, lat: 38.76784 },
            { lng: 0.12647, lat: 38.76664 },
            { lng: 0.12701, lat: 38.76716 },
            { lng: 0.12263, lat: 38.76901 },
            { lng: 0.12086, lat: 38.76875 },
            { lng: 0.12016, lat: 38.76828 },
            { lng: 0.11991, lat: 38.76929 },
            { lng: 0.11892, lat: 38.77011 },
            { lng: 0.11688, lat: 38.77017 },
            { lng: 0.11605, lat: 38.77 },
            { lng: 0.11483, lat: 38.76896 },
            { lng: 0.11324, lat: 38.76884 },
            { lng: 0.11186, lat: 38.76947 },
            { lng: 0.11118, lat: 38.77004 },
            { lng: 0.10956, lat: 38.76798 },
            { lng: 0.10808, lat: 38.76572 },
            { lng: 0.10747, lat: 38.76558 },
            { lng: 0.1057, lat: 38.76576 },
            { lng: 0.10431, lat: 38.76843 },
            { lng: 0.10391, lat: 38.77784 },
            { lng: 0.10365, lat: 38.77942 },
            { lng: 0.10833, lat: 38.77763 },
            { lng: 0.11488, lat: 38.776 },
            { lng: 0.12982, lat: 38.77521 },
            { lng: 0.14365, lat: 38.77534 },
            { lng: 0.14449, lat: 38.77554 },
            { lng: 0.14595, lat: 38.77623 },
            { lng: 0.1482, lat: 38.77896 },
            { lng: 0.1495, lat: 38.77969 },
            { lng: 0.15446, lat: 38.78101 },
            { lng: 0.15538, lat: 38.78162 },
            { lng: 0.15723, lat: 38.78178 },
            { lng: 0.15912, lat: 38.78178 },
            { lng: 0.15973, lat: 38.78165 },
            { lng: 0.16093, lat: 38.78186 },
            { lng: 0.16352, lat: 38.78112 },
            { lng: 0.16486, lat: 38.78117 },
            { lng: 0.16587, lat: 38.78167 },
            { lng: 0.16697, lat: 38.78174 },
            { lng: 0.1677, lat: 38.78197 },
            { lng: 0.16819, lat: 38.78279 },
            { lng: 0.16826, lat: 38.78405 },
            { lng: 0.16852, lat: 38.78452 },
            { lng: 0.16936, lat: 38.7849 },
            { lng: 0.1711, lat: 38.78509 },
            { lng: 0.17146, lat: 38.78466 },
            { lng: 0.17243, lat: 38.78427 },
            { lng: 0.17338, lat: 38.78357 },
            { lng: 0.17483, lat: 38.78179 },
            { lng: 0.17611, lat: 38.78095 },
            { lng: 0.17631, lat: 38.77557 },
            { lng: 0.17908, lat: 38.77177 },
            { lng: 0.18248, lat: 38.77164 },
            { lng: 0.18334, lat: 38.77015 },
            { lng: 0.18718, lat: 38.76515 },
            { lng: 0.18735, lat: 38.7647 }
        ]
    ];
});
