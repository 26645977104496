define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.morairaZoneAdvocat = void 0;
    exports.morairaZoneAdvocat = [
        [
            { lng: 0.12919, lat: 38.68638 },
            { lng: 0.12942, lat: 38.68605 },
            { lng: 0.1288, lat: 38.6856 },
            { lng: 0.12771, lat: 38.68536 },
            { lng: 0.12735, lat: 38.68551 },
            { lng: 0.12691, lat: 38.68543 },
            { lng: 0.12685, lat: 38.68506 },
            { lng: 0.12544, lat: 38.68506 },
            { lng: 0.12498, lat: 38.68436 },
            { lng: 0.12401, lat: 38.68399 },
            { lng: 0.12384, lat: 38.68372 },
            { lng: 0.121, lat: 38.68171 },
            { lng: 0.12055, lat: 38.68187 },
            { lng: 0.12004, lat: 38.68134 },
            { lng: 0.11936, lat: 38.68115 },
            { lng: 0.1188, lat: 38.68155 },
            { lng: 0.11864, lat: 38.68198 },
            { lng: 0.11738, lat: 38.68258 },
            { lng: 0.11537, lat: 38.68482 },
            { lng: 0.11389, lat: 38.68541 },
            { lng: 0.11256, lat: 38.68556 },
            { lng: 0.11001, lat: 38.68672 },
            { lng: 0.10977, lat: 38.68719 },
            { lng: 0.10904, lat: 38.68761 },
            { lng: 0.10767, lat: 38.68783 },
            { lng: 0.10766, lat: 38.68826 },
            { lng: 0.10744, lat: 38.68847 },
            { lng: 0.10579, lat: 38.68953 },
            { lng: 0.10232, lat: 38.69123 },
            { lng: 0.10045, lat: 38.69318 },
            { lng: 0.0978, lat: 38.69495 },
            { lng: 0.10044, lat: 38.69692 },
            { lng: 0.10603, lat: 38.69757 },
            { lng: 0.10733, lat: 38.69797 },
            { lng: 0.1086, lat: 38.69701 },
            { lng: 0.10984, lat: 38.69655 },
            { lng: 0.11063, lat: 38.69652 },
            { lng: 0.11226, lat: 38.69604 },
            { lng: 0.11258, lat: 38.69619 },
            { lng: 0.1136, lat: 38.69599 },
            { lng: 0.114, lat: 38.69581 },
            { lng: 0.11376, lat: 38.6951 },
            { lng: 0.11496, lat: 38.69469 },
            { lng: 0.1178, lat: 38.69476 },
            { lng: 0.12053, lat: 38.69568 },
            { lng: 0.12226, lat: 38.69571 },
            { lng: 0.12479, lat: 38.6942 },
            { lng: 0.12545, lat: 38.69397 },
            { lng: 0.1263, lat: 38.69307 },
            { lng: 0.12755, lat: 38.69254 },
            { lng: 0.12845, lat: 38.6918 },
            { lng: 0.13289, lat: 38.69198 },
            { lng: 0.13356, lat: 38.68905 },
            { lng: 0.13343, lat: 38.68946 },
            { lng: 0.12876, lat: 38.6895 },
            { lng: 0.12884, lat: 38.68921 },
            { lng: 0.12925, lat: 38.68889 },
            { lng: 0.12885, lat: 38.68658 },
            { lng: 0.12919, lat: 38.68638 }
        ]
    ];
});
