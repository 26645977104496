define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.benissa = void 0;
    exports.benissa = [
        [
            { lng: -0.0443928, lat: 38.660956 },
            { lng: -0.0116565, lat: 38.6457716 },
            { lng: -0.0096616, lat: 38.6448217 },
            { lng: -0.0066777, lat: 38.6434009 },
            { lng: -0.0007759, lat: 38.6485319 },
            { lng: 0.0001167, lat: 38.6493079 },
            { lng: 0.0004479, lat: 38.6493346 },
            { lng: 0.0010432, lat: 38.6492739 },
            { lng: 0.0013946, lat: 38.6492777 },
            { lng: 0.0092387, lat: 38.6520225 },
            { lng: 0.0116437, lat: 38.6527195 },
            { lng: 0.0169944, lat: 38.6548552 },
            { lng: 0.0203832, lat: 38.6572229 },
            { lng: 0.0257042, lat: 38.6601013 },
            { lng: 0.0294497, lat: 38.6624457 },
            { lng: 0.0309657, lat: 38.6631885 },
            { lng: 0.0332765, lat: 38.663899 },
            { lng: 0.0333735, lat: 38.6639312 },
            { lng: 0.0352269, lat: 38.6625838 },
            { lng: 0.0366062, lat: 38.6631071 },
            { lng: 0.0386712, lat: 38.6629733 },
            { lng: 0.0406817, lat: 38.6637034 },
            { lng: 0.04087, lat: 38.6638773 },
            { lng: 0.0436898, lat: 38.6664839 },
            { lng: 0.0438271, lat: 38.6668391 },
            { lng: 0.0442195, lat: 38.6672507 },
            { lng: 0.0420317, lat: 38.6698623 },
            { lng: 0.0412136, lat: 38.6709615 },
            { lng: 0.0384605, lat: 38.6742328 },
            { lng: 0.0384377, lat: 38.6745386 },
            { lng: 0.0383556, lat: 38.6756331 },
            { lng: 0.0382423, lat: 38.6766512 },
            { lng: 0.0382659, lat: 38.6767649 },
            { lng: 0.0382634, lat: 38.6768976 },
            { lng: 0.0382758, lat: 38.6770192 },
            { lng: 0.0383068, lat: 38.6771144 },
            { lng: 0.0383288, lat: 38.6771483 },
            { lng: 0.0383358, lat: 38.6771592 },
            { lng: 0.0383466, lat: 38.6771759 },
            { lng: 0.0383822, lat: 38.6772311 },
            { lng: 0.0383943, lat: 38.6772498 },
            { lng: 0.0384333, lat: 38.6772972 },
            { lng: 0.0386055, lat: 38.6775069 },
            { lng: 0.0389891, lat: 38.6779088 },
            { lng: 0.0392781, lat: 38.6778916 },
            { lng: 0.0407643, lat: 38.6771285 },
            { lng: 0.0411052, lat: 38.6770691 },
            { lng: 0.0421003, lat: 38.6761407 },
            { lng: 0.0441796, lat: 38.674158 },
            { lng: 0.0460516, lat: 38.6749066 },
            { lng: 0.0488216, lat: 38.6727678 },
            { lng: 0.049537, lat: 38.6725539 },
            { lng: 0.0495332, lat: 38.6751143 },
            { lng: 0.0493658, lat: 38.6769798 },
            { lng: 0.0505986, lat: 38.6788038 },
            { lng: 0.0547588, lat: 38.6788981 },
            { lng: 0.0598792, lat: 38.6805468 },
            { lng: 0.0643463, lat: 38.6799147 },
            { lng: 0.0683177, lat: 38.6805944 },
            { lng: 0.0693121, lat: 38.6782719 },
            { lng: 0.0692032, lat: 38.6775723 },
            { lng: 0.0722439, lat: 38.6750236 },
            { lng: 0.0748299, lat: 38.6709759 },
            { lng: 0.0749517, lat: 38.6707855 },
            { lng: 0.0750082, lat: 38.6703689 },
            { lng: 0.0752205, lat: 38.6688063 },
            { lng: 0.0749453, lat: 38.6682215 },
            { lng: 0.0747532, lat: 38.6680116 },
            { lng: 0.0750349, lat: 38.6678017 },
            { lng: 0.0747683, lat: 38.6676944 },
            { lng: 0.0748242, lat: 38.6676342 },
            { lng: 0.0746828, lat: 38.6675518 },
            { lng: 0.0746548, lat: 38.6675288 },
            { lng: 0.0746362, lat: 38.6674948 },
            { lng: 0.074637, lat: 38.6674626 },
            { lng: 0.0746444, lat: 38.6674118 },
            { lng: 0.0749363, lat: 38.6670891 },
            { lng: 0.0747364, lat: 38.6670083 },
            { lng: 0.0747423, lat: 38.6670084 },
            { lng: 0.0747684, lat: 38.6668175 },
            { lng: 0.0747885, lat: 38.6664916 },
            { lng: 0.0749694, lat: 38.6663351 },
            { lng: 0.075224, lat: 38.6661776 },
            { lng: 0.0753675, lat: 38.6660887 },
            { lng: 0.0754327, lat: 38.6660483 },
            { lng: 0.0757446, lat: 38.6658551 },
            { lng: 0.0758766, lat: 38.6657355 },
            { lng: 0.0759665, lat: 38.6656665 },
            { lng: 0.0761628, lat: 38.6655159 },
            { lng: 0.0761934, lat: 38.6654923 },
            { lng: 0.0763437, lat: 38.6653439 },
            { lng: 0.0763981, lat: 38.6652899 },
            { lng: 0.076467, lat: 38.6652307 },
            { lng: 0.0765146, lat: 38.6651926 },
            { lng: 0.0765421, lat: 38.6651763 },
            { lng: 0.0765986, lat: 38.6651431 },
            { lng: 0.0767093, lat: 38.6650889 },
            { lng: 0.0768492, lat: 38.6650351 },
            { lng: 0.0770753, lat: 38.664948 },
            { lng: 0.0774482, lat: 38.6647178 },
            { lng: 0.0774838, lat: 38.6647047 },
            { lng: 0.0778067, lat: 38.6645878 },
            { lng: 0.0778619, lat: 38.6645516 },
            { lng: 0.0783508, lat: 38.6642329 },
            { lng: 0.078549, lat: 38.6640382 },
            { lng: 0.078672, lat: 38.6639175 },
            { lng: 0.0786797, lat: 38.6639094 },
            { lng: 0.0786922, lat: 38.6638959 },
            { lng: 0.078754, lat: 38.6638301 },
            { lng: 0.0787905, lat: 38.6637911 },
            { lng: 0.0789355, lat: 38.663655 },
            { lng: 0.0790394, lat: 38.6635323 },
            { lng: 0.0791491, lat: 38.6634301 },
            { lng: 0.0792697, lat: 38.6633179 },
            { lng: 0.0794199, lat: 38.6631783 },
            { lng: 0.0800536, lat: 38.6629034 },
            { lng: 0.0801581, lat: 38.6628196 },
            { lng: 0.0815131, lat: 38.6617337 },
            { lng: 0.0816988, lat: 38.6616788 },
            { lng: 0.0820764, lat: 38.6617087 },
            { lng: 0.0823901, lat: 38.6616987 },
            { lng: 0.082787, lat: 38.6617937 },
            { lng: 0.0832159, lat: 38.6618539 },
            { lng: 0.0834699, lat: 38.6619211 },
            { lng: 0.0837444, lat: 38.6621898 },
            { lng: 0.0841377, lat: 38.6623146 },
            { lng: 0.0842114, lat: 38.6622378 },
            { lng: 0.0841746, lat: 38.6620203 },
            { lng: 0.084277, lat: 38.6617515 },
            { lng: 0.0842606, lat: 38.661358 },
            { lng: 0.0843179, lat: 38.6610125 },
            { lng: 0.0843958, lat: 38.6608334 },
            { lng: 0.0843334, lat: 38.660784 },
            { lng: 0.0845163, lat: 38.6605097 },
            { lng: 0.0846662, lat: 38.6603151 },
            { lng: 0.0846163, lat: 38.6601688 },
            { lng: 0.0845801, lat: 38.6600624 },
            { lng: 0.084658, lat: 38.6598769 },
            { lng: 0.0848434, lat: 38.6596521 },
            { lng: 0.0848319, lat: 38.659641 },
            { lng: 0.0844864, lat: 38.6593081 },
            { lng: 0.0845377, lat: 38.6592464 },
            { lng: 0.0848612, lat: 38.659108 },
            { lng: 0.084942, lat: 38.6589832 },
            { lng: 0.0849405, lat: 38.6586943 },
            { lng: 0.0850255, lat: 38.6586813 },
            { lng: 0.0851848, lat: 38.658955 },
            { lng: 0.0855074, lat: 38.6593532 },
            { lng: 0.0860146, lat: 38.6602233 },
            { lng: 0.085879, lat: 38.6601438 },
            { lng: 0.0857936, lat: 38.6601208 },
            { lng: 0.0851211, lat: 38.6593119 },
            { lng: 0.0848873, lat: 38.6594478 },
            { lng: 0.0850055, lat: 38.6595824 },
            { lng: 0.0850525, lat: 38.6597688 },
            { lng: 0.085119, lat: 38.6598406 },
            { lng: 0.0856896, lat: 38.6602735 },
            { lng: 0.0857806, lat: 38.660193 },
            { lng: 0.0858154, lat: 38.6602139 },
            { lng: 0.0857174, lat: 38.660316 },
            { lng: 0.0857312, lat: 38.6603726 },
            { lng: 0.0860482, lat: 38.6606267 },
            { lng: 0.0864172, lat: 38.6612365 },
            { lng: 0.0866833, lat: 38.661384 },
            { lng: 0.0870498, lat: 38.6611771 },
            { lng: 0.0882504, lat: 38.6617979 },
            { lng: 0.0883227, lat: 38.6620107 },
            { lng: 0.0885694, lat: 38.6620869 },
            { lng: 0.0884525, lat: 38.662569 },
            { lng: 0.0887459, lat: 38.6629737 },
            { lng: 0.0886547, lat: 38.6630713 },
            { lng: 0.0882851, lat: 38.6628543 },
            { lng: 0.0884944, lat: 38.6632606 },
            { lng: 0.088323, lat: 38.6632915 },
            { lng: 0.0886851, lat: 38.663914 },
            { lng: 0.0880516, lat: 38.6643078 },
            { lng: 0.0881792, lat: 38.6648091 },
            { lng: 0.0889544, lat: 38.6650072 },
            { lng: 0.0891795, lat: 38.6653099 },
            { lng: 0.0893701, lat: 38.6652874 },
            { lng: 0.0896121, lat: 38.6655781 },
            { lng: 0.0896367, lat: 38.6658365 },
            { lng: 0.0898814, lat: 38.6660849 },
            { lng: 0.0902172, lat: 38.6662282 },
            { lng: 0.0908378, lat: 38.6661348 },
            { lng: 0.0912638, lat: 38.6660933 },
            { lng: 0.0919504, lat: 38.6664703 },
            { lng: 0.0920722, lat: 38.6667224 },
            { lng: 0.0923597, lat: 38.6668887 },
            { lng: 0.0929337, lat: 38.6672167 },
            { lng: 0.0934455, lat: 38.6677549 },
            { lng: 0.0936627, lat: 38.6683735 },
            { lng: 0.0933275, lat: 38.6682717 },
            { lng: 0.0933833, lat: 38.6685645 },
            { lng: 0.0934793, lat: 38.6689117 },
            { lng: 0.0936622, lat: 38.6691157 },
            { lng: 0.0939841, lat: 38.6694583 },
            { lng: 0.0943531, lat: 38.6697527 },
            { lng: 0.094727, lat: 38.6699563 },
            { lng: 0.095583, lat: 38.6700765 },
            { lng: 0.096081, lat: 38.6703655 },
            { lng: 0.0967821, lat: 38.6707361 },
            { lng: 0.0976528, lat: 38.6711324 },
            { lng: 0.0974978, lat: 38.6707466 },
            { lng: 0.0978427, lat: 38.6709154 },
            { lng: 0.0978223, lat: 38.6711851 },
            { lng: 0.0977048, lat: 38.671748 },
            { lng: 0.0979151, lat: 38.6722552 },
            { lng: 0.0983915, lat: 38.6728349 },
            { lng: 0.0987831, lat: 38.6727657 },
            { lng: 0.0986211, lat: 38.6723855 },
            { lng: 0.0988238, lat: 38.6723771 },
            { lng: 0.0989751, lat: 38.6726405 },
            { lng: 0.0993565, lat: 38.6726175 },
            { lng: 0.0995631, lat: 38.6727356 },
            { lng: 0.0998946, lat: 38.6727775 },
            { lng: 0.1002899, lat: 38.6729752 },
            { lng: 0.1007196, lat: 38.6730556 },
            { lng: 0.1008612, lat: 38.6734166 },
            { lng: 0.1011225, lat: 38.6736867 },
            { lng: 0.1015409, lat: 38.6739016 },
            { lng: 0.1020248, lat: 38.6740444 },
            { lng: 0.1024674, lat: 38.6740419 },
            { lng: 0.1026379, lat: 38.673778 },
            { lng: 0.1027071, lat: 38.6739845 },
            { lng: 0.1030741, lat: 38.6741922 },
            { lng: 0.1034437, lat: 38.6742081 },
            { lng: 0.1036921, lat: 38.674121 },
            { lng: 0.1042618, lat: 38.6739083 },
            { lng: 0.1047703, lat: 38.6738898 },
            { lng: 0.105355, lat: 38.6741654 },
            { lng: 0.1068195, lat: 38.6743292 },
            { lng: 0.1079985, lat: 38.6744561 },
            { lng: 0.1083196, lat: 38.6748368 },
            { lng: 0.1080215, lat: 38.6755396 },
            { lng: 0.1073025, lat: 38.6772335 },
            { lng: 0.1061192, lat: 38.6806421 },
            { lng: 0.1057192, lat: 38.6828798 },
            { lng: 0.1076691, lat: 38.6858198 },
            { lng: 0.1077524, lat: 38.6882134 },
            { lng: 0.1074225, lat: 38.6884892 },
            { lng: 0.1071218, lat: 38.688597 },
            { lng: 0.1069266, lat: 38.6887366 },
            { lng: 0.1066663, lat: 38.6889525 },
            { lng: 0.1038979, lat: 38.6905035 },
            { lng: 0.1036361, lat: 38.690659 },
            { lng: 0.1018158, lat: 38.6917307 },
            { lng: 0.1017362, lat: 38.6917776 },
            { lng: 0.1002696, lat: 38.6931824 },
            { lng: 0.0978365, lat: 38.6946783 },
            { lng: 0.0965699, lat: 38.6963432 },
            { lng: 0.0948227, lat: 38.6974743 },
            { lng: 0.0932868, lat: 38.697865 },
            { lng: 0.0914536, lat: 38.6970456 },
            { lng: 0.0908298, lat: 38.6968867 },
            { lng: 0.089987, lat: 38.6966944 },
            { lng: 0.0895687, lat: 38.6967624 },
            { lng: 0.0881259, lat: 38.697033 },
            { lng: 0.0875039, lat: 38.6971496 },
            { lng: 0.085504, lat: 38.6970196 },
            { lng: 0.0823042, lat: 38.6943531 },
            { lng: 0.0819709, lat: 38.6931824 },
            { lng: 0.0806377, lat: 38.6934946 },
            { lng: 0.0793711, lat: 38.6952116 },
            { lng: 0.0802711, lat: 38.6962131 },
            { lng: 0.0810986, lat: 38.6963392 },
            { lng: 0.0811329, lat: 38.696922 },
            { lng: 0.0812016, lat: 38.6972101 },
            { lng: 0.0814591, lat: 38.6976522 },
            { lng: 0.0815535, lat: 38.6979804 },
            { lng: 0.0815793, lat: 38.6991594 },
            { lng: 0.0814591, lat: 38.6998292 },
            { lng: 0.0824709, lat: 38.7004142 },
            { lng: 0.0831709, lat: 38.7040039 },
            { lng: 0.0821543, lat: 38.7053174 },
            { lng: 0.0825876, lat: 38.7065919 },
            { lng: 0.0829375, lat: 38.7109745 },
            { lng: 0.083663, lat: 38.711818 },
            { lng: 0.0846207, lat: 38.7129511 },
            { lng: 0.0854207, lat: 38.7131982 },
            { lng: 0.0859177, lat: 38.7197867 },
            { lng: 0.0883666, lat: 38.7223498 },
            { lng: 0.0874621, lat: 38.7239741 },
            { lng: 0.0889895, lat: 38.7243136 },
            { lng: 0.0874365, lat: 38.7272892 },
            { lng: 0.0875901, lat: 38.7287803 },
            { lng: 0.0873831, lat: 38.7292253 },
            { lng: 0.0861137, lat: 38.7317645 },
            { lng: 0.0838027, lat: 38.7324246 },
            { lng: 0.0820172, lat: 38.7375543 },
            { lng: 0.0796764, lat: 38.7449948 },
            { lng: 0.0804209, lat: 38.7463809 },
            { lng: 0.0797048, lat: 38.7477433 },
            { lng: 0.0796458, lat: 38.7478246 },
            { lng: 0.0795635, lat: 38.7479274 },
            { lng: 0.0793519, lat: 38.7480787 },
            { lng: 0.0791252, lat: 38.7482635 },
            { lng: 0.0787857, lat: 38.7486404 },
            { lng: 0.0784727, lat: 38.7489851 },
            { lng: 0.0776064, lat: 38.7499874 },
            { lng: 0.0766362, lat: 38.7497717 },
            { lng: 0.0752508, lat: 38.7494746 },
            { lng: 0.0743848, lat: 38.7495384 },
            { lng: 0.0717031, lat: 38.7497729 },
            { lng: 0.0713096, lat: 38.749649 },
            { lng: 0.0710282, lat: 38.7495421 },
            { lng: 0.0706199, lat: 38.7494049 },
            { lng: 0.0705152, lat: 38.7493618 },
            { lng: 0.070322, lat: 38.7492888 },
            { lng: 0.0701492, lat: 38.7492279 },
            { lng: 0.0690302, lat: 38.7488349 },
            { lng: 0.0685694, lat: 38.7484383 },
            { lng: 0.0679008, lat: 38.7478573 },
            { lng: 0.0675364, lat: 38.7475236 },
            { lng: 0.0671384, lat: 38.7471786 },
            { lng: 0.0666182, lat: 38.7467248 },
            { lng: 0.0666166, lat: 38.7467341 },
            { lng: 0.0658318, lat: 38.7460415 },
            { lng: 0.0653755, lat: 38.7456681 },
            { lng: 0.0649426, lat: 38.745229 },
            { lng: 0.0645591, lat: 38.7449226 },
            { lng: 0.0642337, lat: 38.7450787 },
            { lng: 0.0637294, lat: 38.745323 },
            { lng: 0.063137, lat: 38.7456115 },
            { lng: 0.0623944, lat: 38.7459707 },
            { lng: 0.0616698, lat: 38.7463354 },
            { lng: 0.0609834, lat: 38.7466818 },
            { lng: 0.0596203, lat: 38.7473731 },
            { lng: 0.0587283, lat: 38.7477945 },
            { lng: 0.0578708, lat: 38.7482161 },
            { lng: 0.0574997, lat: 38.7483764 },
            { lng: 0.0564621, lat: 38.7488716 },
            { lng: 0.0556652, lat: 38.7492511 },
            { lng: 0.0550374, lat: 38.7495487 },
            { lng: 0.0550273, lat: 38.7495499 },
            { lng: 0.0550789, lat: 38.7494416 },
            { lng: 0.0550672, lat: 38.7493427 },
            { lng: 0.0550135, lat: 38.7491305 },
            { lng: 0.0550032, lat: 38.7487327 },
            { lng: 0.0548442, lat: 38.7485116 },
            { lng: 0.0545278, lat: 38.7483758 },
            { lng: 0.0542753, lat: 38.7481211 },
            { lng: 0.0540742, lat: 38.747721 },
            { lng: 0.053888, lat: 38.7474015 },
            { lng: 0.0535677, lat: 38.7471757 },
            { lng: 0.0529357, lat: 38.7469219 },
            { lng: 0.0516268, lat: 38.7467129 },
            { lng: 0.0513341, lat: 38.7465944 },
            { lng: 0.0507473, lat: 38.7460512 },
            { lng: 0.0506557, lat: 38.7457923 },
            { lng: 0.0506775, lat: 38.7454944 },
            { lng: 0.051139, lat: 38.7449869 },
            { lng: 0.0514198, lat: 38.7448264 },
            { lng: 0.0524171, lat: 38.7433949 },
            { lng: 0.0528418, lat: 38.7428343 },
            { lng: 0.0530394, lat: 38.7426129 },
            { lng: 0.0541849, lat: 38.7419613 },
            { lng: 0.0542952, lat: 38.74158 },
            { lng: 0.054453, lat: 38.7412336 },
            { lng: 0.0540687, lat: 38.740586 },
            { lng: 0.0536818, lat: 38.7401456 },
            { lng: 0.0534731, lat: 38.7398358 },
            { lng: 0.053306, lat: 38.7394257 },
            { lng: 0.0530329, lat: 38.7390981 },
            { lng: 0.0529391, lat: 38.7386058 },
            { lng: 0.0528651, lat: 38.7382903 },
            { lng: 0.0526415, lat: 38.7374244 },
            { lng: 0.0525322, lat: 38.7370371 },
            { lng: 0.0524534, lat: 38.7367772 },
            { lng: 0.0527871, lat: 38.7354996 },
            { lng: 0.0530424, lat: 38.7345948 },
            { lng: 0.0531274, lat: 38.7341601 },
            { lng: 0.0534502, lat: 38.7336382 },
            { lng: 0.0536424, lat: 38.7332908 },
            { lng: 0.0537737, lat: 38.7328639 },
            { lng: 0.0537599, lat: 38.7322697 },
            { lng: 0.0535549, lat: 38.731239 },
            { lng: 0.053492, lat: 38.7308443 },
            { lng: 0.0535234, lat: 38.7305011 },
            { lng: 0.0536872, lat: 38.7297581 },
            { lng: 0.0539031, lat: 38.728347 },
            { lng: 0.0540841, lat: 38.7274684 },
            { lng: 0.0540756, lat: 38.7270002 },
            { lng: 0.0541172, lat: 38.7267763 },
            { lng: 0.0540055, lat: 38.7264344 },
            { lng: 0.0539005, lat: 38.7258606 },
            { lng: 0.0538867, lat: 38.7255366 },
            { lng: 0.0540106, lat: 38.7252501 },
            { lng: 0.0544116, lat: 38.724631 },
            { lng: 0.0544973, lat: 38.7242143 },
            { lng: 0.0545341, lat: 38.7237269 },
            { lng: 0.0547017, lat: 38.7233441 },
            { lng: 0.0549716, lat: 38.7229317 },
            { lng: 0.0553212, lat: 38.7224991 },
            { lng: 0.0530948, lat: 38.720597 },
            { lng: 0.0524455, lat: 38.7210126 },
            { lng: 0.0519675, lat: 38.7213186 },
            { lng: 0.0509291, lat: 38.7219833 },
            { lng: 0.0508644, lat: 38.7215435 },
            { lng: 0.0507434, lat: 38.7211323 },
            { lng: 0.0505952, lat: 38.7208929 },
            { lng: 0.0502931, lat: 38.7208197 },
            { lng: 0.0493017, lat: 38.7207645 },
            { lng: 0.0486462, lat: 38.7207636 },
            { lng: 0.0475266, lat: 38.720937 },
            { lng: 0.0464736, lat: 38.7210546 },
            { lng: 0.0460371, lat: 38.7210659 },
            { lng: 0.0455182, lat: 38.7210344 },
            { lng: 0.0450863, lat: 38.7208835 },
            { lng: 0.0447953, lat: 38.720801 },
            { lng: 0.0435971, lat: 38.7221025 },
            { lng: 0.0433398, lat: 38.7222714 },
            { lng: 0.0429454, lat: 38.7224619 },
            { lng: 0.042631, lat: 38.7226412 },
            { lng: 0.0423664, lat: 38.7229094 },
            { lng: 0.042204, lat: 38.7231479 },
            { lng: 0.042024, lat: 38.7232426 },
            { lng: 0.04146, lat: 38.7233921 },
            { lng: 0.0391946, lat: 38.7237758 },
            { lng: 0.0383223, lat: 38.7240343 },
            { lng: 0.0381951, lat: 38.724072 },
            { lng: 0.037492, lat: 38.7243066 },
            { lng: 0.0369613, lat: 38.7242663 },
            { lng: 0.0361177, lat: 38.7241711 },
            { lng: 0.035322, lat: 38.7241197 },
            { lng: 0.0345332, lat: 38.7241651 },
            { lng: 0.0343899, lat: 38.7241079 },
            { lng: 0.0337837, lat: 38.7241867 },
            { lng: 0.0338303, lat: 38.7250144 },
            { lng: 0.0328521, lat: 38.7258146 },
            { lng: 0.0324423, lat: 38.7261856 },
            { lng: 0.0322281, lat: 38.7265605 },
            { lng: 0.031532, lat: 38.7280471 },
            { lng: 0.0305537, lat: 38.7291175 },
            { lng: 0.0305631, lat: 38.7301533 },
            { lng: 0.0315969, lat: 38.7309373 },
            { lng: 0.0328764, lat: 38.7312734 },
            { lng: 0.0323064, lat: 38.7319369 },
            { lng: 0.0337264, lat: 38.7345036 },
            { lng: 0.0329463, lat: 38.7342806 },
            { lng: 0.0321332, lat: 38.7340946 },
            { lng: 0.0315423, lat: 38.7339928 },
            { lng: 0.0308843, lat: 38.7339378 },
            { lng: 0.030437, lat: 38.7339674 },
            { lng: 0.0300257, lat: 38.7340321 },
            { lng: 0.029753, lat: 38.73393 },
            { lng: 0.0282969, lat: 38.7321389 },
            { lng: 0.0269881, lat: 38.7297494 },
            { lng: 0.0261293, lat: 38.7279338 },
            { lng: 0.0258825, lat: 38.7269943 },
            { lng: 0.0234879, lat: 38.724759 },
            { lng: 0.0215274, lat: 38.7243593 },
            { lng: 0.0210335, lat: 38.7219216 },
            { lng: 0.0193821, lat: 38.7214868 },
            { lng: 0.0190034, lat: 38.7215056 },
            { lng: 0.0183218, lat: 38.7214331 },
            { lng: 0.0176449, lat: 38.7211984 },
            { lng: 0.0172808, lat: 38.7210186 },
            { lng: 0.0165361, lat: 38.7208126 },
            { lng: 0.015711, lat: 38.7206087 },
            { lng: 0.0145321, lat: 38.720468 },
            { lng: 0.0140278, lat: 38.720508 },
            { lng: 0.0136942, lat: 38.7205076 },
            { lng: 0.0134626, lat: 38.7204685 },
            { lng: 0.0111857, lat: 38.7177345 },
            { lng: 0.010284, lat: 38.7168027 },
            { lng: 0.0098303, lat: 38.7161298 },
            { lng: 0.0091758, lat: 38.7150565 },
            { lng: 0.0087734, lat: 38.7142381 },
            { lng: 0.0084666, lat: 38.7137775 },
            { lng: 0.0078881, lat: 38.7131437 },
            { lng: 0.0076125, lat: 38.7128806 },
            { lng: 0.0075389, lat: 38.7124951 },
            { lng: 0.0074204, lat: 38.7121378 },
            { lng: 0.0068055, lat: 38.7114599 },
            { lng: 0.0064658, lat: 38.7110362 },
            { lng: 0.0062336, lat: 38.7107088 },
            { lng: 0.0060603, lat: 38.710416 },
            { lng: 0.0059484, lat: 38.7099414 },
            { lng: 0.0054569, lat: 38.7091883 },
            { lng: 0.0038983, lat: 38.7073905 },
            { lng: 0.003092, lat: 38.7068077 },
            { lng: 0.0027301, lat: 38.7064025 },
            { lng: 0.002449, lat: 38.7060044 },
            { lng: 0.002237, lat: 38.7057873 },
            { lng: 0.0022223, lat: 38.7055327 },
            { lng: 0.0011101, lat: 38.7045072 },
            { lng: 0.0006712, lat: 38.7041852 },
            { lng: 0.0003773, lat: 38.7040306 },
            { lng: -0.0001982, lat: 38.7031895 },
            { lng: -0.0012605, lat: 38.7014329 },
            { lng: -0.0018064, lat: 38.700474 },
            { lng: -0.0031666, lat: 38.6995629 },
            { lng: -0.0053144, lat: 38.6979603 },
            { lng: -0.0059167, lat: 38.6975794 },
            { lng: -0.0063758, lat: 38.6973209 },
            { lng: -0.0075717, lat: 38.6964866 },
            { lng: -0.0082547, lat: 38.6960987 },
            { lng: -0.0078889, lat: 38.695774 },
            { lng: -0.0074952, lat: 38.6955657 },
            { lng: -0.0071727, lat: 38.6953052 },
            { lng: -0.0069865, lat: 38.6950842 },
            { lng: -0.0070438, lat: 38.6945361 },
            { lng: -0.0074123, lat: 38.693969 },
            { lng: -0.0079744, lat: 38.6931726 },
            { lng: -0.0085345, lat: 38.6924211 },
            { lng: -0.0091149, lat: 38.6917333 },
            { lng: -0.0091111, lat: 38.6912737 },
            { lng: -0.0092729, lat: 38.6907013 },
            { lng: -0.0094387, lat: 38.6903091 },
            { lng: -0.010148, lat: 38.689012 },
            { lng: -0.0105269, lat: 38.6881928 },
            { lng: -0.011189, lat: 38.6872007 },
            { lng: -0.0118975, lat: 38.6864711 },
            { lng: -0.0123689, lat: 38.6859156 },
            { lng: -0.0128282, lat: 38.6850985 },
            { lng: -0.0129132, lat: 38.6847133 },
            { lng: -0.0128321, lat: 38.6830715 },
            { lng: -0.0126766, lat: 38.6821126 },
            { lng: -0.0125982, lat: 38.6812367 },
            { lng: -0.0124343, lat: 38.680755 },
            { lng: -0.0122204, lat: 38.6803711 },
            { lng: -0.012085, lat: 38.6800343 },
            { lng: -0.0120179, lat: 38.679438 },
            { lng: -0.0120974, lat: 38.6789085 },
            { lng: -0.0121924, lat: 38.6785596 },
            { lng: -0.0123162, lat: 38.6783465 },
            { lng: -0.0154585, lat: 38.6778225 },
            { lng: -0.0195064, lat: 38.6771498 },
            { lng: -0.0208919, lat: 38.6769168 },
            { lng: -0.0216693, lat: 38.6767385 },
            { lng: -0.0244614, lat: 38.678143 },
            { lng: -0.0309036, lat: 38.6715061 },
            { lng: -0.0364117, lat: 38.6666909 },
            { lng: -0.0403815, lat: 38.6637735 },
            { lng: -0.0443928, lat: 38.660956 }
        ]
    ];
});
