define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.calpe = void 0;
    exports.calpe = [
        [
            { lng: -0.0066777, lat: 38.6434009 },
            { lng: -0.0064122, lat: 38.6427552 },
            { lng: -0.0056981, lat: 38.6416054 },
            { lng: -0.0039178, lat: 38.6402148 },
            { lng: -0.0013745, lat: 38.6389787 },
            { lng: 0.0029208, lat: 38.6379413 },
            { lng: 0.0040229, lat: 38.6378751 },
            { lng: 0.0065661, lat: 38.6372451 },
            { lng: 0.0066132, lat: 38.6368321 },
            { lng: 0.0065931, lat: 38.6366173 },
            { lng: 0.0065448, lat: 38.6365053 },
            { lng: 0.0064643, lat: 38.6364078 },
            { lng: 0.0062958, lat: 38.6362344 },
            { lng: 0.0062229, lat: 38.6360778 },
            { lng: 0.0062042, lat: 38.635905 },
            { lng: 0.0062108, lat: 38.6357615 },
            { lng: 0.0062251, lat: 38.6356523 },
            { lng: 0.0063697, lat: 38.6353302 },
            { lng: 0.0064425, lat: 38.6350221 },
            { lng: 0.006592, lat: 38.6343899 },
            { lng: 0.0074432, lat: 38.633922 },
            { lng: 0.0078328, lat: 38.6338908 },
            { lng: 0.0089903, lat: 38.633182 },
            { lng: 0.0099208, lat: 38.6335978 },
            { lng: 0.0110115, lat: 38.6332175 },
            { lng: 0.0109811, lat: 38.6327118 },
            { lng: 0.0109952, lat: 38.6323551 },
            { lng: 0.0109475, lat: 38.6319467 },
            { lng: 0.0136518, lat: 38.6309728 },
            { lng: 0.0145531, lat: 38.6307382 },
            { lng: 0.0156903, lat: 38.6303023 },
            { lng: 0.0164628, lat: 38.6302521 },
            { lng: 0.0174928, lat: 38.6299671 },
            { lng: 0.018909, lat: 38.6294642 },
            { lng: 0.0212264, lat: 38.6286596 },
            { lng: 0.0226211, lat: 38.628207 },
            { lng: 0.0229216, lat: 38.6282741 },
            { lng: 0.0238228, lat: 38.6280561 },
            { lng: 0.0248742, lat: 38.6278885 },
            { lng: 0.0257111, lat: 38.6278885 },
            { lng: 0.0268912, lat: 38.6268492 },
            { lng: 0.027535, lat: 38.6266983 },
            { lng: 0.0280714, lat: 38.6262122 },
            { lng: 0.0284147, lat: 38.6258266 },
            { lng: 0.0290584, lat: 38.625726 },
            { lng: 0.0300884, lat: 38.625944 },
            { lng: 0.0304317, lat: 38.625944 },
            { lng: 0.0306678, lat: 38.6260948 },
            { lng: 0.0303674, lat: 38.6262457 },
            { lng: 0.0305176, lat: 38.6263798 },
            { lng: 0.0307107, lat: 38.6266815 },
            { lng: 0.0308609, lat: 38.6269833 },
            { lng: 0.0302172, lat: 38.6274359 },
            { lng: 0.0297022, lat: 38.6279556 },
            { lng: 0.0293803, lat: 38.6283243 },
            { lng: 0.0297236, lat: 38.6284584 },
            { lng: 0.0302815, lat: 38.6283746 },
            { lng: 0.030818, lat: 38.6284752 },
            { lng: 0.0312223, lat: 38.6286854 },
            { lng: 0.0310493, lat: 38.628927 },
            { lng: 0.0308347, lat: 38.6292622 },
            { lng: 0.0305858, lat: 38.6294902 },
            { lng: 0.0304656, lat: 38.6299998 },
            { lng: 0.030397, lat: 38.6304959 },
            { lng: 0.0304484, lat: 38.6310592 },
            { lng: 0.0307746, lat: 38.6314346 },
            { lng: 0.0312381, lat: 38.6315888 },
            { lng: 0.0315127, lat: 38.6317296 },
            { lng: 0.0318647, lat: 38.6322325 },
            { lng: 0.0323882, lat: 38.6324806 },
            { lng: 0.0328689, lat: 38.6325745 },
            { lng: 0.0333238, lat: 38.6329097 },
            { lng: 0.0335192, lat: 38.6330022 },
            { lng: 0.0338903, lat: 38.6331779 },
            { lng: 0.0343663, lat: 38.6335711 },
            { lng: 0.0349543, lat: 38.6336535 },
            { lng: 0.0351359, lat: 38.6338192 },
            { lng: 0.0351642, lat: 38.6342282 },
            { lng: 0.0353032, lat: 38.6346535 },
            { lng: 0.0359546, lat: 38.6351996 },
            { lng: 0.035941, lat: 38.6352443 },
            { lng: 0.0358745, lat: 38.6352755 },
            { lng: 0.0357897, lat: 38.6352729 },
            { lng: 0.0357195, lat: 38.6352211 },
            { lng: 0.0357098, lat: 38.6351559 },
            { lng: 0.035168, lat: 38.6348002 },
            { lng: 0.035079, lat: 38.6347774 },
            { lng: 0.0349291, lat: 38.6346688 },
            { lng: 0.0350412, lat: 38.6345727 },
            { lng: 0.0348681, lat: 38.6339783 },
            { lng: 0.0348119, lat: 38.6339263 },
            { lng: 0.03434, lat: 38.6338835 },
            { lng: 0.034122, lat: 38.6341811 },
            { lng: 0.0339746, lat: 38.6343824 },
            { lng: 0.0338135, lat: 38.6348806 },
            { lng: 0.0337841, lat: 38.6349868 },
            { lng: 0.0339906, lat: 38.6349918 },
            { lng: 0.0340039, lat: 38.6349921 },
            { lng: 0.0341601, lat: 38.6350473 },
            { lng: 0.0342938, lat: 38.6349809 },
            { lng: 0.034405, lat: 38.6350651 },
            { lng: 0.0348269, lat: 38.6347431 },
            { lng: 0.0349883, lat: 38.6348956 },
            { lng: 0.0349566, lat: 38.6349357 },
            { lng: 0.03511, lat: 38.6350881 },
            { lng: 0.0351916, lat: 38.6351317 },
            { lng: 0.0351856, lat: 38.6351846 },
            { lng: 0.035102, lat: 38.6351659 },
            { lng: 0.0349053, lat: 38.6349911 },
            { lng: 0.0346365, lat: 38.6352045 },
            { lng: 0.0345694, lat: 38.635849 },
            { lng: 0.0348677, lat: 38.6361725 },
            { lng: 0.0353328, lat: 38.6363599 },
            { lng: 0.0355232, lat: 38.6364456 },
            { lng: 0.0357552, lat: 38.6364985 },
            { lng: 0.0360301, lat: 38.6365242 },
            { lng: 0.0365845, lat: 38.6364637 },
            { lng: 0.0372988, lat: 38.636416 },
            { lng: 0.0375585, lat: 38.6365425 },
            { lng: 0.0378814, lat: 38.6366039 },
            { lng: 0.0385852, lat: 38.636738 },
            { lng: 0.0390058, lat: 38.636852 },
            { lng: 0.0393491, lat: 38.6371269 },
            { lng: 0.039701, lat: 38.6377906 },
            { lng: 0.040113, lat: 38.6383135 },
            { lng: 0.0404477, lat: 38.638575 },
            { lng: 0.040937, lat: 38.6387158 },
            { lng: 0.041792, lat: 38.638755 },
            { lng: 0.042645, lat: 38.6393125 },
            { lng: 0.0434145, lat: 38.6398757 },
            { lng: 0.0435873, lat: 38.6398952 },
            { lng: 0.0440856, lat: 38.6394124 },
            { lng: 0.0441642, lat: 38.6394063 },
            { lng: 0.0441225, lat: 38.6394951 },
            { lng: 0.0437922, lat: 38.63984 },
            { lng: 0.0441905, lat: 38.6402125 },
            { lng: 0.044994, lat: 38.640505 },
            { lng: 0.045316, lat: 38.640641 },
            { lng: 0.0459323, lat: 38.6407471 },
            { lng: 0.046614, lat: 38.640931 },
            { lng: 0.0472574, lat: 38.6409864 },
            { lng: 0.0489707, lat: 38.6413706 },
            { lng: 0.0503097, lat: 38.6415449 },
            { lng: 0.0506182, lat: 38.6413748 },
            { lng: 0.0512029, lat: 38.6413812 },
            { lng: 0.0515864, lat: 38.6415274 },
            { lng: 0.0521077, lat: 38.6416025 },
            { lng: 0.0523491, lat: 38.6415469 },
            { lng: 0.0526143, lat: 38.6415723 },
            { lng: 0.0533385, lat: 38.6415346 },
            { lng: 0.0539206, lat: 38.6416163 },
            { lng: 0.0551004, lat: 38.6414643 },
            { lng: 0.0572891, lat: 38.6414107 },
            { lng: 0.0578538, lat: 38.6412666 },
            { lng: 0.0584217, lat: 38.6410453 },
            { lng: 0.0583241, lat: 38.6406775 },
            { lng: 0.0584379, lat: 38.640651 },
            { lng: 0.0586149, lat: 38.6412349 },
            { lng: 0.0589041, lat: 38.6411872 },
            { lng: 0.0590304, lat: 38.6411929 },
            { lng: 0.0590871, lat: 38.6412461 },
            { lng: 0.0591848, lat: 38.6413531 },
            { lng: 0.0591686, lat: 38.6414548 },
            { lng: 0.0593416, lat: 38.6414726 },
            { lng: 0.0595043, lat: 38.6414149 },
            { lng: 0.0596044, lat: 38.641327 },
            { lng: 0.0596594, lat: 38.6411489 },
            { lng: 0.0595669, lat: 38.6411437 },
            { lng: 0.0597267, lat: 38.6410286 },
            { lng: 0.0624696, lat: 38.6407296 },
            { lng: 0.0627136, lat: 38.6409749 },
            { lng: 0.0629779, lat: 38.6410427 },
            { lng: 0.0631313, lat: 38.6409157 },
            { lng: 0.0629958, lat: 38.6407518 },
            { lng: 0.0630073, lat: 38.6405158 },
            { lng: 0.0631369, lat: 38.6404636 },
            { lng: 0.0632862, lat: 38.6405318 },
            { lng: 0.0633552, lat: 38.6403074 },
            { lng: 0.0635994, lat: 38.6402571 },
            { lng: 0.0638382, lat: 38.6403052 },
            { lng: 0.0644114, lat: 38.6403547 },
            { lng: 0.0646948, lat: 38.6403168 },
            { lng: 0.0649015, lat: 38.6404185 },
            { lng: 0.0653817, lat: 38.6404746 },
            { lng: 0.0656028, lat: 38.6405604 },
            { lng: 0.065944, lat: 38.640562 },
            { lng: 0.0661915, lat: 38.6404768 },
            { lng: 0.06637, lat: 38.6402375 },
            { lng: 0.0668746, lat: 38.6404082 },
            { lng: 0.0672712, lat: 38.640338 },
            { lng: 0.067676, lat: 38.6401618 },
            { lng: 0.0678893, lat: 38.6398233 },
            { lng: 0.0682044, lat: 38.6398868 },
            { lng: 0.0685442, lat: 38.6396449 },
            { lng: 0.0685336, lat: 38.6395095 },
            { lng: 0.0684255, lat: 38.6394998 },
            { lng: 0.0678869, lat: 38.6384514 },
            { lng: 0.0679065, lat: 38.6383931 },
            { lng: 0.0684958, lat: 38.6382253 },
            { lng: 0.0685744, lat: 38.6381776 },
            { lng: 0.0691427, lat: 38.6370289 },
            { lng: 0.0691914, lat: 38.6369676 },
            { lng: 0.0692414, lat: 38.636948 },
            { lng: 0.0693046, lat: 38.636949 },
            { lng: 0.0693627, lat: 38.6369641 },
            { lng: 0.0694331, lat: 38.6370294 },
            { lng: 0.0694568, lat: 38.6370785 },
            { lng: 0.0694539, lat: 38.6371497 },
            { lng: 0.0694846, lat: 38.6371612 },
            { lng: 0.0688197, lat: 38.6384209 },
            { lng: 0.0691167, lat: 38.6391365 },
            { lng: 0.0703359, lat: 38.6389874 },
            { lng: 0.0703269, lat: 38.63893 },
            { lng: 0.0704154, lat: 38.6389553 },
            { lng: 0.0703806, lat: 38.6390208 },
            { lng: 0.07085, lat: 38.6391733 },
            { lng: 0.0711513, lat: 38.6390119 },
            { lng: 0.0717334, lat: 38.6379062 },
            { lng: 0.0713895, lat: 38.637796 },
            { lng: 0.0715038, lat: 38.6375768 },
            { lng: 0.0713188, lat: 38.6375183 },
            { lng: 0.0713314, lat: 38.6374973 },
            { lng: 0.0714949, lat: 38.6375562 },
            { lng: 0.071522, lat: 38.63751 },
            { lng: 0.0713315, lat: 38.637447 },
            { lng: 0.0715213, lat: 38.6370791 },
            { lng: 0.0709905, lat: 38.6369084 },
            { lng: 0.0709816, lat: 38.6369237 },
            { lng: 0.0708124, lat: 38.6368792 },
            { lng: 0.0708422, lat: 38.6367217 },
            { lng: 0.0704273, lat: 38.6366649 },
            { lng: 0.0704318, lat: 38.6366383 },
            { lng: 0.0708514, lat: 38.6366917 },
            { lng: 0.0708608, lat: 38.6366392 },
            { lng: 0.0706666, lat: 38.6366159 },
            { lng: 0.0706711, lat: 38.6365908 },
            { lng: 0.0704462, lat: 38.6365604 },
            { lng: 0.070449, lat: 38.6365276 },
            { lng: 0.0705323, lat: 38.6365372 },
            { lng: 0.0706373, lat: 38.6362606 },
            { lng: 0.069823, lat: 38.6360569 },
            { lng: 0.0697635, lat: 38.635889 },
            { lng: 0.0686417, lat: 38.6361595 },
            { lng: 0.0684252, lat: 38.6360454 },
            { lng: 0.0675891, lat: 38.636176 },
            { lng: 0.0674401, lat: 38.6361662 },
            { lng: 0.067294, lat: 38.6361005 },
            { lng: 0.0672427, lat: 38.6359927 },
            { lng: 0.0672789, lat: 38.6359178 },
            { lng: 0.067428, lat: 38.6358596 },
            { lng: 0.0685643, lat: 38.6357366 },
            { lng: 0.0688273, lat: 38.6358838 },
            { lng: 0.0693169, lat: 38.6357774 },
            { lng: 0.069419, lat: 38.6356954 },
            { lng: 0.0697578, lat: 38.6356224 },
            { lng: 0.070075, lat: 38.635616 },
            { lng: 0.0706921, lat: 38.6357747 },
            { lng: 0.0708206, lat: 38.6357453 },
            { lng: 0.071074, lat: 38.6356364 },
            { lng: 0.071298, lat: 38.635504 },
            { lng: 0.071401, lat: 38.6354114 },
            { lng: 0.0714374, lat: 38.6352649 },
            { lng: 0.0713454, lat: 38.6345169 },
            { lng: 0.0714999, lat: 38.6340677 },
            { lng: 0.0714484, lat: 38.6335716 },
            { lng: 0.0716372, lat: 38.6331894 },
            { lng: 0.0725985, lat: 38.632834 },
            { lng: 0.0732852, lat: 38.6324988 },
            { lng: 0.0741435, lat: 38.6324318 },
            { lng: 0.0748301, lat: 38.6326329 },
            { lng: 0.0754738, lat: 38.6325726 },
            { lng: 0.075759, lat: 38.632677 },
            { lng: 0.07683, lat: 38.6324318 },
            { lng: 0.0785552, lat: 38.6318954 },
            { lng: 0.0794478, lat: 38.6318283 },
            { lng: 0.0807438, lat: 38.6312249 },
            { lng: 0.0820056, lat: 38.6308293 },
            { lng: 0.082675, lat: 38.6308628 },
            { lng: 0.0827094, lat: 38.6310707 },
            { lng: 0.0830699, lat: 38.6311444 },
            { lng: 0.083681, lat: 38.631077 },
            { lng: 0.083808, lat: 38.6311444 },
            { lng: 0.0834818, lat: 38.6313992 },
            { lng: 0.0828381, lat: 38.6318149 },
            { lng: 0.0824862, lat: 38.6325055 },
            { lng: 0.082323, lat: 38.6328185 },
            { lng: 0.0819602, lat: 38.6332929 },
            { lng: 0.0815628, lat: 38.6335242 },
            { lng: 0.0812105, lat: 38.6338111 },
            { lng: 0.0810004, lat: 38.6342318 },
            { lng: 0.0807715, lat: 38.634689 },
            { lng: 0.0801945, lat: 38.6348723 },
            { lng: 0.0793783, lat: 38.6350599 },
            { lng: 0.0788384, lat: 38.6352276 },
            { lng: 0.0782712, lat: 38.6354947 },
            { lng: 0.0782552, lat: 38.6355762 },
            { lng: 0.0778558, lat: 38.6355712 },
            { lng: 0.0774689, lat: 38.6357329 },
            { lng: 0.0770203, lat: 38.6358032 },
            { lng: 0.0766024, lat: 38.6363909 },
            { lng: 0.076466, lat: 38.6366918 },
            { lng: 0.0762545, lat: 38.6367841 },
            { lng: 0.076129, lat: 38.636861 },
            { lng: 0.0760004, lat: 38.6369069 },
            { lng: 0.0758321, lat: 38.6371869 },
            { lng: 0.0756412, lat: 38.6376808 },
            { lng: 0.0757118, lat: 38.6381081 },
            { lng: 0.075883, lat: 38.6381266 },
            { lng: 0.0759271, lat: 38.638277 },
            { lng: 0.0762641, lat: 38.6385183 },
            { lng: 0.0763066, lat: 38.6385602 },
            { lng: 0.0761237, lat: 38.6385465 },
            { lng: 0.0760716, lat: 38.6386061 },
            { lng: 0.0760154, lat: 38.6390792 },
            { lng: 0.0759352, lat: 38.6392202 },
            { lng: 0.0757399, lat: 38.6392972 },
            { lng: 0.0751734, lat: 38.6399676 },
            { lng: 0.0752078, lat: 38.6402626 },
            { lng: 0.0754662, lat: 38.6405443 },
            { lng: 0.0755339, lat: 38.6406246 },
            { lng: 0.0754553, lat: 38.6407298 },
            { lng: 0.0753794, lat: 38.6408592 },
            { lng: 0.0751388, lat: 38.6409372 },
            { lng: 0.0750447, lat: 38.6410268 },
            { lng: 0.0746842, lat: 38.6410939 },
            { lng: 0.074075, lat: 38.6409272 },
            { lng: 0.0737412, lat: 38.640899 },
            { lng: 0.0734663, lat: 38.6410035 },
            { lng: 0.0732402, lat: 38.6412731 },
            { lng: 0.0730212, lat: 38.6417194 },
            { lng: 0.0729348, lat: 38.6422406 },
            { lng: 0.0729671, lat: 38.642664 },
            { lng: 0.0731664, lat: 38.6434421 },
            { lng: 0.073261, lat: 38.6440221 },
            { lng: 0.0736413, lat: 38.6451478 },
            { lng: 0.0740146, lat: 38.6459395 },
            { lng: 0.0742924, lat: 38.6464545 },
            { lng: 0.0745961, lat: 38.6473316 },
            { lng: 0.0749461, lat: 38.6480344 },
            { lng: 0.0751791, lat: 38.6484547 },
            { lng: 0.075473, lat: 38.6486733 },
            { lng: 0.0760963, lat: 38.6488595 },
            { lng: 0.076204, lat: 38.6488918 },
            { lng: 0.0763323, lat: 38.6489994 },
            { lng: 0.0765599, lat: 38.6490264 },
            { lng: 0.076783, lat: 38.6490134 },
            { lng: 0.0774651, lat: 38.6493996 },
            { lng: 0.0777483, lat: 38.6497951 },
            { lng: 0.0779629, lat: 38.6502308 },
            { lng: 0.0785643, lat: 38.6507274 },
            { lng: 0.0787423, lat: 38.6509918 },
            { lng: 0.0789977, lat: 38.6512384 },
            { lng: 0.078805, lat: 38.6512664 },
            { lng: 0.0786678, lat: 38.6513665 },
            { lng: 0.0780744, lat: 38.6512795 },
            { lng: 0.0777948, lat: 38.6514393 },
            { lng: 0.0775681, lat: 38.6516452 },
            { lng: 0.0775939, lat: 38.6520474 },
            { lng: 0.0774737, lat: 38.6523155 },
            { lng: 0.0776874, lat: 38.6527861 },
            { lng: 0.0776454, lat: 38.6530059 },
            { lng: 0.0778184, lat: 38.6532293 },
            { lng: 0.0779028, lat: 38.6533477 },
            { lng: 0.0786753, lat: 38.6538504 },
            { lng: 0.0798512, lat: 38.6545676 },
            { lng: 0.080246, lat: 38.6553116 },
            { lng: 0.080349, lat: 38.6558478 },
            { lng: 0.0802203, lat: 38.6561562 },
            { lng: 0.0802804, lat: 38.6562902 },
            { lng: 0.0805979, lat: 38.6562902 },
            { lng: 0.0810528, lat: 38.6565114 },
            { lng: 0.0812846, lat: 38.6565248 },
            { lng: 0.0812932, lat: 38.6566455 },
            { lng: 0.0815592, lat: 38.6566991 },
            { lng: 0.0817738, lat: 38.6569672 },
            { lng: 0.0816279, lat: 38.6572085 },
            { lng: 0.0817309, lat: 38.6574363 },
            { lng: 0.082512, lat: 38.6579189 },
            { lng: 0.0823403, lat: 38.6581937 },
            { lng: 0.0824605, lat: 38.6584417 },
            { lng: 0.083499, lat: 38.6590382 },
            { lng: 0.0842114, lat: 38.6592058 },
            { lng: 0.0845377, lat: 38.6592464 },
            { lng: 0.0844864, lat: 38.6593081 },
            { lng: 0.0848319, lat: 38.659641 },
            { lng: 0.0848434, lat: 38.6596521 },
            { lng: 0.084658, lat: 38.6598769 },
            { lng: 0.0845801, lat: 38.6600624 },
            { lng: 0.0846163, lat: 38.6601688 },
            { lng: 0.0846662, lat: 38.6603151 },
            { lng: 0.0845163, lat: 38.6605097 },
            { lng: 0.0843334, lat: 38.660784 },
            { lng: 0.0843958, lat: 38.6608334 },
            { lng: 0.0843179, lat: 38.6610125 },
            { lng: 0.0842606, lat: 38.661358 },
            { lng: 0.084277, lat: 38.6617515 },
            { lng: 0.0841746, lat: 38.6620203 },
            { lng: 0.0842114, lat: 38.6622378 },
            { lng: 0.0841377, lat: 38.6623146 },
            { lng: 0.0837444, lat: 38.6621898 },
            { lng: 0.0834699, lat: 38.6619211 },
            { lng: 0.0832159, lat: 38.6618539 },
            { lng: 0.082787, lat: 38.6617937 },
            { lng: 0.0823901, lat: 38.6616987 },
            { lng: 0.0820764, lat: 38.6617087 },
            { lng: 0.0816988, lat: 38.6616788 },
            { lng: 0.0815131, lat: 38.6617337 },
            { lng: 0.0801581, lat: 38.6628196 },
            { lng: 0.0800536, lat: 38.6629034 },
            { lng: 0.0794199, lat: 38.6631783 },
            { lng: 0.0792697, lat: 38.6633179 },
            { lng: 0.0791491, lat: 38.6634301 },
            { lng: 0.0790394, lat: 38.6635323 },
            { lng: 0.0789355, lat: 38.663655 },
            { lng: 0.0787905, lat: 38.6637911 },
            { lng: 0.078754, lat: 38.6638301 },
            { lng: 0.0786922, lat: 38.6638959 },
            { lng: 0.0786797, lat: 38.6639094 },
            { lng: 0.078672, lat: 38.6639175 },
            { lng: 0.078549, lat: 38.6640382 },
            { lng: 0.0783508, lat: 38.6642329 },
            { lng: 0.0778619, lat: 38.6645516 },
            { lng: 0.0778067, lat: 38.6645878 },
            { lng: 0.0774838, lat: 38.6647047 },
            { lng: 0.0774482, lat: 38.6647178 },
            { lng: 0.0770753, lat: 38.664948 },
            { lng: 0.0768492, lat: 38.6650351 },
            { lng: 0.0767093, lat: 38.6650889 },
            { lng: 0.0765986, lat: 38.6651431 },
            { lng: 0.0765421, lat: 38.6651763 },
            { lng: 0.0765146, lat: 38.6651926 },
            { lng: 0.076467, lat: 38.6652307 },
            { lng: 0.0763981, lat: 38.6652899 },
            { lng: 0.0763437, lat: 38.6653439 },
            { lng: 0.0761934, lat: 38.6654923 },
            { lng: 0.0761628, lat: 38.6655159 },
            { lng: 0.0759665, lat: 38.6656665 },
            { lng: 0.0758766, lat: 38.6657355 },
            { lng: 0.0757446, lat: 38.6658551 },
            { lng: 0.0754327, lat: 38.6660483 },
            { lng: 0.0753675, lat: 38.6660887 },
            { lng: 0.075224, lat: 38.6661776 },
            { lng: 0.0749694, lat: 38.6663351 },
            { lng: 0.0747885, lat: 38.6664916 },
            { lng: 0.0747684, lat: 38.6668175 },
            { lng: 0.0747423, lat: 38.6670084 },
            { lng: 0.0747364, lat: 38.6670083 },
            { lng: 0.0749363, lat: 38.6670891 },
            { lng: 0.0746444, lat: 38.6674118 },
            { lng: 0.074637, lat: 38.6674626 },
            { lng: 0.0746362, lat: 38.6674948 },
            { lng: 0.0746548, lat: 38.6675288 },
            { lng: 0.0746828, lat: 38.6675518 },
            { lng: 0.0748242, lat: 38.6676342 },
            { lng: 0.0747683, lat: 38.6676944 },
            { lng: 0.0750349, lat: 38.6678017 },
            { lng: 0.0747532, lat: 38.6680116 },
            { lng: 0.0749453, lat: 38.6682215 },
            { lng: 0.0752205, lat: 38.6688063 },
            { lng: 0.0750082, lat: 38.6703689 },
            { lng: 0.0749517, lat: 38.6707855 },
            { lng: 0.0748299, lat: 38.6709759 },
            { lng: 0.0722439, lat: 38.6750236 },
            { lng: 0.0692032, lat: 38.6775723 },
            { lng: 0.0693121, lat: 38.6782719 },
            { lng: 0.0683177, lat: 38.6805944 },
            { lng: 0.0643463, lat: 38.6799147 },
            { lng: 0.0598792, lat: 38.6805468 },
            { lng: 0.0547588, lat: 38.6788981 },
            { lng: 0.0505986, lat: 38.6788038 },
            { lng: 0.0493658, lat: 38.6769798 },
            { lng: 0.0495332, lat: 38.6751143 },
            { lng: 0.049537, lat: 38.6725539 },
            { lng: 0.0488216, lat: 38.6727678 },
            { lng: 0.0460516, lat: 38.6749066 },
            { lng: 0.0441796, lat: 38.674158 },
            { lng: 0.0421003, lat: 38.6761407 },
            { lng: 0.0411052, lat: 38.6770691 },
            { lng: 0.0407643, lat: 38.6771285 },
            { lng: 0.0392781, lat: 38.6778916 },
            { lng: 0.0389891, lat: 38.6779088 },
            { lng: 0.0386055, lat: 38.6775069 },
            { lng: 0.0384333, lat: 38.6772972 },
            { lng: 0.0383943, lat: 38.6772498 },
            { lng: 0.0383822, lat: 38.6772311 },
            { lng: 0.0383466, lat: 38.6771759 },
            { lng: 0.0383358, lat: 38.6771592 },
            { lng: 0.0383288, lat: 38.6771483 },
            { lng: 0.0383068, lat: 38.6771144 },
            { lng: 0.0382758, lat: 38.6770192 },
            { lng: 0.0382634, lat: 38.6768976 },
            { lng: 0.0382659, lat: 38.6767649 },
            { lng: 0.0382423, lat: 38.6766512 },
            { lng: 0.0383556, lat: 38.6756331 },
            { lng: 0.0384377, lat: 38.6745386 },
            { lng: 0.0384605, lat: 38.6742328 },
            { lng: 0.0412136, lat: 38.6709615 },
            { lng: 0.0420317, lat: 38.6698623 },
            { lng: 0.0442195, lat: 38.6672507 },
            { lng: 0.0438271, lat: 38.6668391 },
            { lng: 0.0436898, lat: 38.6664839 },
            { lng: 0.04087, lat: 38.6638773 },
            { lng: 0.0406817, lat: 38.6637034 },
            { lng: 0.0386712, lat: 38.6629733 },
            { lng: 0.0366062, lat: 38.6631071 },
            { lng: 0.0352269, lat: 38.6625838 },
            { lng: 0.0333735, lat: 38.6639312 },
            { lng: 0.0332765, lat: 38.663899 },
            { lng: 0.0309657, lat: 38.6631885 },
            { lng: 0.0294497, lat: 38.6624457 },
            { lng: 0.0257042, lat: 38.6601013 },
            { lng: 0.0203832, lat: 38.6572229 },
            { lng: 0.0169944, lat: 38.6548552 },
            { lng: 0.0116437, lat: 38.6527195 },
            { lng: 0.0092387, lat: 38.6520225 },
            { lng: 0.0013946, lat: 38.6492777 },
            { lng: 0.0010432, lat: 38.6492739 },
            { lng: 0.0004479, lat: 38.6493346 },
            { lng: 0.0001167, lat: 38.6493079 },
            { lng: -0.0007759, lat: 38.6485319 },
            { lng: -0.0066777, lat: 38.6434009 }
        ]
    ];
});
