define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.gataDeGorgos = void 0;
    exports.gataDeGorgos = [
        [
            { lng: 0.0336935, lat: 38.7635677 },
            { lng: 0.0376887, lat: 38.7663286 },
            { lng: 0.0379332, lat: 38.7674385 },
            { lng: 0.0395993, lat: 38.7749996 },
            { lng: 0.0408752, lat: 38.7754607 },
            { lng: 0.044633, lat: 38.7773024 },
            { lng: 0.0484936, lat: 38.7786445 },
            { lng: 0.0486616, lat: 38.7786962 },
            { lng: 0.0507381, lat: 38.7793367 },
            { lng: 0.0542848, lat: 38.7804328 },
            { lng: 0.0543552, lat: 38.7804463 },
            { lng: 0.0544548, lat: 38.7804698 },
            { lng: 0.0693001, lat: 38.7835199 },
            { lng: 0.069253, lat: 38.7848364 },
            { lng: 0.0692049, lat: 38.7869368 },
            { lng: 0.0720311, lat: 38.7905651 },
            { lng: 0.0723206, lat: 38.7911431 },
            { lng: 0.0725899, lat: 38.7917846 },
            { lng: 0.0725172, lat: 38.792237 },
            { lng: 0.0726643, lat: 38.7929809 },
            { lng: 0.0726875, lat: 38.7935208 },
            { lng: 0.0727663, lat: 38.7935224 },
            { lng: 0.0765702, lat: 38.7935987 },
            { lng: 0.0790896, lat: 38.7928389 },
            { lng: 0.0824336, lat: 38.7918224 },
            { lng: 0.0845246, lat: 38.7922627 },
            { lng: 0.0856987, lat: 38.7917271 },
            { lng: 0.0866077, lat: 38.7914509 },
            { lng: 0.0874645, lat: 38.7912732 },
            { lng: 0.087703, lat: 38.7912237 },
            { lng: 0.0889044, lat: 38.7910568 },
            { lng: 0.089936, lat: 38.7906871 },
            { lng: 0.0908449, lat: 38.7904108 },
            { lng: 0.0913115, lat: 38.7900201 },
            { lng: 0.0918126, lat: 38.7896284 },
            { lng: 0.0925387, lat: 38.789384 },
            { lng: 0.0937774, lat: 38.7892791 },
            { lng: 0.0943501, lat: 38.7894801 },
            { lng: 0.0948772, lat: 38.7896914 },
            { lng: 0.0954306, lat: 38.7897128 },
            { lng: 0.0959108, lat: 38.789637 },
            { lng: 0.0963436, lat: 38.7892651 },
            { lng: 0.0966794, lat: 38.7887787 },
            { lng: 0.0971617, lat: 38.7884867 },
            { lng: 0.097698, lat: 38.7881121 },
            { lng: 0.0982454, lat: 38.7871967 },
            { lng: 0.0982971, lat: 38.7865286 },
            { lng: 0.0983669, lat: 38.7860133 },
            { lng: 0.0986609, lat: 38.7856271 },
            { lng: 0.0990707, lat: 38.7852559 },
            { lng: 0.0994595, lat: 38.7849302 },
            { lng: 0.0997588, lat: 38.7843998 },
            { lng: 0.0999352, lat: 38.7839537 },
            { lng: 0.1002791, lat: 38.7836562 },
            { lng: 0.1012072, lat: 38.783028 },
            { lng: 0.1014368, lat: 38.7829322 },
            { lng: 0.1017838, lat: 38.7827875 },
            { lng: 0.1024324, lat: 38.7826171 },
            { lng: 0.0997745, lat: 38.7751922 },
            { lng: 0.0986319, lat: 38.7727271 },
            { lng: 0.0968856, lat: 38.7714671 },
            { lng: 0.0952436, lat: 38.7675647 },
            { lng: 0.0946039, lat: 38.7676717 },
            { lng: 0.094563, lat: 38.7672584 },
            { lng: 0.094447, lat: 38.767014 },
            { lng: 0.0943477, lat: 38.7668047 },
            { lng: 0.0941921, lat: 38.7661331 },
            { lng: 0.094286, lat: 38.7656441 },
            { lng: 0.0945038, lat: 38.7653591 },
            { lng: 0.0943403, lat: 38.7650391 },
            { lng: 0.0942122, lat: 38.7647362 },
            { lng: 0.0939311, lat: 38.7643563 },
            { lng: 0.093567, lat: 38.7641857 },
            { lng: 0.09331, lat: 38.7638322 },
            { lng: 0.0928823, lat: 38.7635192 },
            { lng: 0.0923651, lat: 38.7635329 },
            { lng: 0.0920758, lat: 38.7634955 },
            { lng: 0.0916127, lat: 38.7631654 },
            { lng: 0.0909133, lat: 38.7621569 },
            { lng: 0.0904199, lat: 38.7616565 },
            { lng: 0.0908425, lat: 38.7611894 },
            { lng: 0.0898641, lat: 38.7600101 },
            { lng: 0.0899392, lat: 38.7591149 },
            { lng: 0.090053, lat: 38.7575277 },
            { lng: 0.090116, lat: 38.7565815 },
            { lng: 0.0901867, lat: 38.7552451 },
            { lng: 0.0902378, lat: 38.754439 },
            { lng: 0.0906684, lat: 38.7541866 },
            { lng: 0.091531, lat: 38.7536967 },
            { lng: 0.0920426, lat: 38.7529745 },
            { lng: 0.0925276, lat: 38.7522855 },
            { lng: 0.0926669, lat: 38.75133 },
            { lng: 0.0924401, lat: 38.7509475 },
            { lng: 0.0921632, lat: 38.7505008 },
            { lng: 0.0919176, lat: 38.7500939 },
            { lng: 0.0915016, lat: 38.7497995 },
            { lng: 0.0879619, lat: 38.747195 },
            { lng: 0.0875368, lat: 38.7469025 },
            { lng: 0.0869944, lat: 38.747054 },
            { lng: 0.0861039, lat: 38.7471862 },
            { lng: 0.0856008, lat: 38.7470362 },
            { lng: 0.0839354, lat: 38.7464712 },
            { lng: 0.0833206, lat: 38.7459814 },
            { lng: 0.0828377, lat: 38.7457309 },
            { lng: 0.0819581, lat: 38.7455065 },
            { lng: 0.0796764, lat: 38.7449948 },
            { lng: 0.0804209, lat: 38.7463809 },
            { lng: 0.0797048, lat: 38.7477433 },
            { lng: 0.0796458, lat: 38.7478246 },
            { lng: 0.0795635, lat: 38.7479274 },
            { lng: 0.0793519, lat: 38.7480787 },
            { lng: 0.0791252, lat: 38.7482635 },
            { lng: 0.0787857, lat: 38.7486404 },
            { lng: 0.0784727, lat: 38.7489851 },
            { lng: 0.0776064, lat: 38.7499874 },
            { lng: 0.0766362, lat: 38.7497717 },
            { lng: 0.0752508, lat: 38.7494746 },
            { lng: 0.0743848, lat: 38.7495384 },
            { lng: 0.0717031, lat: 38.7497729 },
            { lng: 0.0713096, lat: 38.749649 },
            { lng: 0.0710283, lat: 38.7495421 },
            { lng: 0.0706199, lat: 38.7494049 },
            { lng: 0.0705152, lat: 38.7493618 },
            { lng: 0.070322, lat: 38.7492888 },
            { lng: 0.0701492, lat: 38.7492279 },
            { lng: 0.0690302, lat: 38.7488349 },
            { lng: 0.0685694, lat: 38.7484383 },
            { lng: 0.0679008, lat: 38.7478573 },
            { lng: 0.0675364, lat: 38.7475236 },
            { lng: 0.0671384, lat: 38.7471786 },
            { lng: 0.0666182, lat: 38.7467248 },
            { lng: 0.0666166, lat: 38.7467341 },
            { lng: 0.0658318, lat: 38.7460415 },
            { lng: 0.0653755, lat: 38.7456681 },
            { lng: 0.0649426, lat: 38.745229 },
            { lng: 0.0645591, lat: 38.7449226 },
            { lng: 0.0642337, lat: 38.7450787 },
            { lng: 0.0637294, lat: 38.745323 },
            { lng: 0.063137, lat: 38.7456115 },
            { lng: 0.0623944, lat: 38.7459707 },
            { lng: 0.0616698, lat: 38.7463354 },
            { lng: 0.0609834, lat: 38.7466818 },
            { lng: 0.0596203, lat: 38.7473731 },
            { lng: 0.0587283, lat: 38.7477945 },
            { lng: 0.0578708, lat: 38.7482161 },
            { lng: 0.0574997, lat: 38.7483764 },
            { lng: 0.0564621, lat: 38.7488716 },
            { lng: 0.0556652, lat: 38.7492511 },
            { lng: 0.0550374, lat: 38.7495487 },
            { lng: 0.0550273, lat: 38.7495499 },
            { lng: 0.0543504, lat: 38.7496064 },
            { lng: 0.046881, lat: 38.7495395 },
            { lng: 0.0406389, lat: 38.7500492 },
            { lng: 0.0409775, lat: 38.753288 },
            { lng: 0.040215, lat: 38.7556502 },
            { lng: 0.0384273, lat: 38.7577148 },
            { lng: 0.0374542, lat: 38.7583708 },
            { lng: 0.0363154, lat: 38.7594634 },
            { lng: 0.0353824, lat: 38.760794 },
            { lng: 0.0348577, lat: 38.7619879 },
            { lng: 0.0336935, lat: 38.7635677 }
        ]
    ];
});
