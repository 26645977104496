define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.morairaZonePaichi = void 0;
    exports.morairaZonePaichi = [
        [
            { lng: 0.13352, lat: 38.69759 },
            { lng: 0.13359, lat: 38.69828 },
            { lng: 0.13425, lat: 38.70026 },
            { lng: 0.13542, lat: 38.7016 },
            { lng: 0.13578, lat: 38.70295 },
            { lng: 0.13589, lat: 38.70457 },
            { lng: 0.13578, lat: 38.70503 },
            { lng: 0.13554, lat: 38.7053 },
            { lng: 0.1348, lat: 38.70558 },
            { lng: 0.1347, lat: 38.70582 },
            { lng: 0.13494, lat: 38.70678 },
            { lng: 0.13461, lat: 38.70889 },
            { lng: 0.13516, lat: 38.70863 },
            { lng: 0.13563, lat: 38.70783 },
            { lng: 0.13667, lat: 38.70948 },
            { lng: 0.13716, lat: 38.71099 },
            { lng: 0.13719, lat: 38.71175 },
            { lng: 0.13787, lat: 38.71299 },
            { lng: 0.13783, lat: 38.71369 },
            { lng: 0.13883, lat: 38.71531 },
            { lng: 0.1397, lat: 38.71601 },
            { lng: 0.14215, lat: 38.7136 },
            { lng: 0.14506, lat: 38.7113 },
            { lng: 0.14627, lat: 38.71046 },
            { lng: 0.14756, lat: 38.70987 },
            { lng: 0.15098, lat: 38.70711 },
            { lng: 0.15154, lat: 38.70253 },
            { lng: 0.15322, lat: 38.7011 },
            { lng: 0.15337, lat: 38.69992 },
            { lng: 0.15441, lat: 38.69848 },
            { lng: 0.15568, lat: 38.69795 },
            { lng: 0.15142, lat: 38.69749 },
            { lng: 0.14982, lat: 38.6971 },
            { lng: 0.14684, lat: 38.69546 },
            { lng: 0.14594, lat: 38.69452 },
            { lng: 0.14514, lat: 38.69414 },
            { lng: 0.145, lat: 38.69375 },
            { lng: 0.14448, lat: 38.69369 },
            { lng: 0.1439, lat: 38.69329 },
            { lng: 0.14348, lat: 38.69325 },
            { lng: 0.14247, lat: 38.69259 },
            { lng: 0.1407, lat: 38.69321 },
            { lng: 0.14043, lat: 38.69475 },
            { lng: 0.14005, lat: 38.69541 },
            { lng: 0.13871, lat: 38.69647 },
            { lng: 0.13661, lat: 38.6973 },
            { lng: 0.13352, lat: 38.69759 }
        ]
    ];
});
