define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.javeaZonePuerto = void 0;
    exports.javeaZonePuerto = [
        [
            { lng: 0.16164, lat: 38.79244 },
            { lng: 0.16134, lat: 38.79356 },
            { lng: 0.16067, lat: 38.79384 },
            { lng: 0.16053, lat: 38.79449 },
            { lng: 0.16031, lat: 38.7944 },
            { lng: 0.16043, lat: 38.79403 },
            { lng: 0.16019, lat: 38.79363 },
            { lng: 0.16025, lat: 38.79314 },
            { lng: 0.15977, lat: 38.79289 },
            { lng: 0.15916, lat: 38.79319 },
            { lng: 0.15906, lat: 38.79384 },
            { lng: 0.15781, lat: 38.79409 },
            { lng: 0.15704, lat: 38.79455 },
            { lng: 0.15718, lat: 38.79631 },
            { lng: 0.1564, lat: 38.79792 },
            { lng: 0.15707, lat: 38.79868 },
            { lng: 0.15344, lat: 38.80533 },
            { lng: 0.15502, lat: 38.80543 },
            { lng: 0.15598, lat: 38.8059 },
            { lng: 0.15947, lat: 38.80925 },
            { lng: 0.16157, lat: 38.81089 },
            { lng: 0.16414, lat: 38.81394 },
            { lng: 0.16644, lat: 38.81753 },
            { lng: 0.1672, lat: 38.81765 },
            { lng: 0.16789, lat: 38.81745 },
            { lng: 0.16813, lat: 38.81769 },
            { lng: 0.16958, lat: 38.8165 },
            { lng: 0.16958, lat: 38.8159 },
            { lng: 0.17012, lat: 38.81578 },
            { lng: 0.17027, lat: 38.81535 },
            { lng: 0.17106, lat: 38.81504 },
            { lng: 0.17156, lat: 38.81532 },
            { lng: 0.17236, lat: 38.81508 },
            { lng: 0.17389, lat: 38.81385 },
            { lng: 0.1757, lat: 38.81347 },
            { lng: 0.17683, lat: 38.81355 },
            { lng: 0.17802, lat: 38.81294 },
            { lng: 0.17919, lat: 38.8127 },
            { lng: 0.17947, lat: 38.81281 },
            { lng: 0.17995, lat: 38.81234 },
            { lng: 0.1809, lat: 38.81248 },
            { lng: 0.18175, lat: 38.81238 },
            { lng: 0.18352, lat: 38.81116 },
            { lng: 0.1835, lat: 38.81092 },
            { lng: 0.18413, lat: 38.81118 },
            { lng: 0.18472, lat: 38.81112 },
            { lng: 0.18737, lat: 38.81005 },
            { lng: 0.18831, lat: 38.80937 },
            { lng: 0.18908, lat: 38.80939 },
            { lng: 0.19141, lat: 38.80875 },
            { lng: 0.19156, lat: 38.80863 },
            { lng: 0.1914, lat: 38.80807 },
            { lng: 0.19166, lat: 38.80777 },
            { lng: 0.19135, lat: 38.80751 },
            { lng: 0.19143, lat: 38.80727 },
            { lng: 0.19302, lat: 38.80648 },
            { lng: 0.19329, lat: 38.80657 },
            { lng: 0.19399, lat: 38.80625 },
            { lng: 0.19424, lat: 38.80601 },
            { lng: 0.194, lat: 38.80601 },
            { lng: 0.19402, lat: 38.80572 },
            { lng: 0.19469, lat: 38.80483 },
            { lng: 0.19699, lat: 38.80498 },
            { lng: 0.19797, lat: 38.80461 },
            { lng: 0.19957, lat: 38.80252 },
            { lng: 0.19921, lat: 38.80174 },
            { lng: 0.19795, lat: 38.80062 },
            { lng: 0.19794, lat: 38.80002 },
            { lng: 0.19757, lat: 38.80024 },
            { lng: 0.19706, lat: 38.79983 },
            { lng: 0.19574, lat: 38.8002 },
            { lng: 0.19537, lat: 38.79993 },
            { lng: 0.19471, lat: 38.80001 },
            { lng: 0.19384, lat: 38.79979 },
            { lng: 0.19291, lat: 38.7999 },
            { lng: 0.19262, lat: 38.79972 },
            { lng: 0.1913, lat: 38.80009 },
            { lng: 0.19002, lat: 38.7996 },
            { lng: 0.1897, lat: 38.7992 },
            { lng: 0.18864, lat: 38.79668 },
            { lng: 0.18751, lat: 38.79466 },
            { lng: 0.1873, lat: 38.79469 },
            { lng: 0.18722, lat: 38.79489 },
            { lng: 0.18748, lat: 38.79506 },
            { lng: 0.18802, lat: 38.79631 },
            { lng: 0.18814, lat: 38.79631 },
            { lng: 0.18889, lat: 38.79848 },
            { lng: 0.18855, lat: 38.79868 },
            { lng: 0.18752, lat: 38.79864 },
            { lng: 0.18761, lat: 38.79733 },
            { lng: 0.18733, lat: 38.79861 },
            { lng: 0.18688, lat: 38.79859 },
            { lng: 0.18698, lat: 38.79753 },
            { lng: 0.18676, lat: 38.7986 },
            { lng: 0.18643, lat: 38.79853 },
            { lng: 0.18658, lat: 38.79751 },
            { lng: 0.18638, lat: 38.79848 },
            { lng: 0.18618, lat: 38.79862 },
            { lng: 0.18596, lat: 38.79846 },
            { lng: 0.18602, lat: 38.79779 },
            { lng: 0.18585, lat: 38.79847 },
            { lng: 0.18557, lat: 38.79843 },
            { lng: 0.18568, lat: 38.7977 },
            { lng: 0.18551, lat: 38.79845 },
            { lng: 0.18529, lat: 38.79842 },
            { lng: 0.18526, lat: 38.79774 },
            { lng: 0.18518, lat: 38.79842 },
            { lng: 0.18504, lat: 38.79824 },
            { lng: 0.1851, lat: 38.79772 },
            { lng: 0.18494, lat: 38.79789 },
            { lng: 0.18494, lat: 38.79776 },
            { lng: 0.18463, lat: 38.7977 },
            { lng: 0.18478, lat: 38.79648 },
            { lng: 0.18537, lat: 38.79628 },
            { lng: 0.18625, lat: 38.79631 },
            { lng: 0.18643, lat: 38.7961 },
            { lng: 0.18523, lat: 38.79602 },
            { lng: 0.18429, lat: 38.79619 },
            { lng: 0.18353, lat: 38.79575 },
            { lng: 0.18242, lat: 38.79405 },
            { lng: 0.18241, lat: 38.79283 },
            { lng: 0.18261, lat: 38.79262 },
            { lng: 0.18236, lat: 38.79172 },
            { lng: 0.18197, lat: 38.7918 },
            { lng: 0.18186, lat: 38.79164 },
            { lng: 0.17955, lat: 38.79118 },
            { lng: 0.17776, lat: 38.79057 },
            { lng: 0.17577, lat: 38.79439 },
            { lng: 0.17393, lat: 38.79438 },
            { lng: 0.17207, lat: 38.79416 },
            { lng: 0.17011, lat: 38.79317 },
            { lng: 0.16813, lat: 38.79321 },
            { lng: 0.1659, lat: 38.7929 },
            { lng: 0.16578, lat: 38.79311 },
            { lng: 0.16365, lat: 38.79312 },
            { lng: 0.16275, lat: 38.79292 },
            { lng: 0.16164, lat: 38.79244 }
        ]
    ];
});
