define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.javeaZoneTosal = void 0;
    exports.javeaZoneTosal = [
        [
            { lng: 0.13701, lat: 38.77532 },
            { lng: 0.13699, lat: 38.77753 },
            { lng: 0.13754, lat: 38.78205 },
            { lng: 0.13797, lat: 38.7821 },
            { lng: 0.13795, lat: 38.78297 },
            { lng: 0.13809, lat: 38.78322 },
            { lng: 0.13749, lat: 38.7839 },
            { lng: 0.13775, lat: 38.78463 },
            { lng: 0.13728, lat: 38.78528 },
            { lng: 0.13746, lat: 38.78612 },
            { lng: 0.13666, lat: 38.78707 },
            { lng: 0.13681, lat: 38.78735 },
            { lng: 0.13713, lat: 38.7875 },
            { lng: 0.13731, lat: 38.78811 },
            { lng: 0.13757, lat: 38.78837 },
            { lng: 0.13913, lat: 38.78951 },
            { lng: 0.13938, lat: 38.78992 },
            { lng: 0.13994, lat: 38.79033 },
            { lng: 0.14094, lat: 38.79271 },
            { lng: 0.1416, lat: 38.80345 },
            { lng: 0.14462, lat: 38.80418 },
            { lng: 0.14951, lat: 38.80502 },
            { lng: 0.15344, lat: 38.80533 },
            { lng: 0.15707, lat: 38.79868 },
            { lng: 0.1564, lat: 38.79792 },
            { lng: 0.15718, lat: 38.79631 },
            { lng: 0.15704, lat: 38.79455 },
            { lng: 0.15781, lat: 38.79409 },
            { lng: 0.15906, lat: 38.79384 },
            { lng: 0.15916, lat: 38.79319 },
            { lng: 0.15965, lat: 38.79289 },
            { lng: 0.16025, lat: 38.79314 },
            { lng: 0.16019, lat: 38.79363 },
            { lng: 0.16043, lat: 38.79403 },
            { lng: 0.16031, lat: 38.79434 },
            { lng: 0.1604, lat: 38.7945 },
            { lng: 0.16061, lat: 38.79444 },
            { lng: 0.16067, lat: 38.79384 },
            { lng: 0.16123, lat: 38.79369 },
            { lng: 0.16138, lat: 38.79343 },
            { lng: 0.16164, lat: 38.79244 },
            { lng: 0.16154, lat: 38.7921 },
            { lng: 0.15771, lat: 38.79051 },
            { lng: 0.15729, lat: 38.78999 },
            { lng: 0.15712, lat: 38.78947 },
            { lng: 0.15702, lat: 38.78814 },
            { lng: 0.15818, lat: 38.78722 },
            { lng: 0.15958, lat: 38.7849 },
            { lng: 0.15727, lat: 38.78372 },
            { lng: 0.15592, lat: 38.78206 },
            { lng: 0.15468, lat: 38.7811 },
            { lng: 0.15055, lat: 38.78002 },
            { lng: 0.14852, lat: 38.77923 },
            { lng: 0.14619, lat: 38.77642 },
            { lng: 0.14531, lat: 38.77587 },
            { lng: 0.14338, lat: 38.77531 },
            { lng: 0.13701, lat: 38.77532 }
        ]
    ];
});
