define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.benissaZoneAdvocat = void 0;
    exports.benissaZoneAdvocat = [
        [
            { lng: 0.08342, lat: 38.68538 },
            { lng: 0.08579, lat: 38.68409 },
            { lng: 0.08669, lat: 38.68395 },
            { lng: 0.08845, lat: 38.68401 },
            { lng: 0.09039, lat: 38.68465 },
            { lng: 0.09088, lat: 38.68463 },
            { lng: 0.09209, lat: 38.68406 },
            { lng: 0.09486, lat: 38.68354 },
            { lng: 0.09518, lat: 38.68252 },
            { lng: 0.09565, lat: 38.68221 },
            { lng: 0.09593, lat: 38.68224 },
            { lng: 0.09712, lat: 38.6831 },
            { lng: 0.09764, lat: 38.68314 },
            { lng: 0.09886, lat: 38.6836 },
            { lng: 0.0995, lat: 38.68286 },
            { lng: 0.10096, lat: 38.68329 },
            { lng: 0.10036, lat: 38.68403 },
            { lng: 0.10052, lat: 38.68413 },
            { lng: 0.10162, lat: 38.68369 },
            { lng: 0.10187, lat: 38.68314 },
            { lng: 0.10244, lat: 38.68256 },
            { lng: 0.10346, lat: 38.68254 },
            { lng: 0.10337, lat: 38.68226 },
            { lng: 0.10376, lat: 38.6818 },
            { lng: 0.10415, lat: 38.68182 },
            { lng: 0.10499, lat: 38.68234 },
            { lng: 0.10553, lat: 38.68247 },
            { lng: 0.10585, lat: 38.68075 },
            { lng: 0.1085, lat: 38.67457 },
            { lng: 0.10556, lat: 38.67418 },
            { lng: 0.10477, lat: 38.67387 },
            { lng: 0.10421, lat: 38.6739 },
            { lng: 0.10336, lat: 38.67421 },
            { lng: 0.10263, lat: 38.67397 },
            { lng: 0.1019, lat: 38.67401 },
            { lng: 0.10115, lat: 38.67364 },
            { lng: 0.10064, lat: 38.67301 },
            { lng: 0.09903, lat: 38.67264 },
            { lng: 0.09881, lat: 38.67242 },
            { lng: 0.09863, lat: 38.67255 },
            { lng: 0.09877, lat: 38.67278 },
            { lng: 0.09844, lat: 38.67282 },
            { lng: 0.09783, lat: 38.672 },
            { lng: 0.09793, lat: 38.67131 },
            { lng: 0.09781, lat: 38.67109 },
            { lng: 0.0953, lat: 38.66994 },
            { lng: 0.095, lat: 38.66997 },
            { lng: 0.09417, lat: 38.67122 },
            { lng: 0.09352, lat: 38.67152 },
            { lng: 0.09304, lat: 38.67151 },
            { lng: 0.09197, lat: 38.6711 },
            { lng: 0.09147, lat: 38.67138 },
            { lng: 0.09095, lat: 38.67205 },
            { lng: 0.09012, lat: 38.67255 },
            { lng: 0.08647, lat: 38.67363 },
            { lng: 0.08565, lat: 38.6742 },
            { lng: 0.08484, lat: 38.67504 },
            { lng: 0.08469, lat: 38.67639 },
            { lng: 0.08428, lat: 38.67706 },
            { lng: 0.08324, lat: 38.67764 },
            { lng: 0.08301, lat: 38.67818 },
            { lng: 0.08265, lat: 38.67835 },
            { lng: 0.08222, lat: 38.67885 },
            { lng: 0.08113, lat: 38.68124 },
            { lng: 0.0804, lat: 38.68187 },
            { lng: 0.0807, lat: 38.6839 },
            { lng: 0.08178, lat: 38.68481 },
            { lng: 0.08283, lat: 38.6854 },
            { lng: 0.08342, lat: 38.68538 }
        ]
    ];
});
