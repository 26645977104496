define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.morairaZoneBenimeit = void 0;
    exports.morairaZoneBenimeit = [
        [
            { lng: 0.09795, lat: 38.69476 },
            { lng: 0.09656, lat: 38.69648 },
            { lng: 0.09371, lat: 38.69794 },
            { lng: 0.09342, lat: 38.69829 },
            { lng: 0.09338, lat: 38.69944 },
            { lng: 0.09395, lat: 38.70086 },
            { lng: 0.09537, lat: 38.70048 },
            { lng: 0.09587, lat: 38.70134 },
            { lng: 0.09669, lat: 38.7019 },
            { lng: 0.09691, lat: 38.7035 },
            { lng: 0.09844, lat: 38.70365 },
            { lng: 0.10102, lat: 38.70429 },
            { lng: 0.10174, lat: 38.70409 },
            { lng: 0.1029, lat: 38.70444 },
            { lng: 0.10313, lat: 38.70479 },
            { lng: 0.10472, lat: 38.705 },
            { lng: 0.10583, lat: 38.70573 },
            { lng: 0.10631, lat: 38.70581 },
            { lng: 0.10684, lat: 38.70558 },
            { lng: 0.10734, lat: 38.70598 },
            { lng: 0.1082, lat: 38.70633 },
            { lng: 0.11132, lat: 38.70647 },
            { lng: 0.11245, lat: 38.70664 },
            { lng: 0.1145, lat: 38.70739 },
            { lng: 0.11894, lat: 38.7079 },
            { lng: 0.12116, lat: 38.70871 },
            { lng: 0.12189, lat: 38.70962 },
            { lng: 0.12235, lat: 38.70981 },
            { lng: 0.12319, lat: 38.70989 },
            { lng: 0.12442, lat: 38.71034 },
            { lng: 0.12621, lat: 38.71071 },
            { lng: 0.12825, lat: 38.71074 },
            { lng: 0.12902, lat: 38.71044 },
            { lng: 0.13002, lat: 38.71055 },
            { lng: 0.13306, lat: 38.7096 },
            { lng: 0.13364, lat: 38.70964 },
            { lng: 0.13516, lat: 38.70863 },
            { lng: 0.13461, lat: 38.70889 },
            { lng: 0.13494, lat: 38.70678 },
            { lng: 0.1347, lat: 38.70582 },
            { lng: 0.1348, lat: 38.70558 },
            { lng: 0.13554, lat: 38.7053 },
            { lng: 0.13578, lat: 38.70503 },
            { lng: 0.13589, lat: 38.70457 },
            { lng: 0.13578, lat: 38.70295 },
            { lng: 0.13542, lat: 38.7016 },
            { lng: 0.13466, lat: 38.70085 },
            { lng: 0.13417, lat: 38.70006 },
            { lng: 0.13356, lat: 38.69815 },
            { lng: 0.1332, lat: 38.69603 },
            { lng: 0.13248, lat: 38.69447 },
            { lng: 0.13289, lat: 38.69198 },
            { lng: 0.12845, lat: 38.6918 },
            { lng: 0.12755, lat: 38.69254 },
            { lng: 0.1263, lat: 38.69307 },
            { lng: 0.12545, lat: 38.69397 },
            { lng: 0.12479, lat: 38.6942 },
            { lng: 0.12226, lat: 38.69571 },
            { lng: 0.12053, lat: 38.69568 },
            { lng: 0.11758, lat: 38.69473 },
            { lng: 0.11605, lat: 38.69466 },
            { lng: 0.11438, lat: 38.69479 },
            { lng: 0.11376, lat: 38.6951 },
            { lng: 0.114, lat: 38.69581 },
            { lng: 0.1136, lat: 38.69599 },
            { lng: 0.11258, lat: 38.69619 },
            { lng: 0.11226, lat: 38.69604 },
            { lng: 0.11063, lat: 38.69652 },
            { lng: 0.10984, lat: 38.69655 },
            { lng: 0.1086, lat: 38.69701 },
            { lng: 0.10733, lat: 38.69797 },
            { lng: 0.10603, lat: 38.69757 },
            { lng: 0.10044, lat: 38.69692 },
            { lng: 0.0978, lat: 38.69495 },
            { lng: 0.09795, lat: 38.69476 }
        ]
    ];
});
