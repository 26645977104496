define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.benissaZoneMontemar = void 0;
    exports.benissaZoneMontemar = [
        [
            { lng: 0.10553, lat: 38.68247 },
            { lng: 0.10499, lat: 38.68234 },
            { lng: 0.10415, lat: 38.68182 },
            { lng: 0.10376, lat: 38.6818 },
            { lng: 0.10337, lat: 38.68226 },
            { lng: 0.10346, lat: 38.68254 },
            { lng: 0.10244, lat: 38.68256 },
            { lng: 0.10187, lat: 38.68314 },
            { lng: 0.10162, lat: 38.68369 },
            { lng: 0.10052, lat: 38.68413 },
            { lng: 0.10036, lat: 38.68403 },
            { lng: 0.10096, lat: 38.68329 },
            { lng: 0.0995, lat: 38.68286 },
            { lng: 0.09886, lat: 38.6836 },
            { lng: 0.09764, lat: 38.68314 },
            { lng: 0.09712, lat: 38.6831 },
            { lng: 0.09593, lat: 38.68224 },
            { lng: 0.09565, lat: 38.68221 },
            { lng: 0.09518, lat: 38.68252 },
            { lng: 0.09486, lat: 38.68354 },
            { lng: 0.09209, lat: 38.68406 },
            { lng: 0.09088, lat: 38.68463 },
            { lng: 0.09039, lat: 38.68465 },
            { lng: 0.08885, lat: 38.6841 },
            { lng: 0.08751, lat: 38.68391 },
            { lng: 0.0856, lat: 38.68418 },
            { lng: 0.08342, lat: 38.68538 },
            { lng: 0.08335, lat: 38.68613 },
            { lng: 0.08228, lat: 38.68768 },
            { lng: 0.08252, lat: 38.68893 },
            { lng: 0.08229, lat: 38.69099 },
            { lng: 0.08248, lat: 38.69177 },
            { lng: 0.08173, lat: 38.69317 },
            { lng: 0.08307, lat: 38.69521 },
            { lng: 0.08582, lat: 38.69719 },
            { lng: 0.0897, lat: 38.69686 },
            { lng: 0.09371, lat: 38.69794 },
            { lng: 0.09415, lat: 38.69778 },
            { lng: 0.09656, lat: 38.69648 },
            { lng: 0.09795, lat: 38.69476 },
            { lng: 0.10045, lat: 38.69318 },
            { lng: 0.10232, lat: 38.69123 },
            { lng: 0.10579, lat: 38.68953 },
            { lng: 0.10766, lat: 38.68826 },
            { lng: 0.10755, lat: 38.68647 },
            { lng: 0.1073, lat: 38.68575 },
            { lng: 0.10575, lat: 38.68316 },
            { lng: 0.10553, lat: 38.68247 }
        ]
    ];
});
