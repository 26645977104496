define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.benitachell = void 0;
    exports.benitachell = [
        [
            { lng: 0.118007, lat: 38.7406892 },
            { lng: 0.1169179, lat: 38.7397182 },
            { lng: 0.1140423, lat: 38.7391913 },
            { lng: 0.1133759, lat: 38.737092 },
            { lng: 0.1140865, lat: 38.7365055 },
            { lng: 0.1149363, lat: 38.735681 },
            { lng: 0.1157883, lat: 38.734379 },
            { lng: 0.1164383, lat: 38.7337221 },
            { lng: 0.1167661, lat: 38.7334072 },
            { lng: 0.1169189, lat: 38.7332603 },
            { lng: 0.1172774, lat: 38.7329159 },
            { lng: 0.1293678, lat: 38.7290161 },
            { lng: 0.1361176, lat: 38.7274117 },
            { lng: 0.1387411, lat: 38.7182772 },
            { lng: 0.1389375, lat: 38.7175932 },
            { lng: 0.1394004, lat: 38.7159813 },
            { lng: 0.1451986, lat: 38.7110687 },
            { lng: 0.1457947, lat: 38.7107644 },
            { lng: 0.1464881, lat: 38.7105836 },
            { lng: 0.1470956, lat: 38.7102789 },
            { lng: 0.1475665, lat: 38.710005 },
            { lng: 0.1481031, lat: 38.7096572 },
            { lng: 0.148694, lat: 38.7092359 },
            { lng: 0.1493419, lat: 38.708804 },
            { lng: 0.1503965, lat: 38.7079467 },
            { lng: 0.1508142, lat: 38.707512 },
            { lng: 0.1510851, lat: 38.7071354 },
            { lng: 0.1513734, lat: 38.7066321 },
            { lng: 0.1514716, lat: 38.7062511 },
            { lng: 0.1515811, lat: 38.7059605 },
            { lng: 0.1516466, lat: 38.7057869 },
            { lng: 0.1516455, lat: 38.7052374 },
            { lng: 0.151495, lat: 38.7041694 },
            { lng: 0.1513905, lat: 38.703884 },
            { lng: 0.1513893, lat: 38.7035957 },
            { lng: 0.1514258, lat: 38.7033785 },
            { lng: 0.1516103, lat: 38.7031303 },
            { lng: 0.1521654, lat: 38.7021604 },
            { lng: 0.1524569, lat: 38.7017291 },
            { lng: 0.1527499, lat: 38.7013338 },
            { lng: 0.1531146, lat: 38.7004771 },
            { lng: 0.1532342, lat: 38.7000595 },
            { lng: 0.15337, lat: 38.6997495 },
            { lng: 0.1536368, lat: 38.6992829 },
            { lng: 0.1539568, lat: 38.6989769 },
            { lng: 0.1543582, lat: 38.6986958 },
            { lng: 0.1552348, lat: 38.698215 },
            { lng: 0.1555969, lat: 38.6981112 },
            { lng: 0.15569, lat: 38.6981767 },
            { lng: 0.1557639, lat: 38.6981894 },
            { lng: 0.1560053, lat: 38.6982307 },
            { lng: 0.1566498, lat: 38.6982584 },
            { lng: 0.1568303, lat: 38.6982636 },
            { lng: 0.1570018, lat: 38.6982331 },
            { lng: 0.1571909, lat: 38.6982425 },
            { lng: 0.1572633, lat: 38.6982205 },
            { lng: 0.1573116, lat: 38.6981148 },
            { lng: 0.1574014, lat: 38.698142 },
            { lng: 0.1573907, lat: 38.6982205 },
            { lng: 0.1575623, lat: 38.698254 },
            { lng: 0.1577152, lat: 38.6981379 },
            { lng: 0.1579782, lat: 38.6980584 },
            { lng: 0.1580773, lat: 38.6980887 },
            { lng: 0.1580948, lat: 38.6981411 },
            { lng: 0.1579204, lat: 38.6983545 },
            { lng: 0.1577663, lat: 38.6984767 },
            { lng: 0.1578185, lat: 38.6985743 },
            { lng: 0.1581017, lat: 38.6986757 },
            { lng: 0.1583281, lat: 38.6988601 },
            { lng: 0.1586656, lat: 38.6990132 },
            { lng: 0.1591167, lat: 38.6993515 },
            { lng: 0.1597671, lat: 38.6996925 },
            { lng: 0.1600647, lat: 38.7000041 },
            { lng: 0.160495, lat: 38.7003132 },
            { lng: 0.1608284, lat: 38.7004659 },
            { lng: 0.1611914, lat: 38.7007917 },
            { lng: 0.1615132, lat: 38.7010805 },
            { lng: 0.1618557, lat: 38.7015556 },
            { lng: 0.1622191, lat: 38.7018697 },
            { lng: 0.1626765, lat: 38.7021592 },
            { lng: 0.1630978, lat: 38.7025466 },
            { lng: 0.1632329, lat: 38.7029518 },
            { lng: 0.163679, lat: 38.7031366 },
            { lng: 0.1638686, lat: 38.703448 },
            { lng: 0.1643635, lat: 38.7038107 },
            { lng: 0.1647478, lat: 38.7042105 },
            { lng: 0.1647765, lat: 38.7053944 },
            { lng: 0.1649477, lat: 38.7056358 },
            { lng: 0.1651113, lat: 38.7057321 },
            { lng: 0.1654976, lat: 38.7064646 },
            { lng: 0.1655584, lat: 38.7066281 },
            { lng: 0.1659534, lat: 38.7066233 },
            { lng: 0.166093, lat: 38.7067326 },
            { lng: 0.1660662, lat: 38.7068948 },
            { lng: 0.1661422, lat: 38.707052 },
            { lng: 0.1661936, lat: 38.7070591 },
            { lng: 0.1663304, lat: 38.7069963 },
            { lng: 0.16641, lat: 38.707053 },
            { lng: 0.1664699, lat: 38.7075562 },
            { lng: 0.1666388, lat: 38.7076912 },
            { lng: 0.1667035, lat: 38.708001 },
            { lng: 0.1666093, lat: 38.7080771 },
            { lng: 0.1665873, lat: 38.7082547 },
            { lng: 0.166602, lat: 38.7086315 },
            { lng: 0.1665577, lat: 38.7088231 },
            { lng: 0.1665194, lat: 38.7090507 },
            { lng: 0.1665074, lat: 38.7092882 },
            { lng: 0.1666481, lat: 38.709551 },
            { lng: 0.1668279, lat: 38.709799 },
            { lng: 0.167066, lat: 38.7100799 },
            { lng: 0.1672811, lat: 38.7103284 },
            { lng: 0.1674664, lat: 38.7104748 },
            { lng: 0.167595, lat: 38.7105738 },
            { lng: 0.1675998, lat: 38.7104901 },
            { lng: 0.1677613, lat: 38.7104795 },
            { lng: 0.1681134, lat: 38.7105489 },
            { lng: 0.168338, lat: 38.7106855 },
            { lng: 0.1684073, lat: 38.7107589 },
            { lng: 0.1685536, lat: 38.7107444 },
            { lng: 0.1687745, lat: 38.7109126 },
            { lng: 0.1688176, lat: 38.7109084 },
            { lng: 0.1688728, lat: 38.7108936 },
            { lng: 0.1689964, lat: 38.7109363 },
            { lng: 0.1690373, lat: 38.7110044 },
            { lng: 0.1691945, lat: 38.7110681 },
            { lng: 0.1692164, lat: 38.7111753 },
            { lng: 0.1693027, lat: 38.7113036 },
            { lng: 0.1693111, lat: 38.7113661 },
            { lng: 0.1694436, lat: 38.7114358 },
            { lng: 0.1697608, lat: 38.7115285 },
            { lng: 0.1699632, lat: 38.7114672 },
            { lng: 0.170052, lat: 38.7114795 },
            { lng: 0.170169, lat: 38.7116074 },
            { lng: 0.170194, lat: 38.7117627 },
            { lng: 0.1703477, lat: 38.7118757 },
            { lng: 0.1705584, lat: 38.7118823 },
            { lng: 0.1707674, lat: 38.7116522 },
            { lng: 0.1708964, lat: 38.711688 },
            { lng: 0.1710082, lat: 38.7117097 },
            { lng: 0.1710683, lat: 38.7119255 },
            { lng: 0.1710987, lat: 38.7120741 },
            { lng: 0.1713064, lat: 38.7120798 },
            { lng: 0.171532, lat: 38.712053 },
            { lng: 0.1715589, lat: 38.7119753 },
            { lng: 0.1716098, lat: 38.711707 },
            { lng: 0.1716636, lat: 38.7116579 },
            { lng: 0.1718115, lat: 38.7117432 },
            { lng: 0.1718773, lat: 38.7117204 },
            { lng: 0.171969, lat: 38.7116858 },
            { lng: 0.1722602, lat: 38.7117002 },
            { lng: 0.1723479, lat: 38.711715 },
            { lng: 0.172659, lat: 38.7117359 },
            { lng: 0.1728939, lat: 38.711718 },
            { lng: 0.1730487, lat: 38.7117679 },
            { lng: 0.1732674, lat: 38.7119034 },
            { lng: 0.1734693, lat: 38.7119913 },
            { lng: 0.1736515, lat: 38.712122 },
            { lng: 0.1737958, lat: 38.712117 },
            { lng: 0.1738757, lat: 38.7120706 },
            { lng: 0.1740128, lat: 38.7121208 },
            { lng: 0.1741325, lat: 38.7122862 },
            { lng: 0.1744374, lat: 38.71246 },
            { lng: 0.1746475, lat: 38.7123809 },
            { lng: 0.1747873, lat: 38.7124771 },
            { lng: 0.1746, lat: 38.7127302 },
            { lng: 0.1746761, lat: 38.7128462 },
            { lng: 0.1756462, lat: 38.7131921 },
            { lng: 0.1760302, lat: 38.7132134 },
            { lng: 0.1763578, lat: 38.713361 },
            { lng: 0.1766586, lat: 38.7137445 },
            { lng: 0.1766497, lat: 38.71389 },
            { lng: 0.1767962, lat: 38.7139576 },
            { lng: 0.1769711, lat: 38.7140193 },
            { lng: 0.1771405, lat: 38.7141624 },
            { lng: 0.1775389, lat: 38.7143971 },
            { lng: 0.1778218, lat: 38.714847 },
            { lng: 0.1778827, lat: 38.7148909 },
            { lng: 0.1779993, lat: 38.714916 },
            { lng: 0.1781169, lat: 38.7150088 },
            { lng: 0.1783166, lat: 38.7152923 },
            { lng: 0.1785225, lat: 38.715425 },
            { lng: 0.1786176, lat: 38.7155498 },
            { lng: 0.1788264, lat: 38.7156764 },
            { lng: 0.1789775, lat: 38.7157119 },
            { lng: 0.1791989, lat: 38.7158933 },
            { lng: 0.179484, lat: 38.7160655 },
            { lng: 0.1799145, lat: 38.7162676 },
            { lng: 0.1800711, lat: 38.7164031 },
            { lng: 0.1802498, lat: 38.7165189 },
            { lng: 0.1804719, lat: 38.7167974 },
            { lng: 0.1793487, lat: 38.7168016 },
            { lng: 0.1753936, lat: 38.7196659 },
            { lng: 0.1673855, lat: 38.7258022 },
            { lng: 0.1643514, lat: 38.7282788 },
            { lng: 0.1641673, lat: 38.7284479 },
            { lng: 0.1612816, lat: 38.7310756 },
            { lng: 0.1587082, lat: 38.7344917 },
            { lng: 0.1567856, lat: 38.7370693 },
            { lng: 0.1554896, lat: 38.7392681 },
            { lng: 0.1552697, lat: 38.7394993 },
            { lng: 0.1546585, lat: 38.739723 },
            { lng: 0.1497908, lat: 38.7412418 },
            { lng: 0.1457949, lat: 38.7419261 },
            { lng: 0.1387001, lat: 38.7432971 },
            { lng: 0.1374417, lat: 38.7433075 },
            { lng: 0.1363922, lat: 38.7433892 },
            { lng: 0.1322331, lat: 38.7435667 },
            { lng: 0.1311012, lat: 38.7434034 },
            { lng: 0.1290749, lat: 38.7431049 },
            { lng: 0.1229003, lat: 38.7426395 },
            { lng: 0.118007, lat: 38.7406892 }
        ]
    ];
});
