define(["require", "exports", "./areas/altea", "./areas/calpe", "./areas/javea", "./zones/javea/center", "./zones/javea/el-arenal", "./zones/javea/adsubia", "./zones/javea/puerto", "./zones/javea/montgo", "./zones/javea/tosal", "./zones/javea/capmarti", "./zones/javea/granadella", "./zones/javea/portichol", "./areas/benissa", "./areas/teulada", "./areas/benitachell", "./areas/denia", "./areas/pedreguer", "./areas/gata-de-gorgos", "./areas/moraira", "./zones/moraira/moravit", "./zones/moraira/portet", "./zones/moraira/benimeit", "./zones/moraira/advocat", "./zones/moraira/casco-urbano", "./zones/moraira/paichi", "./zones/benissa/la-fustera", "./zones/benissa/montemar", "./zones/benissa/advocat", "./zones/benissa/pueblo"], function (require, exports, altea_1, calpe_1, javea_1, center_1, el_arenal_1, adsubia_1, puerto_1, montgo_1, tosal_1, capmarti_1, granadella_1, portichol_1, benissa_1, teulada_1, benitachell_1, denia_1, pedreguer_1, gata_de_gorgos_1, moraira_1, moravit_1, portet_1, benimeit_1, advocat_1, casco_urbano_1, paichi_1, la_fustera_1, montemar_1, advocat_2, pueblo_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.sooUsedPoligons = void 0;
    exports.sooUsedPoligons = {
        center: { lat: 38.74532785673098, lng: 0.16015891455079979 },
        poligons: [
            {
                id: 'moraira',
                label: 'Moraira',
                coords: moraira_1.moraira,
                type: 'parent',
                zoom: 13.2,
                children: [
                    { id: 'moraira:moravit-cap-blanc', label: 'Moravit - Cap Blanc', coords: moravit_1.morairaZoneMoravit, type: 'children' },
                    { id: 'moraira:el-portet-pla-de-mar', label: 'El Portet - Pla de Mar', coords: portet_1.morairaZonePortet, type: 'children' },
                    { id: 'moraira:benimeit-tabaira', label: 'Benimeit - Tabaira ', coords: benimeit_1.morairaZoneBenimeit, type: 'children' },
                    { id: 'moraira:pinar-del-advocat-cometa', label: 'Pinar del Advocat - Cometa', coords: advocat_1.morairaZoneAdvocat, type: 'children' },
                    { id: 'moraira:casco-urbano', label: 'Casco Urbano', coords: casco_urbano_1.morairaZoneCascoUrbano, type: 'children' },
                    { id: 'moraira:paichi', label: 'Paichi', coords: paichi_1.morairaZonePaichi, type: 'children' },
                ]
            },
            { id: 'altea', label: 'Altea', coords: altea_1.altea, type: 'parent' },
            { id: 'calpe/calp', label: 'Calpe/Calp', coords: calpe_1.calpe, type: 'parent' },
            {
                id: 'javea/xabia',
                label: 'Javea/Xabia',
                coords: javea_1.javea,
                type: 'parent',
                zoom: 12.2,
                children: [
                    { id: 'javea/xabia:centro-ciudad', label: 'Centro ciudad', coords: center_1.javeaZoneCentroCiudad, type: 'children' },
                    { id: 'javea/xabia:montanar-el-arenal', label: 'Montañar - El Arenal', coords: el_arenal_1.javeaZoneElArenal, type: 'children' },
                    { id: 'javea/xabia:partidas-comunes-adsubia', label: 'Partides comunes - Adsubia', coords: adsubia_1.javeaZoneAdsubia, type: 'children' },
                    { id: 'javea/xabia:puerto', label: 'Puerto', coords: puerto_1.javeaZonePuerto, type: 'children' },
                    { id: 'javea/xabia:montgo-ermita', label: 'Montgó - Ermita', coords: montgo_1.javeaZoneMontgo, type: 'children' },
                    { id: 'javea/xabia:partida-tosal-zona-del-castellans', label: 'Partida Tosal - Zona del Castellans', coords: tosal_1.javeaZoneTosal, type: 'children' },
                    { id: 'javea/xabia:cap-marti-el-tossalet-pinomar', label: 'Cap Martí - El Tossalet - Pinomar', coords: capmarti_1.javeaZoneCapMarti, type: 'children' },
                    { id: 'javea/xabia:la-granadella-costa-nova', label: 'La Granadella - Costa Nova', coords: granadella_1.javeaZoneGranadella, type: 'children' },
                    { id: 'javea/xabia:portichol-balcon-al-mar', label: 'Portichol - Balcón al Mar', coords: portichol_1.javeaZonePortichol, type: 'children' }
                ]
            },
            {
                id: 'teulada',
                label: 'Teulada',
                coords: teulada_1.teulada,
                type: 'parent'
            },
            {
                id: 'benissa',
                label: 'Benissa',
                coords: benissa_1.benissa,
                type: 'parent',
                zoom: 12.2,
                children: [
                    { id: 'benissa:la-vina-montemar-san-jaime', label: 'La Viña - Montemar - San Jaime', coords: montemar_1.benissaZoneMontemar, type: 'children' },
                    { id: 'benissa:la-fustera', label: 'La Fustera', coords: la_fustera_1.benissaZoneLaFustera, type: 'children' },
                    { id: 'benissa:cala-advocat-baladrar', label: 'Cala Advocat - Baladrar', coords: advocat_2.benissaZoneAdvocat, type: 'children' },
                    { id: 'benissa:benissa-pueblo', label: 'Benissa pueblo', coords: pueblo_1.benissaZonePueblo, type: 'children' },
                ]
            },
            { id: 'benitachell', label: 'Benitachell', coords: benitachell_1.benitachell, type: 'parent' },
            { id: 'denia', label: 'Denia', coords: denia_1.denia, type: 'parent', center: { lng: 0.08476, lat: 38.83175 } },
            { id: 'pedreguer', label: 'Pedreguer', coords: pedreguer_1.pedreguer, type: 'parent' },
            { id: 'gatadegorgos', label: 'Gata De Gorgos', coords: gata_de_gorgos_1.gataDeGorgos, type: 'parent' }
        ]
    };
});
