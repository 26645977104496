define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.morairaZonePortet = void 0;
    exports.morairaZonePortet = [
        [
            { lng: 0.15669, lat: 38.69398 },
            { lng: 0.15657, lat: 38.69383 },
            { lng: 0.15626, lat: 38.69388 },
            { lng: 0.15636, lat: 38.69375 },
            { lng: 0.15621, lat: 38.69361 },
            { lng: 0.15647, lat: 38.69354 },
            { lng: 0.1566, lat: 38.69325 },
            { lng: 0.15628, lat: 38.69295 },
            { lng: 0.15633, lat: 38.69252 },
            { lng: 0.15574, lat: 38.69214 },
            { lng: 0.15576, lat: 38.69194 },
            { lng: 0.1562, lat: 38.6917 },
            { lng: 0.15615, lat: 38.69141 },
            { lng: 0.15597, lat: 38.69122 },
            { lng: 0.15564, lat: 38.6912 },
            { lng: 0.15532, lat: 38.69064 },
            { lng: 0.15544, lat: 38.69042 },
            { lng: 0.1553, lat: 38.69035 },
            { lng: 0.15563, lat: 38.69018 },
            { lng: 0.15531, lat: 38.68964 },
            { lng: 0.1555, lat: 38.68934 },
            { lng: 0.15483, lat: 38.68858 },
            { lng: 0.15524, lat: 38.6883 },
            { lng: 0.15496, lat: 38.68756 },
            { lng: 0.15513, lat: 38.68732 },
            { lng: 0.15507, lat: 38.68685 },
            { lng: 0.15464, lat: 38.68629 },
            { lng: 0.15312, lat: 38.68553 },
            { lng: 0.15346, lat: 38.68526 },
            { lng: 0.15346, lat: 38.68502 },
            { lng: 0.15301, lat: 38.68447 },
            { lng: 0.15303, lat: 38.68409 },
            { lng: 0.15282, lat: 38.68379 },
            { lng: 0.15287, lat: 38.68329 },
            { lng: 0.15222, lat: 38.68211 },
            { lng: 0.1519, lat: 38.68207 },
            { lng: 0.15171, lat: 38.68181 },
            { lng: 0.1508, lat: 38.6819 },
            { lng: 0.15043, lat: 38.68134 },
            { lng: 0.15093, lat: 38.68129 },
            { lng: 0.15106, lat: 38.68105 },
            { lng: 0.15096, lat: 38.68086 },
            { lng: 0.15054, lat: 38.68054 },
            { lng: 0.15011, lat: 38.68059 },
            { lng: 0.14951, lat: 38.68007 },
            { lng: 0.14909, lat: 38.67942 },
            { lng: 0.14827, lat: 38.6792 },
            { lng: 0.14781, lat: 38.67929 },
            { lng: 0.14693, lat: 38.68009 },
            { lng: 0.14737, lat: 38.68132 },
            { lng: 0.14737, lat: 38.68181 },
            { lng: 0.14717, lat: 38.68218 },
            { lng: 0.1472, lat: 38.68294 },
            { lng: 0.14705, lat: 38.68308 },
            { lng: 0.14713, lat: 38.68447 },
            { lng: 0.14697, lat: 38.68597 },
            { lng: 0.14707, lat: 38.68636 },
            { lng: 0.14739, lat: 38.68648 },
            { lng: 0.14729, lat: 38.68687 },
            { lng: 0.14685, lat: 38.68718 },
            { lng: 0.14525, lat: 38.68755 },
            { lng: 0.14492, lat: 38.68746 },
            { lng: 0.14472, lat: 38.68688 },
            { lng: 0.14457, lat: 38.68681 },
            { lng: 0.14293, lat: 38.68669 },
            { lng: 0.14246, lat: 38.68646 },
            { lng: 0.14188, lat: 38.68652 },
            { lng: 0.14126, lat: 38.68622 },
            { lng: 0.14012, lat: 38.68615 },
            { lng: 0.13981, lat: 38.6863 },
            { lng: 0.13982, lat: 38.68651 },
            { lng: 0.13913, lat: 38.68668 },
            { lng: 0.13887, lat: 38.68747 },
            { lng: 0.13742, lat: 38.68774 },
            { lng: 0.13653, lat: 38.68773 },
            { lng: 0.1364, lat: 38.68885 },
            { lng: 0.13536, lat: 38.68922 },
            { lng: 0.13356, lat: 38.68905 },
            { lng: 0.13248, lat: 38.69432 },
            { lng: 0.1332, lat: 38.69603 },
            { lng: 0.13352, lat: 38.69759 },
            { lng: 0.13623, lat: 38.69737 },
            { lng: 0.13871, lat: 38.69647 },
            { lng: 0.13967, lat: 38.69575 },
            { lng: 0.14043, lat: 38.69475 },
            { lng: 0.1407, lat: 38.69321 },
            { lng: 0.14247, lat: 38.69259 },
            { lng: 0.14348, lat: 38.69325 },
            { lng: 0.1439, lat: 38.69329 },
            { lng: 0.14448, lat: 38.69369 },
            { lng: 0.145, lat: 38.69375 },
            { lng: 0.14514, lat: 38.69414 },
            { lng: 0.14594, lat: 38.69452 },
            { lng: 0.14684, lat: 38.69546 },
            { lng: 0.14982, lat: 38.6971 },
            { lng: 0.15142, lat: 38.69749 },
            { lng: 0.15567, lat: 38.69785 },
            { lng: 0.15641, lat: 38.69687 },
            { lng: 0.1562, lat: 38.69672 },
            { lng: 0.15629, lat: 38.69653 },
            { lng: 0.15599, lat: 38.69592 },
            { lng: 0.15599, lat: 38.69549 },
            { lng: 0.15566, lat: 38.69492 },
            { lng: 0.15591, lat: 38.69464 },
            { lng: 0.15598, lat: 38.69422 },
            { lng: 0.1566, lat: 38.69421 },
            { lng: 0.15669, lat: 38.69398 }
        ]
    ];
});
