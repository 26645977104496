define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.javeaZoneGranadella = void 0;
    exports.javeaZoneGranadella = [
        [
            { lng: 0.16084, lat: 38.73162 },
            { lng: 0.16136, lat: 38.7328 },
            { lng: 0.16322, lat: 38.73543 },
            { lng: 0.16497, lat: 38.7367 },
            { lng: 0.16727, lat: 38.73782 },
            { lng: 0.16851, lat: 38.73909 },
            { lng: 0.1712, lat: 38.74018 },
            { lng: 0.17314, lat: 38.74139 },
            { lng: 0.17961, lat: 38.74343 },
            { lng: 0.1825, lat: 38.7439 },
            { lng: 0.18634, lat: 38.74491 },
            { lng: 0.18865, lat: 38.74626 },
            { lng: 0.19045, lat: 38.74576 },
            { lng: 0.19183, lat: 38.74405 },
            { lng: 0.19322, lat: 38.7434 },
            { lng: 0.19505, lat: 38.74342 },
            { lng: 0.19613, lat: 38.74369 },
            { lng: 0.19729, lat: 38.74371 },
            { lng: 0.19919, lat: 38.74508 },
            { lng: 0.20192, lat: 38.74555 },
            { lng: 0.20182, lat: 38.74663 },
            { lng: 0.20235, lat: 38.74708 },
            { lng: 0.20396, lat: 38.74734 },
            { lng: 0.20418, lat: 38.74749 },
            { lng: 0.2042, lat: 38.74805 },
            { lng: 0.20471, lat: 38.74812 },
            { lng: 0.20486, lat: 38.7486 },
            { lng: 0.20719, lat: 38.74721 },
            { lng: 0.20763, lat: 38.74739 },
            { lng: 0.20983, lat: 38.74715 },
            { lng: 0.21343, lat: 38.74753 },
            { lng: 0.21491, lat: 38.74806 },
            { lng: 0.21665, lat: 38.7484 },
            { lng: 0.21638, lat: 38.74742 },
            { lng: 0.21547, lat: 38.74624 },
            { lng: 0.2166, lat: 38.73728 },
            { lng: 0.21697, lat: 38.73677 },
            { lng: 0.21809, lat: 38.73628 },
            { lng: 0.21812, lat: 38.73556 },
            { lng: 0.21869, lat: 38.73527 },
            { lng: 0.2185, lat: 38.73485 },
            { lng: 0.21774, lat: 38.73434 },
            { lng: 0.21433, lat: 38.73109 },
            { lng: 0.21348, lat: 38.73037 },
            { lng: 0.21342, lat: 38.73172 },
            { lng: 0.21321, lat: 38.73187 },
            { lng: 0.21294, lat: 38.73157 },
            { lng: 0.21152, lat: 38.73158 },
            { lng: 0.21075, lat: 38.73174 },
            { lng: 0.20959, lat: 38.73167 },
            { lng: 0.20869, lat: 38.73185 },
            { lng: 0.20859, lat: 38.73205 },
            { lng: 0.20679, lat: 38.73243 },
            { lng: 0.20631, lat: 38.73239 },
            { lng: 0.2052, lat: 38.73179 },
            { lng: 0.20484, lat: 38.73177 },
            { lng: 0.20426, lat: 38.73203 },
            { lng: 0.20429, lat: 38.7316 },
            { lng: 0.2045, lat: 38.73126 },
            { lng: 0.20428, lat: 38.73105 },
            { lng: 0.20155, lat: 38.73103 },
            { lng: 0.20124, lat: 38.73084 },
            { lng: 0.2013, lat: 38.73045 },
            { lng: 0.20116, lat: 38.73017 },
            { lng: 0.20037, lat: 38.72989 },
            { lng: 0.20025, lat: 38.72922 },
            { lng: 0.19956, lat: 38.72894 },
            { lng: 0.19891, lat: 38.72905 },
            { lng: 0.19865, lat: 38.7289 },
            { lng: 0.19799, lat: 38.72887 },
            { lng: 0.19752, lat: 38.72912 },
            { lng: 0.19751, lat: 38.72949 },
            { lng: 0.19677, lat: 38.72938 },
            { lng: 0.19644, lat: 38.72901 },
            { lng: 0.19642, lat: 38.72855 },
            { lng: 0.19678, lat: 38.72846 },
            { lng: 0.19697, lat: 38.72814 },
            { lng: 0.19805, lat: 38.72734 },
            { lng: 0.19913, lat: 38.72608 },
            { lng: 0.19946, lat: 38.72555 },
            { lng: 0.19944, lat: 38.72523 },
            { lng: 0.19912, lat: 38.72506 },
            { lng: 0.19796, lat: 38.72491 },
            { lng: 0.1966, lat: 38.72511 },
            { lng: 0.1957, lat: 38.72441 },
            { lng: 0.19562, lat: 38.72405 },
            { lng: 0.19521, lat: 38.72376 },
            { lng: 0.19396, lat: 38.72399 },
            { lng: 0.19337, lat: 38.72388 },
            { lng: 0.19322, lat: 38.7241 },
            { lng: 0.19155, lat: 38.72387 },
            { lng: 0.18985, lat: 38.72302 },
            { lng: 0.18937, lat: 38.72314 },
            { lng: 0.18877, lat: 38.72302 },
            { lng: 0.18751, lat: 38.72196 },
            { lng: 0.18715, lat: 38.72192 },
            { lng: 0.18692, lat: 38.72206 },
            { lng: 0.18658, lat: 38.72138 },
            { lng: 0.18571, lat: 38.72111 },
            { lng: 0.18527, lat: 38.72037 },
            { lng: 0.18417, lat: 38.71943 },
            { lng: 0.18365, lat: 38.71948 },
            { lng: 0.18343, lat: 38.72011 },
            { lng: 0.18295, lat: 38.72034 },
            { lng: 0.18284, lat: 38.72067 },
            { lng: 0.18264, lat: 38.7207 },
            { lng: 0.18212, lat: 38.7204 },
            { lng: 0.18176, lat: 38.71926 },
            { lng: 0.18089, lat: 38.71823 },
            { lng: 0.18069, lat: 38.71686 },
            { lng: 0.18008, lat: 38.71623 },
            { lng: 0.17278, lat: 38.72203 },
            { lng: 0.16802, lat: 38.72552 },
            { lng: 0.16346, lat: 38.72956 },
            { lng: 0.16084, lat: 38.73162 }
        ]
    ];
});
