define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.benissaZonePueblo = void 0;
    exports.benissaZonePueblo = [
        [
            { lng: 0.08173, lat: 38.69317 },
            { lng: 0.08248, lat: 38.69177 },
            { lng: 0.08229, lat: 38.69099 },
            { lng: 0.08252, lat: 38.68893 },
            { lng: 0.08228, lat: 38.68768 },
            { lng: 0.08335, lat: 38.68613 },
            { lng: 0.08342, lat: 38.68537 },
            { lng: 0.08283, lat: 38.6854 },
            { lng: 0.08178, lat: 38.68481 },
            { lng: 0.0807, lat: 38.6839 },
            { lng: 0.0804, lat: 38.68187 },
            { lng: 0.07845, lat: 38.68171 },
            { lng: 0.07417, lat: 38.68071 },
            { lng: 0.07343, lat: 38.67994 },
            { lng: 0.0708, lat: 38.67899 },
            { lng: 0.06921, lat: 38.67802 },
            { lng: 0.06856, lat: 38.68035 },
            { lng: 0.06766, lat: 38.68056 },
            { lng: 0.06421, lat: 38.67985 },
            { lng: 0.05997, lat: 38.6809 },
            { lng: 0.05751, lat: 38.68005 },
            { lng: 0.05489, lat: 38.67886 },
            { lng: 0.05309, lat: 38.67897 },
            { lng: 0.05088, lat: 38.6786 },
            { lng: 0.05018, lat: 38.67808 },
            { lng: 0.04934, lat: 38.67704 },
            { lng: 0.04941, lat: 38.67279 },
            { lng: 0.04913, lat: 38.6726 },
            { lng: 0.04853, lat: 38.67284 },
            { lng: 0.04632, lat: 38.6749 },
            { lng: 0.04586, lat: 38.67498 },
            { lng: 0.04469, lat: 38.67422 },
            { lng: 0.04426, lat: 38.67425 },
            { lng: 0.0436, lat: 38.67464 },
            { lng: 0.04085, lat: 38.67705 },
            { lng: 0.0396, lat: 38.67759 },
            { lng: 0.03894, lat: 38.67766 },
            { lng: 0.03865, lat: 38.67735 },
            { lng: 0.03838, lat: 38.67658 },
            { lng: 0.03857, lat: 38.67416 },
            { lng: 0.03878, lat: 38.67323 },
            { lng: 0.04309, lat: 38.66827 },
            { lng: 0.04363, lat: 38.66783 },
            { lng: 0.04395, lat: 38.6671 },
            { lng: 0.04049, lat: 38.66384 },
            { lng: 0.03843, lat: 38.66305 },
            { lng: 0.03796, lat: 38.663 },
            { lng: 0.03674, lat: 38.66328 },
            { lng: 0.03451, lat: 38.6628 },
            { lng: 0.03408, lat: 38.66295 },
            { lng: 0.03366, lat: 38.66353 },
            { lng: 0.0336, lat: 38.66406 },
            { lng: 0.03201, lat: 38.66393 },
            { lng: 0.03076, lat: 38.66346 },
            { lng: 0.02971, lat: 38.66282 },
            { lng: 0.02941, lat: 38.66235 },
            { lng: 0.02717, lat: 38.66092 },
            { lng: 0.02587, lat: 38.66033 },
            { lng: 0.02493, lat: 38.65966 },
            { lng: 0.02382, lat: 38.65934 },
            { lng: 0.02184, lat: 38.65833 },
            { lng: 0.02055, lat: 38.6573 },
            { lng: 0.01669, lat: 38.65472 },
            { lng: 0.01405, lat: 38.65317 },
            { lng: -0.00026, lat: 38.64892 },
            { lng: -0.00615, lat: 38.64394 },
            { lng: -0.0124, lat: 38.64882 },
            { lng: -0.01637, lat: 38.65253 },
            { lng: -0.0178, lat: 38.65292 },
            { lng: -0.01854, lat: 38.6537 },
            { lng: -0.01969, lat: 38.65427 },
            { lng: -0.0215, lat: 38.65483 },
            { lng: -0.02281, lat: 38.65499 },
            { lng: -0.02579, lat: 38.65471 },
            { lng: -0.02774, lat: 38.65502 },
            { lng: -0.03022, lat: 38.65583 },
            { lng: -0.03177, lat: 38.65541 },
            { lng: -0.03531, lat: 38.65736 },
            { lng: -0.0386, lat: 38.65819 },
            { lng: -0.04452, lat: 38.66068 },
            { lng: -0.04081, lat: 38.66316 },
            { lng: -0.03672, lat: 38.66696 },
            { lng: -0.03071, lat: 38.67178 },
            { lng: -0.02492, lat: 38.67861 },
            { lng: -0.01274, lat: 38.67814 },
            { lng: -0.01255, lat: 38.68615 },
            { lng: -0.00989, lat: 38.68927 },
            { lng: -0.00894, lat: 38.69135 },
            { lng: -0.00778, lat: 38.69284 },
            { lng: -0.00656, lat: 38.69582 },
            { lng: -0.00573, lat: 38.69704 },
            { lng: 0.00071, lat: 38.70462 },
            { lng: 0.00445, lat: 38.70831 },
            { lng: 0.01061, lat: 38.7179 },
            { lng: 0.01216, lat: 38.71955 },
            { lng: 0.01318, lat: 38.72012 },
            { lng: 0.01749, lat: 38.72119 },
            { lng: 0.01925, lat: 38.72198 },
            { lng: 0.02141, lat: 38.72466 },
            { lng: 0.02621, lat: 38.72699 },
            { lng: 0.02653, lat: 38.72795 },
            { lng: 0.02684, lat: 38.73009 },
            { lng: 0.02838, lat: 38.73325 },
            { lng: 0.02922, lat: 38.73297 },
            { lng: 0.03014, lat: 38.73295 },
            { lng: 0.03283, lat: 38.73358 },
            { lng: 0.03262, lat: 38.73263 },
            { lng: 0.0304, lat: 38.72896 },
            { lng: 0.03102, lat: 38.72769 },
            { lng: 0.03254, lat: 38.72584 },
            { lng: 0.03472, lat: 38.72443 },
            { lng: 0.03806, lat: 38.72415 },
            { lng: 0.04171, lat: 38.72344 },
            { lng: 0.04411, lat: 38.72195 },
            { lng: 0.04539, lat: 38.72053 },
            { lng: 0.04653, lat: 38.7204 },
            { lng: 0.04742, lat: 38.7208 },
            { lng: 0.04857, lat: 38.72085 },
            { lng: 0.05401, lat: 38.72075 },
            { lng: 0.05505, lat: 38.72157 },
            { lng: 0.05534, lat: 38.72191 },
            { lng: 0.05541, lat: 38.72235 },
            { lng: 0.05392, lat: 38.72522 },
            { lng: 0.05345, lat: 38.73156 },
            { lng: 0.05351, lat: 38.73318 },
            { lng: 0.0528, lat: 38.73454 },
            { lng: 0.0534, lat: 38.74148 },
            { lng: 0.04993, lat: 38.74583 },
            { lng: 0.05001, lat: 38.74616 },
            { lng: 0.05053, lat: 38.74648 },
            { lng: 0.0536, lat: 38.74755 },
            { lng: 0.05469, lat: 38.74884 },
            { lng: 0.05631, lat: 38.74885 },
            { lng: 0.06472, lat: 38.7449 },
            { lng: 0.06867, lat: 38.74875 },
            { lng: 0.07059, lat: 38.74935 },
            { lng: 0.07399, lat: 38.74976 },
            { lng: 0.07737, lat: 38.74973 },
            { lng: 0.07794, lat: 38.74946 },
            { lng: 0.07891, lat: 38.74827 },
            { lng: 0.07946, lat: 38.74803 },
            { lng: 0.08002, lat: 38.74735 },
            { lng: 0.08034, lat: 38.74638 },
            { lng: 0.07974, lat: 38.74506 },
            { lng: 0.07996, lat: 38.74407 },
            { lng: 0.0823, lat: 38.73708 },
            { lng: 0.08361, lat: 38.73257 },
            { lng: 0.08603, lat: 38.73166 },
            { lng: 0.08676, lat: 38.73042 },
            { lng: 0.08703, lat: 38.72953 },
            { lng: 0.08771, lat: 38.72854 },
            { lng: 0.08751, lat: 38.72793 },
            { lng: 0.08754, lat: 38.72742 },
            { lng: 0.08894, lat: 38.72444 },
            { lng: 0.08771, lat: 38.724 },
            { lng: 0.0883, lat: 38.72234 },
            { lng: 0.08594, lat: 38.71983 },
            { lng: 0.08569, lat: 38.71652 },
            { lng: 0.08545, lat: 38.71596 },
            { lng: 0.08511, lat: 38.71326 },
            { lng: 0.08382, lat: 38.71238 },
            { lng: 0.08303, lat: 38.71143 },
            { lng: 0.08253, lat: 38.70827 },
            { lng: 0.08262, lat: 38.70639 },
            { lng: 0.08238, lat: 38.70602 },
            { lng: 0.08223, lat: 38.70515 },
            { lng: 0.08312, lat: 38.70427 },
            { lng: 0.08253, lat: 38.70062 },
            { lng: 0.08217, lat: 38.70027 },
            { lng: 0.08138, lat: 38.69989 },
            { lng: 0.08162, lat: 38.69884 },
            { lng: 0.08139, lat: 38.69835 },
            { lng: 0.08152, lat: 38.69775 },
            { lng: 0.08103, lat: 38.6972 },
            { lng: 0.08107, lat: 38.6963 },
            { lng: 0.08019, lat: 38.69624 },
            { lng: 0.0792, lat: 38.69508 },
            { lng: 0.08045, lat: 38.69423 },
            { lng: 0.08049, lat: 38.6936 },
            { lng: 0.08173, lat: 38.69317 }
        ]
    ];
});
