define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.moraira = void 0;
    exports.moraira = [
        [
            { lng: 0.15669, lat: 38.69398 },
            { lng: 0.15626, lat: 38.69388 },
            { lng: 0.1566, lat: 38.69325 },
            { lng: 0.15633, lat: 38.69252 },
            { lng: 0.15574, lat: 38.69214 },
            { lng: 0.15615, lat: 38.69141 },
            { lng: 0.15532, lat: 38.69064 },
            { lng: 0.1555, lat: 38.68934 },
            { lng: 0.15483, lat: 38.68858 },
            { lng: 0.15524, lat: 38.6883 },
            { lng: 0.15507, lat: 38.68685 },
            { lng: 0.15312, lat: 38.68553 },
            { lng: 0.15346, lat: 38.68502 },
            { lng: 0.15222, lat: 38.68211 },
            { lng: 0.15072, lat: 38.68184 },
            { lng: 0.15043, lat: 38.68134 },
            { lng: 0.15096, lat: 38.68086 },
            { lng: 0.15011, lat: 38.68059 },
            { lng: 0.14909, lat: 38.67942 },
            { lng: 0.14793, lat: 38.67923 },
            { lng: 0.14693, lat: 38.68009 },
            { lng: 0.14737, lat: 38.68181 },
            { lng: 0.14696, lat: 38.68606 },
            { lng: 0.14739, lat: 38.68648 },
            { lng: 0.14685, lat: 38.68718 },
            { lng: 0.14525, lat: 38.68755 },
            { lng: 0.14457, lat: 38.68681 },
            { lng: 0.14048, lat: 38.68611 },
            { lng: 0.13882, lat: 38.68662 },
            { lng: 0.13841, lat: 38.68532 },
            { lng: 0.13947, lat: 38.68522 },
            { lng: 0.13939, lat: 38.68481 },
            { lng: 0.13459, lat: 38.6853 },
            { lng: 0.13395, lat: 38.68673 },
            { lng: 0.13193, lat: 38.68629 },
            { lng: 0.13042, lat: 38.68683 },
            { lng: 0.12919, lat: 38.68638 },
            { lng: 0.12942, lat: 38.68605 },
            { lng: 0.1288, lat: 38.6856 },
            { lng: 0.12544, lat: 38.68506 },
            { lng: 0.12102, lat: 38.68184 },
            { lng: 0.12122, lat: 38.68089 },
            { lng: 0.12001, lat: 38.6803 },
            { lng: 0.11973, lat: 38.67926 },
            { lng: 0.1181, lat: 38.67836 },
            { lng: 0.11545, lat: 38.67487 },
            { lng: 0.11516, lat: 38.67379 },
            { lng: 0.11447, lat: 38.67363 },
            { lng: 0.11243, lat: 38.67489 },
            { lng: 0.1085, lat: 38.67457 },
            { lng: 0.10552, lat: 38.68237 },
            { lng: 0.1073, lat: 38.68575 },
            { lng: 0.10766, lat: 38.68826 },
            { lng: 0.10232, lat: 38.69123 },
            { lng: 0.10045, lat: 38.69318 },
            { lng: 0.09795, lat: 38.69476 },
            { lng: 0.09656, lat: 38.69648 },
            { lng: 0.09371, lat: 38.69794 },
            { lng: 0.09338, lat: 38.69944 },
            { lng: 0.09395, lat: 38.70086 },
            { lng: 0.09537, lat: 38.70048 },
            { lng: 0.09669, lat: 38.7019 },
            { lng: 0.09691, lat: 38.7035 },
            { lng: 0.10174, lat: 38.70409 },
            { lng: 0.1082, lat: 38.70633 },
            { lng: 0.11132, lat: 38.70647 },
            { lng: 0.1145, lat: 38.70739 },
            { lng: 0.11894, lat: 38.7079 },
            { lng: 0.12116, lat: 38.70871 },
            { lng: 0.12235, lat: 38.70981 },
            { lng: 0.12621, lat: 38.71071 },
            { lng: 0.13002, lat: 38.71055 },
            { lng: 0.13364, lat: 38.70964 },
            { lng: 0.13563, lat: 38.70783 },
            { lng: 0.13667, lat: 38.70948 },
            { lng: 0.13783, lat: 38.71369 },
            { lng: 0.1397, lat: 38.71601 },
            { lng: 0.14506, lat: 38.7113 },
            { lng: 0.15098, lat: 38.70711 },
            { lng: 0.15154, lat: 38.70253 },
            { lng: 0.15322, lat: 38.7011 },
            { lng: 0.15337, lat: 38.69992 },
            { lng: 0.15441, lat: 38.69848 },
            { lng: 0.15568, lat: 38.69795 },
            { lng: 0.15641, lat: 38.69698 },
            { lng: 0.15566, lat: 38.69492 },
            { lng: 0.15598, lat: 38.69422 },
            { lng: 0.15669, lat: 38.69398 }
        ]
    ];
});
