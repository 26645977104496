define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.pedreguer = void 0;
    exports.pedreguer = [
        [
            { lng: -0.0090865, lat: 38.8069957 },
            { lng: -0.0084997, lat: 38.8053498 },
            { lng: -0.0063943, lat: 38.7988406 },
            { lng: -0.0062801, lat: 38.792954 },
            { lng: -0.0066629, lat: 38.7912955 },
            { lng: -0.008743, lat: 38.7873755 },
            { lng: -0.0089276, lat: 38.7865812 },
            { lng: -0.0083042, lat: 38.7850016 },
            { lng: -0.007493, lat: 38.7832687 },
            { lng: -0.0071713, lat: 38.782613 },
            { lng: -0.0057149, lat: 38.782288 },
            { lng: -0.0050981, lat: 38.7819441 },
            { lng: -0.0040699, lat: 38.7815892 },
            { lng: -0.0034534, lat: 38.7813687 },
            { lng: -0.000554, lat: 38.7805069 },
            { lng: 0.0043232, lat: 38.7776967 },
            { lng: 0.0045365, lat: 38.7767454 },
            { lng: 0.006216, lat: 38.7696438 },
            { lng: 0.006305, lat: 38.7692672 },
            { lng: 0.0166958, lat: 38.7665216 },
            { lng: 0.0180133, lat: 38.7664503 },
            { lng: 0.0243422, lat: 38.7661075 },
            { lng: 0.0283679, lat: 38.7650671 },
            { lng: 0.0336935, lat: 38.7635677 },
            { lng: 0.0376887, lat: 38.7663286 },
            { lng: 0.0379332, lat: 38.7674385 },
            { lng: 0.0395993, lat: 38.7749996 },
            { lng: 0.0408752, lat: 38.7754607 },
            { lng: 0.044633, lat: 38.7773024 },
            { lng: 0.0484936, lat: 38.7786445 },
            { lng: 0.0486616, lat: 38.7786962 },
            { lng: 0.0507381, lat: 38.7793367 },
            { lng: 0.0542848, lat: 38.7804328 },
            { lng: 0.0543552, lat: 38.7804463 },
            { lng: 0.0544548, lat: 38.7804698 },
            { lng: 0.0693001, lat: 38.7835199 },
            { lng: 0.069253, lat: 38.7848364 },
            { lng: 0.0692049, lat: 38.7869368 },
            { lng: 0.0720311, lat: 38.7905651 },
            { lng: 0.0723206, lat: 38.7911431 },
            { lng: 0.0725899, lat: 38.7917846 },
            { lng: 0.0725172, lat: 38.792237 },
            { lng: 0.0726643, lat: 38.7929809 },
            { lng: 0.0726875, lat: 38.7935208 },
            { lng: 0.0728346, lat: 38.793733 },
            { lng: 0.0728965, lat: 38.7938846 },
            { lng: 0.0730019, lat: 38.7941431 },
            { lng: 0.0729806, lat: 38.7943375 },
            { lng: 0.0729666, lat: 38.7944652 },
            { lng: 0.0729522, lat: 38.7945948 },
            { lng: 0.0728528, lat: 38.7948236 },
            { lng: 0.0728055, lat: 38.794932 },
            { lng: 0.0726991, lat: 38.7954033 },
            { lng: 0.072318, lat: 38.7961791 },
            { lng: 0.0720816, lat: 38.7965727 },
            { lng: 0.0718495, lat: 38.7970653 },
            { lng: 0.0716447, lat: 38.7976562 },
            { lng: 0.0715031, lat: 38.7983807 },
            { lng: 0.0714242, lat: 38.7986891 },
            { lng: 0.0712139, lat: 38.7991541 },
            { lng: 0.0712614, lat: 38.7995511 },
            { lng: 0.0713773, lat: 38.7997003 },
            { lng: 0.071278, lat: 38.7999363 },
            { lng: 0.0710476, lat: 38.8003746 },
            { lng: 0.0707058, lat: 38.8009962 },
            { lng: 0.0700346, lat: 38.801987 },
            { lng: 0.0697671, lat: 38.8024625 },
            { lng: 0.0694644, lat: 38.8029209 },
            { lng: 0.069197, lat: 38.8033964 },
            { lng: 0.0688226, lat: 38.8037936 },
            { lng: 0.0685931, lat: 38.8040789 },
            { lng: 0.0681155, lat: 38.8044879 },
            { lng: 0.067306, lat: 38.8050397 },
            { lng: 0.066172, lat: 38.8071756 },
            { lng: 0.065947, lat: 38.8076647 },
            { lng: 0.0653269, lat: 38.8085794 },
            { lng: 0.0651562, lat: 38.8088855 },
            { lng: 0.0649745, lat: 38.8091481 },
            { lng: 0.0646512, lat: 38.8095375 },
            { lng: 0.0623139, lat: 38.8139392 },
            { lng: 0.0618877, lat: 38.8144705 },
            { lng: 0.0613398, lat: 38.8150208 },
            { lng: 0.0596108, lat: 38.8157703 },
            { lng: 0.0580645, lat: 38.8164438 },
            { lng: 0.0549353, lat: 38.8186922 },
            { lng: 0.0517895, lat: 38.8210443 },
            { lng: 0.0516529, lat: 38.8211452 },
            { lng: 0.0515176, lat: 38.8212256 },
            { lng: 0.0514895, lat: 38.8212251 },
            { lng: 0.0481182, lat: 38.822075 },
            { lng: 0.0478425, lat: 38.8221132 },
            { lng: 0.047635, lat: 38.8221588 },
            { lng: 0.0474055, lat: 38.8222235 },
            { lng: 0.0471775, lat: 38.8223068 },
            { lng: 0.0469983, lat: 38.8222252 },
            { lng: 0.046819, lat: 38.8221437 },
            { lng: 0.0463005, lat: 38.8218942 },
            { lng: 0.0449986, lat: 38.8212739 },
            { lng: 0.0441438, lat: 38.8208598 },
            { lng: 0.0438712, lat: 38.8207168 },
            { lng: 0.0437061, lat: 38.8206057 },
            { lng: 0.0432169, lat: 38.8201577 },
            { lng: 0.0422369, lat: 38.8191379 },
            { lng: 0.0415177, lat: 38.8182983 },
            { lng: 0.0413817, lat: 38.8181668 },
            { lng: 0.041116, lat: 38.8179634 },
            { lng: 0.0409257, lat: 38.8178177 },
            { lng: 0.0408734, lat: 38.8177802 },
            { lng: 0.0406737, lat: 38.8176281 },
            { lng: 0.0404719, lat: 38.8174828 },
            { lng: 0.040434, lat: 38.8174555 },
            { lng: 0.0401935, lat: 38.8172864 },
            { lng: 0.039944, lat: 38.8171119 },
            { lng: 0.039814, lat: 38.817019 },
            { lng: 0.0396972, lat: 38.8169285 },
            { lng: 0.0395868, lat: 38.8168349 },
            { lng: 0.03949, lat: 38.8167526 },
            { lng: 0.039367, lat: 38.816637 },
            { lng: 0.0392903, lat: 38.816561 },
            { lng: 0.0392138, lat: 38.8164808 },
            { lng: 0.0391339, lat: 38.8163869 },
            { lng: 0.0390005, lat: 38.8162241 },
            { lng: 0.038884, lat: 38.8160733 },
            { lng: 0.0387917, lat: 38.815954 },
            { lng: 0.0386365, lat: 38.8157529 },
            { lng: 0.0384896, lat: 38.8155653 },
            { lng: 0.0384187, lat: 38.8154754 },
            { lng: 0.0383523, lat: 38.8153997 },
            { lng: 0.0382542, lat: 38.8153039 },
            { lng: 0.0381657, lat: 38.8152303 },
            { lng: 0.0380167, lat: 38.8151313 },
            { lng: 0.0378665, lat: 38.8150323 },
            { lng: 0.0376153, lat: 38.81486 },
            { lng: 0.0374203, lat: 38.8147154 },
            { lng: 0.0373487, lat: 38.8146596 },
            { lng: 0.0372841, lat: 38.8145977 },
            { lng: 0.0372345, lat: 38.8145431 },
            { lng: 0.0371915, lat: 38.8144852 },
            { lng: 0.0371185, lat: 38.8145102 },
            { lng: 0.0368364, lat: 38.8146013 },
            { lng: 0.0366228, lat: 38.814631 },
            { lng: 0.036369, lat: 38.8148554 },
            { lng: 0.0363151, lat: 38.8151116 },
            { lng: 0.0361327, lat: 38.8154417 },
            { lng: 0.0361306, lat: 38.815893 },
            { lng: 0.0360176, lat: 38.8161391 },
            { lng: 0.0359406, lat: 38.8164433 },
            { lng: 0.0359329, lat: 38.8168454 },
            { lng: 0.0359119, lat: 38.8170624 },
            { lng: 0.0359406, lat: 38.8172396 },
            { lng: 0.0359648, lat: 38.817425 },
            { lng: 0.0361922, lat: 38.8176038 },
            { lng: 0.0366246, lat: 38.8178398 },
            { lng: 0.0333413, lat: 38.8192633 },
            { lng: 0.0332827, lat: 38.8189523 },
            { lng: 0.0331363, lat: 38.8187002 },
            { lng: 0.0322557, lat: 38.8175612 },
            { lng: 0.0320686, lat: 38.8174109 },
            { lng: 0.0318732, lat: 38.817219 },
            { lng: 0.0314758, lat: 38.8169536 },
            { lng: 0.0311692, lat: 38.8169755 },
            { lng: 0.0309073, lat: 38.8169475 },
            { lng: 0.030291, lat: 38.8169215 },
            { lng: 0.0295385, lat: 38.8166202 },
            { lng: 0.0291347, lat: 38.8168962 },
            { lng: 0.0286875, lat: 38.8169228 },
            { lng: 0.0280665, lat: 38.8168578 },
            { lng: 0.0277651, lat: 38.8167983 },
            { lng: 0.027431, lat: 38.8166399 },
            { lng: 0.0272241, lat: 38.8166202 },
            { lng: 0.0272334, lat: 38.8166102 },
            { lng: 0.027459, lat: 38.8163648 },
            { lng: 0.0273938, lat: 38.8161818 },
            { lng: 0.0273157, lat: 38.8159566 },
            { lng: 0.0280447, lat: 38.815375 },
            { lng: 0.0288611, lat: 38.8147013 },
            { lng: 0.0277006, lat: 38.8139565 },
            { lng: 0.0287174, lat: 38.8129274 },
            { lng: 0.0271877, lat: 38.8119069 },
            { lng: 0.0281364, lat: 38.8111395 },
            { lng: 0.0274547, lat: 38.8106797 },
            { lng: 0.0261751, lat: 38.8099025 },
            { lng: 0.0253499, lat: 38.8105693 },
            { lng: 0.0249792, lat: 38.8111381 },
            { lng: 0.0246963, lat: 38.8112614 },
            { lng: 0.0238204, lat: 38.8117326 },
            { lng: 0.0238421, lat: 38.8119249 },
            { lng: 0.023833, lat: 38.8121385 },
            { lng: 0.0238929, lat: 38.8121286 },
            { lng: 0.024816, lat: 38.8126986 },
            { lng: 0.0243952, lat: 38.813054 },
            { lng: 0.0235754, lat: 38.8124208 },
            { lng: 0.023419, lat: 38.8125856 },
            { lng: 0.023384, lat: 38.8128408 },
            { lng: 0.0218851, lat: 38.8128474 },
            { lng: 0.02164, lat: 38.8127215 },
            { lng: 0.0203575, lat: 38.8120776 },
            { lng: 0.0192593, lat: 38.8115283 },
            { lng: 0.0181719, lat: 38.8110575 },
            { lng: 0.0180705, lat: 38.811136 },
            { lng: 0.0171907, lat: 38.8105137 },
            { lng: 0.0169164, lat: 38.8108046 },
            { lng: 0.0165213, lat: 38.8104654 },
            { lng: 0.015629, lat: 38.8111521 },
            { lng: 0.0151222, lat: 38.8114782 },
            { lng: 0.0147626, lat: 38.8118511 },
            { lng: 0.0145012, lat: 38.8119173 },
            { lng: 0.0110928, lat: 38.8103833 },
            { lng: 0.010921, lat: 38.8103398 },
            { lng: 0.0107181, lat: 38.8103298 },
            { lng: 0.0102277, lat: 38.8101618 },
            { lng: 0.009827, lat: 38.8099757 },
            { lng: 0.0095473, lat: 38.8098496 },
            { lng: 0.0094367, lat: 38.8098376 },
            { lng: 0.0092724, lat: 38.8099056 },
            { lng: 0.0091927, lat: 38.8098956 },
            { lng: 0.0092544, lat: 38.8097095 },
            { lng: 0.0092107, lat: 38.8095794 },
            { lng: 0.0089858, lat: 38.8094167 },
            { lng: 0.0089411, lat: 38.8093613 },
            { lng: 0.0089258, lat: 38.8090361 },
            { lng: 0.0088409, lat: 38.8089751 },
            { lng: 0.0087345, lat: 38.8089327 },
            { lng: 0.0084099, lat: 38.8088826 },
            { lng: 0.0082892, lat: 38.8088387 },
            { lng: 0.0081707, lat: 38.808765 },
            { lng: 0.0078805, lat: 38.8086409 },
            { lng: 0.007634, lat: 38.8085769 },
            { lng: 0.0073383, lat: 38.8085735 },
            { lng: 0.0072735, lat: 38.8084891 },
            { lng: 0.0072989, lat: 38.8083193 },
            { lng: 0.0074297, lat: 38.808175 },
            { lng: 0.0073757, lat: 38.8080965 },
            { lng: 0.0072436, lat: 38.8080866 },
            { lng: 0.0070592, lat: 38.8081184 },
            { lng: 0.0068384, lat: 38.8080884 },
            { lng: 0.0066359, lat: 38.8079969 },
            { lng: 0.0063098, lat: 38.808025 },
            { lng: 0.0061857, lat: 38.8080689 },
            { lng: 0.0056991, lat: 38.8091681 },
            { lng: 0.0056018, lat: 38.8094357 },
            { lng: 0.0054083, lat: 38.8094023 },
            { lng: 0.0042442, lat: 38.8092644 },
            { lng: 0.0039153, lat: 38.8092418 },
            { lng: 0.0037646, lat: 38.8092024 },
            { lng: 0.0035205, lat: 38.8091523 },
            { lng: 0.0031718, lat: 38.8090979 },
            { lng: 0.0028741, lat: 38.8090582 },
            { lng: 0.0027735, lat: 38.809047 },
            { lng: 0.002461, lat: 38.8090122 },
            { lng: 0.0018951, lat: 38.8089203 },
            { lng: 0.0017288, lat: 38.8089015 },
            { lng: 0.0015625, lat: 38.8088847 },
            { lng: 0.0013747, lat: 38.8088576 },
            { lng: 0.0006854, lat: 38.8087322 },
            { lng: 0.0003474, lat: 38.8086731 },
            { lng: 0.000045, lat: 38.8086261 },
            { lng: -0.000132, lat: 38.8085947 },
            { lng: -0.0002105, lat: 38.8085775 },
            { lng: -0.0003271, lat: 38.8085477 },
            { lng: -0.0004565, lat: 38.808508 },
            { lng: -0.0007523, lat: 38.8084171 },
            { lng: -0.0009715, lat: 38.808345 },
            { lng: -0.0010466, lat: 38.8083241 },
            { lng: -0.0010666, lat: 38.8083199 },
            { lng: -0.0012263, lat: 38.8082967 },
            { lng: -0.0013859, lat: 38.808281 },
            { lng: -0.0019834, lat: 38.8082658 },
            { lng: -0.0020974, lat: 38.8082559 },
            { lng: -0.002208, lat: 38.8082413 },
            { lng: -0.0026421, lat: 38.8081749 },
            { lng: -0.0030203, lat: 38.808131 },
            { lng: -0.0033234, lat: 38.8080976 },
            { lng: -0.0037311, lat: 38.8080265 },
            { lng: -0.0041924, lat: 38.8079387 },
            { lng: -0.0043819, lat: 38.8079018 },
            { lng: -0.0049072, lat: 38.8077996 },
            { lng: -0.005319, lat: 38.8077366 },
            { lng: -0.0058474, lat: 38.807653 },
            { lng: -0.0064268, lat: 38.8075918 },
            { lng: -0.0069812, lat: 38.8075347 },
            { lng: -0.0074028, lat: 38.8074434 },
            { lng: -0.0078549, lat: 38.807367 },
            { lng: -0.0080546, lat: 38.8073201 },
            { lng: -0.008422, lat: 38.8072093 },
            { lng: -0.008472, lat: 38.8071971 },
            { lng: -0.0086622, lat: 38.8071371 },
            { lng: -0.0090121, lat: 38.807017 },
            { lng: -0.0090865, lat: 38.8069957 }
        ]
    ];
});
